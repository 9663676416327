@import '../../assets/sass/variables';
@import '../../assets/sass/mixins';
@import '../../assets/sass/base';

.single-time-checkbox {
  width: max-content;
  display: flex;
  align-items: center;
  height: min-content;


  @include responsive(sm) {
  }

  & .checkbox-container {
    padding: 0;
    margin: 0;
    width: 25px;
    height: 25px;
  }

  & .checkbox-day-period {
    min-width: 120px;
    display: flex;
    align-items: flex-end;
    gap: 10px;
  }

  // Left Side
  &__time {
    font-size: 1.1rem;

    &--blue {
      color: $primary_blue;
      font-weight: 500;
    }
  }

  &__hours {
    color: $font-primary-color;
    font-size: .6rem;
    margin-top: 5px;
  }

  // Right Side
  &__right {
    border-left: 3px solid $primary_blue;
    padding-left: .8rem;
  }

  &__price {
    color: #4A4A4A;
    font-size: 18px;
    font-weight: 700;
  }

  &__rate {
    white-space: nowrap;
    color: #9b9b9b;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
  }

  &__service-fee {
    white-space: nowrap;
    color: rgba($tertiary_gray, .8);
    font-size: 12px;
  }
}

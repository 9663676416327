@import '../../assets/sass/mixins';

.booking-success {

    & p.jobform-text {
        font-size: 1.125rem;
    }

    &__book-more-text {
        margin-top: 4.3rem !important;
        margin-bottom: 1.7rem;
    }
}

﻿/* ===============
   GLOBAL PRESETS
   =============== */
//fonts
/* roboto-300 - latin */

$font-primary: Roboto,"Helvetica Neue","sans-serif";


$font-secondary: "Brandon Grotesque","Georgia", "sans-serif";

$fontawesome: "Font Awesome 5 Free";

$font-primary-color: #212529;
$font-secondary-color: #777;

// colours
$color-primary: #FED900; // nav-bar, top-menu

$color-secondary: #34B9B9; // primary buttons, category icon colors
$color-secondary-hover: #2C9696; // hover color for buttons etc.

$bgcolor-primary: #E6F2F7; //primary section bg color

$color-stars-active: #EEBD01; //color of stars on reviews

//urls
$page-bottom-image: none; //image that is at the bottom of most pages





/* ==========================================
   STUFF BELOW HERE SHOULD NOT NEED CHANGING
   ========================================== */

$color-white: white; // 56
$color-black: black; // 10
$color-grey: #bbbbbb; // 6
$color-error: crimson; // 2

$color-white-opacity-50: rgba(255,255,255,0.5); // 4
$color-white-opacity-70: rgba(255,255,255,0.7); // 1

$color-black-opacity-20: rgba(0,0,0,0.2); // 3 - just some box-shadow
$color-black-opacity-46: rgba(0,0,0,0.46); // 2


//light greys - mostly backgrounds
$color-off-white: #f7f7f7; // 6
$color-eee: #eee; // 6
$color-ddd: #ddd; // 3
$color-ccc: #ccc; // 7
$color-aaa: #aaa; // 1
//dark greys
$color-almost-black: #222; //11 occurances //mostly text color
$color-333: #333; // 6
$color-555: #555; // 3
$color-888: #888; // 6
$color-999: #999; // 7

$color_39: #857200 !default; // 2 price text color

//confirmed, review, status page timeslot styles
$timeslot-active-color: #155724; // 1
$timeslot-active-background: #d4edda; // 1
$timeslot-active-border: #c3e6cb; // 1

$timeslot-cancelled-color: #721c24; // 1
$timeslot-cancelled-background: #f8d7da; // 1
$timeslot-cancelled-border: #f5c6cb; // 1

//change this
$color_53: #00605e; // 2 not-found and faq <a />

//@extend-elements
//original selectors
//section.jumbo .search-form .input-group input, section.jumbo .search-form button
%extend_1 {
    font-family: $font-primary;
    height: 65px;
    border: none;
    font-size: calc(12px + 0.5vw);
}

//original selectors
//section.how .search-form .input-group input, section.how .search-form button
%extend_2 {
    font-family: $font-primary;
    height: 65px;
    border: none;
    font-size: calc(12px + 0.5vw);
}

//original selectors
//#form-inputs >div >input, #form-inputs >div >textarea
%extend_3 {
    z-index: 3;
    height: 55px;
    border-radius: 10px;
}

//original selectors
//#form-inputs >div.row >label, #form-inputs >div.row >div.form-check >label, #form-inputs >div.row >div.form-check >input
%extend_4 {
    font-family: $font-primary;
    font-weight: 700;
    font-size: calc(12px + 0.5vw);
    margin: 25px 0 6px;
}

@import '../../assets/sass/variables';

.dynamic-fields {
    display: flex;
    flex-direction: column;
    row-gap: 1.7rem;
    margin-top: 26px;

    &__spinner {
        justify-content: flex-start;
        height: 220px;
    }

    & label {
        display: block;
        margin-bottom: .5rem;
    }
}

@import '../../assets/sass/variables';
@import '../../assets/sass/mixins';

.customer-information-fields {
  width: 100%;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;

  @include responsive(md) {
    width: $input_width;
  }

  &__form-group {
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;

    @include responsive(md) {
      flex-direction: row;
      column-gap: 2rem;

      &--phone-number {
        max-width: calc(50% - 16px);
        flex-direction: column;
        row-gap: 0;
      }
    }

    .input-component, input, div.PhoneInput {
      width: 100%;

    }

    .input-component {

      & > div > label {
        top: -8px;
        left: 10px!important;
        padding: 0 2px;
        background: linear-gradient(
          to top,
          transparent  0%,
          transparent 40%,
          white 40%,
          white 100%
        );
        z-index: 1;
      }

      & div input {
        padding: 14px 5px 14px 15px;
      }

      & > div {
        border: 1px solid #ccc;
        padding: 0 !important;
        border-radius: 7px;
      }

    }

    .input-active {
      & > div {
        border: 2px solid #34b9b9;
      }
    }
  }
}

@import '../../assets/sass/variables';
@import '../../assets/sass/mixins';

.uploaded-files-box {
    font-weight: 700;

    &__upload-image-img {
        width: 100%;
        display: flex;
        justify-content: center;
    }
    &__title {
        font-weight: 700;
    }

    &__container {
       /* width: 100%;
        border: 1px solid $input_border_color;*/
        cursor: pointer;
        row-gap: .8rem !important;
        justify-content: center;
        padding: 2rem 18px;
        border-radius: $primary_border_radius;
        text-align: center;

        &--small-box {
            row-gap: 0;
            width: 120px;
            height: 120px;
            border: none;
            background-color: $primary_light_blue;
        }

        @include responsive(md) {
            padding: 1.75rem 18px;
            width: $input_width;

            &--small-box {
                width: 120px;
            }
        }

        &:hover {
            /*
            border: 1px solid $input_border_color_hover;
            */
        }

        & span {
            color: $input_placeholder_color;
        }
        & span.max-upload-size {
            font-size: .9rem;
            margin-left: 2px;
            margin-top: -.5rem;
            color: rgba($input_placeholder_color, .8)
        }
    }
    &__input {
        display: none;
    }

    // Uploaded Images
    &__images-grid {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        margin-bottom: 16px;

        @include responsive(sm) {
            align-items: start;
        }
        @include responsive(md) {
            width: $input_width;
        }
    }

    &__file-container {
        position: relative;
        width: 120px;
        border-radius: $primary_border_radius;
        overflow: hidden;
        height: 120px;

        & img {
            position: absolute;
        }
        & img.uploaded-image {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        & img.close-icon {
            cursor: pointer;
            right: 3%;
            top: 2%;

            &--pdf {
                right: 25%;
                top: 2%;
                position: absolute;
            }
        }

        & img.video-arrow-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            pointer-events: none;
        }

        // Container for pdfs
        &--pdf {
            height: auto;
            width: 100px;

            & img {
                position: static;
            }
        }

        // Container for videos
        &--video {
            cursor: pointer;
        }
    }

    &__pdf-text {
        color: rgba($primary_gray, .75);
        font-size: .93rem;
        margin-top: 5px;
    }
}

@import '../../assets/sass/mixins';
@import '../../assets/sass/variables';

.results-dropdown {
    position: absolute;
    width: 100%;
    top: 100%;
    overflow: auto;
    height: 0;
    border-radius: 0 0 $primary_border_radius $primary_border_radius;
    opacity: 0;
    border-top: 0;
    z-index: 3;
    background-color: $primary_white;
    cursor: pointer;
    transition: all .1s ease-in-out;

    &.opened {
        background-color: #F6F6F6;
        transition: all .3s ease-in-out;
        opacity: 1;
     }

    &__single-result {
        width: 100%;
        font-size: 1rem;
        padding: $input_padding;
        color: rgba(0,0,0,.7);
        transition: background .25s;
        display: flex;

        &--address {
            align-items: center;
            justify-content: space-between;
        }

        &--task {
            display: flex;
            flex-direction: column;
        }

        &:hover {
            background-color: #F0F0F0;
        }
    }

    &__category {
        margin-top: .4rem;
        font-size: .8rem;
        font-style: italic;
    }

    &__view-all-results {
        transition: background .25s;
        background-color: $primary_white;
        font-weight: 700;
        cursor: initial;

        // &:hover {
        //     background-color: $primary_blue;
        // }

        & a {
            color: $primary_black;
            padding: 1.3rem 1rem;
            display: inline-block;
            font-size: 1rem;
            height: 100%;
            cursor: initial;
            text-decoration: underline;
        }
    }
}

@import '../../assets/sass/variables';

.description-field {
    width: 100%;
    resize: none;
}

.input-wrapper {
    & > div > div > div textarea {
        padding-left: 16px;
    }

    & > div > div > label {
        top: -8px;
        left: 10px!important;
        padding: 0 2px;
        background-color: white;
        z-index: 1;
    }

    & div textarea {
        //padding: 11px 5px 10px 11px;
    }

    & > div {
        border: 1px solid #ccc;
        padding: 0 !important;
        border-radius: 7px;
    }
}




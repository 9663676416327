@import 'mixins';
@import 'variables';

/**
 * Global Classes
*/

* {
  box-sizing: border-box
}

body, html {
  height: 100vh;
  max-width: 100vw;
  margin: 0 auto
}

@media only screen and (min-width: 576px) {
  html {
    font-size: 92%
  }
}

body {
  margin: 0;
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-interpolation-mode: nearest-neighbor;
  font-family: Lato, sans-serif
}

.app {
  padding: pxToRem(30) pxToRem(15);
  width: 100%;

  @include responsive(md) {
    padding: pxToRem(56) pxToRem(50);
  }
}

.job-form {
  min-width: 100%;
  width: 100%;
}

.notification-message {
  h4 {
    color: white !important;
  }
}

input, option, select, textarea {
  color: $primary_black;
  font-size: .9rem;
  border-radius: $primary_border_radius;
  width: 100%;
  font-weight: 700;
  padding: $input_padding;
  border: 1px solid $input_border_color;
  transition: all .05s;
  background-color: $primary_white;

  @include responsive(md) {
    width: $input_width;
    font-size: 1rem;
  }

  /*  &:hover {
      outline: 1px solid $input_border_color_hover;
    }*/

  &:focus, &:focus-visible, &:focus-within {
    outline: 1.5px solid $input_focus_color;
  }

  &.error {
    outline: 1.5px solid $input_error_color;
  }

  // Change input placeholder color
  &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $input_placeholder_color;
    opacity: 1; /* Firefox */
    font-weight: 400;
  }

  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $input_placeholder_color;
    font-weight: 400;
  }

  &::-ms-input-placeholder { /* Microsoft Edge */
    color: $input_placeholder_color;
    font-weight: 400;
  }

  &:disabled::placeholder {
    color: $input_border_color_hover;
    opacity: 1; /* Firefox */
  }

  &:disabled::-ms-input-placeholder { /* Microsoft Edge */
    color: $input_border_color_hover;
  }

  /* Delete Input Number Arrows: Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Delete Input Number Arrows: Firefox */
  &[type=number] {
    -moz-appearance: textfield;
    appearance: textfield;
  }

  &[type=checkbox] {
    cursor: pointer;
    width: initial;
  }
}

select {
  cursor: pointer;
}

option {
  &:hover, &.active {
    background-color: $primary_blue !important;
  }
}

datalist {
  max-height: 20vh;
  overflow: auto;

  option {
    border-radius: 0;
  }
}

button {
  cursor: pointer
}

input, option, select, textarea {
  color: $primary_black;
  font-size: .9rem;
  border-radius: 6px;
  width: 100%;
  font-weight: 700;
  padding: 13px 18px;
  border: 1px solid rgba(0, 0, 0, .1215686275);
  transition: all .05s;
  background-color: $primary_white
}

@media only screen and (min-width: 48em) {
  input, option, select, textarea {
    width: 90%;
    font-size: 1rem
  }
}

input[type=button], input[type=checkbox] {
  cursor: pointer
}

input a, input:last-of-type, option a, option:last-of-type, select a, select:last-of-type {
  grid-column: 1/3
}

.MuiSvgIcon-root {
  min-height: 2rem;
  min-width: 2rem;
  width: 2rem !important;
  height: 2rem !important;
}

.MuiOutlinedInput-root {
  padding-left: 0 !important;

  input {
    padding-left: 43px;
  }
}

.MuiStepLabel-iconContainer {
  padding: 0 !important;
}

.MuiInputBase-root-MuiInput-root:hover::before {
  border-bottom: none !important;
}


/*
.MuiInput-underline:after {
  border-bottom-color: orange!important;
}
*/

/*.MuiInputLabel-root {
  color: $primary_blue!important;
}*/

.MuiOutlinedInput-notchedOutline {
  padding: 0;
}

.MuiInput-root:after {
  border-bottom: 2px solid $primary_blue !important;
}

.MuiInput-root {
  padding-top: 0!important;
  margin-top: 0!important;
}

.MuiStep-root {
  padding-left: 2px !important;
  padding-right: 2px !important;
}

.MuiStepIcon-root {
  height: 32px !important;
  width: 32px !important;
}

.MuiStepIcon-root circle {
  color: #d8d8d8;
  border-color: #d8d8d8;
}

.title {
  color: #777;
  font-size: 20px;
  text-align: center;
  width: 100%;
}

.MuiStepConnector-line {
  border-top-width: 1.5px !important;
  border-radius: 1.5px !important;
  stroke: rgb(37, 131, 131) !important;
}

.MuiStepIcon-text {
  font-size: 14px !important;
}

.MuiTextField-root {
  width: 100%;
}

.MuiOutlinedInput-root {
  color: black;
}

.Mui-focused {
  left: 0 !important;

  .MuiOutlinedInput-notchedOutline {
    border-color: #34B9B9 !important;
  }
}

.swiper-wrapper {
  justify-content: center;

  @media screen and (max-width: 900px) {
    justify-content: start;
  }
}

input.error, option.error, select.error {
  border: 1px solid $input_error_color
}

input:disabled::-moz-placeholder, option:disabled::-moz-placeholder, select:disabled::-moz-placeholder {
  color: $input_border_color_hover;
  opacity: 1
}

input:disabled:-ms-input-placeholder, option:disabled:-ms-input-placeholder, select:disabled:-ms-input-placeholder {
  color: $input_border_color_hover;
  opacity: 1
}

input:disabled::placeholder, option:disabled::placeholder, select:disabled::placeholder {
  color: $input_border_color_hover;
  opacity: 1
}

input:disabled::-ms-input-placeholder, option:disabled::-ms-input-placeholder, select:disabled::-ms-input-placeholder {
  color: $input_border_color_hover
}

input::-webkit-inner-spin-button, input::-webkit-outer-spin-button, option::-webkit-inner-spin-button, option::-webkit-outer-spin-button, select::-webkit-inner-spin-button, select::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0
}

input[type=number], option[type=number], select[type=number] {
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
  appearance: textfield
}

option.active, option:hover {
  background-color: $primary_blue !important
}


input:hover, option:hover, select:hover, textarea:hover {
  /*
  outline: 1px solid rgba(0, 0, 0, .3803921569)
  */
}

input:focus, input:focus-visible, input:focus-within, option:focus, option:focus-visible, option:focus-within, select:focus, select:focus-visible, select:focus-within, textarea:focus, textarea:focus-visible, textarea:focus-within {
  /*
  outline: 1.5px solid rgba(52, 185, 185, .6) !important;
  */
}

input.error, option.error, select.error, textarea.error {
  outline: 1.5px solid $input_error_color
}

input::placeholder, option::placeholder, select::placeholder, textarea::placeholder {
  color: $primary_light_gray_secondary;
  opacity: 1;
  font-weight: 700
}

input:-ms-input-placeholder, option:-ms-input-placeholder, select:-ms-input-placeholder, textarea:-ms-input-placeholder {
  color: $primary_light_gray_secondary;
  font-weight: 700
}

input::-ms-input-placeholder, option::-ms-input-placeholder, select::-ms-input-placeholder, textarea::-ms-input-placeholder {
  color: $primary_light_gray_secondary;
  font-weight: 700
}

input:disabled::placeholder, option:disabled::placeholder, select:disabled::placeholder, textarea:disabled::placeholder {
  color: $input_border_color_hover;
  opacity: 1
}

input:disabled::-ms-input-placeholder, option:disabled::-ms-input-placeholder, select:disabled::-ms-input-placeholder, textarea:disabled::-ms-input-placeholder {
  color: $input_border_color_hover
}

input::-webkit-outer-spin-button, input::-webkit-inner-spin-button, option::-webkit-outer-spin-button, option::-webkit-inner-spin-button, select::-webkit-outer-spin-button, select::-webkit-inner-spin-button, textarea::-webkit-outer-spin-button, textarea::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0
}

input[type=number], option[type=number], select[type=number], textarea[type=number] {
  -moz-appearance: textfield;
  appearance: textfield
}

input[type=checkbox], option[type=checkbox], select[type=checkbox], textarea[type=checkbox] {
  cursor: pointer;
  width: initial
}

option, select {
  cursor: pointer
}

option:hover, option.active {
  background-color: $primary_blue !important
}

.grid {
  display: grid
}

.grid--3 {
  grid-template-columns: 1fr;
  row-gap: 7.5rem
}

@media only screen and (min-width: 28em) {
  .grid--3 {
    grid-template-columns:repeat(2, 1fr);
    justify-content: space-between;
    -moz-column-gap: 1.5rem;
    column-gap: 1.5rem;
    row-gap: 5rem
  }
}

@media only screen and (min-width: 768px) {
  .grid--3 {
    grid-template-columns:repeat(3, 1fr)
  }
}

:is(h1,h2,h3,h4,h5,h6) {
  color: $primary_black;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0
}

h1 {
  font-size: 3.8rem;
  line-height: 60px
}

@media only screen and (max-width: 575px) {
  h1 {
    font-size: 2.9rem;
    line-height: 40px
  }
}

@media only screen and (max-width: 479px) {
  h1 {
    font-size: 2.5rem
  }
}

h2 {
  font-size: 3rem
}

@media only screen and (max-width: 575px) {
  h2 {
    font-size: 1.8rem
  }
}

@media only screen and (max-width: 479px) {
  h2 {
    font-size: 1.6rem
  }
}

h3 {
  font-size: 1.75rem
}

@media only screen and (max-width: 575px) {
  h3 {
    font-size: 1.15rem
  }
}

h4 {
  font-size: 1.5rem
}

@media only screen and (max-width: 575px) {
  h4 {
    font-size: 1.35rem
  }
}

h6 {
  font-size: 1.3rem
}

@media only screen and (max-width: 575px) {
  h6 {
    font-size: 1rem
  }
}

p {
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 28px;
  color: $primary_light_black;
  margin: 0
}

@media only screen and (max-width: 575px) {
  p {
    font-size: 1.1rem
  }
}

p.secondary-p, span.secondary-p {
  font-size: 1.125rem
}

@media only screen and (max-width: 575px) {
  p.secondary-p, span.secondary-p {
    font-size: .95rem;
    line-height: 25px
  }
}

a {
  font-family: Roboto, sans-serif;
  color: $primary_blue;
  font-size: .8rem;
  text-decoration: none;
  cursor: pointer
}

@media only screen and (max-width: 575px) {
  a {
    font-size: 1.15rem
  }
}

ol, ul {
  padding-left: 0 !important
}

:root {
  --PhoneInput-color--focus: #03b2cb;
  --PhoneInputInternationalIconPhone-opacity: .8;
  --PhoneInputInternationalIconGlobe-opacity: .65;
  --PhoneInputCountrySelect-marginRight: .35em;
  --PhoneInputCountrySelectArrow-width: .3em;
  --PhoneInputCountrySelectArrow-marginLeft: var(--PhoneInputCountrySelect-marginRight);
  --PhoneInputCountrySelectArrow-borderWidth: 1px;
  --PhoneInputCountrySelectArrow-opacity: .45;
  --PhoneInputCountrySelectArrow-color: currentColor;
  --PhoneInputCountrySelectArrow-color--focus: var(--PhoneInput-color--focus);
  --PhoneInputCountrySelectArrow-transform: rotate(45deg);
  --PhoneInputCountryFlag-aspectRatio: 1.5;
  --PhoneInputCountryFlag-height: 1em;
  --PhoneInputCountryFlag-borderWidth: 1px;
  --PhoneInputCountryFlag-borderColor: rgba(0, 0, 0, .5);
  --PhoneInputCountryFlag-borderColor--focus: var(--PhoneInput-color--focus);
  --PhoneInputCountryFlag-backgroundColor--loading: rgba(0, 0, 0, .1);
  --swiper-navigation-size: 44px;
  --swiper-theme-color: #007aff
}

// Input, Option & Select
@import '../../../assets/sass/variables';
@import '../../../assets/sass/mixins';

 /* Customize the label (the container) */
 .checkbox-container {
	display: block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 22px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

/* Hide the browser's default checkbox */
label.checkbox-container input[type=checkbox] {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

/* Create a custom checkbox */
.custom-checkbox {
	position: absolute;
	top: 0;
	left: 0;
	height: 25px;
	border: 1px solid rgba(0,0,0,.4);
	width: 25px;
	background-color: $primary_white;
}

  /* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .custom-checkbox {
	background-color: #eee;
}

  /* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .custom-checkbox {
	background-color: $primary_blue;
	border: none;
}

  /* Create the checkmark/indicator (hidden when not checked) */
.custom-checkbox:after {
	content: "";
	position: absolute;
	display: none;
}

  /* Show the checkmark when checked */
.checkbox-container input:checked ~ .custom-checkbox:after {
	display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .custom-checkbox:after {
	left: 8px;
	top: 4px;
	width: 5px;
	height: 10px;
	border: solid white;
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

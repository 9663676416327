@import '../../assets/sass/variables';
@import '../../assets/sass/mixins';

.select-date-modal {
  background-color: rgba(0, 0, 0, .02);
  width: 100%;
  z-index: 20;
  display: none;
  position: fixed;
  transition: all .4s;
  align-items: center;

  @include responsive(lg) {
    margin-block: -1.5rem;
    border-radius: $primary_border_radius;
  }

  .tip-title {
    color: #30b8b8;
    text-align: center;
    font-size: 1.5rem;
    font-weight: 300;
    font-family: Helvetica, sans-serif;

  }
  &--opened {
    height: 105vh;
    max-height: 105vh;
    transition: all .3s;
    display: flex;
  }

  &__content {
    width: 70%;
    border: 1px solid rgba(0,0,0,.2);
    background-color: $primary_white;
    margin: 0 auto;
    border-radius: $primary_border_radius;


    @include responsive(lg) {
      max-width: 500px;
    }
  }

  &__upper {
    border-bottom: 1.5px solid $primary_blue;
    text-align: center;
    width: calc(100% - 32px);
    margin: 0 16px;
    padding: .8rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .title {
      font-family: Lato, sans-serif;
      display: contents;
      margin: 0;
      color: #30b8b8;
      text-align: center;
      font-size: 24px;
      font-weight: 300;
      line-height: 24px;
      transform: translate(-50%, -50%);
    }

    .tip-wrapper {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .tip-image {
      min-width: 30px;
      width: 30px;
    }

    .close {
      border: none;
      outline: none;
      position: absolute;
      top: -16px;
      right: -32px;
      background: #9b9b9b;
      border-radius: 50%;
      height: 32px;
      width: 32px;
      color: #fff;
      font-size: 24px;
      font-weight: 700;
      //TODO font
      font-family: Helvetica, sans-serif;
    }
  }

  &__lower {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1rem;
    padding: 1rem;
    text-align: center;

    & p {
      margin: 0;
      @include responsive(lg) {
        font-size: 1.175rem;
      }
    }

    .body-main {
      text-align: center;
      font-weight: 200;
      margin-bottom: 0;
      font-size: .9rem;
    }

    .body-secondary {
      text-align: center;
      font-weight: 200;
      font-size: .8rem;
    }

    & .cta-underline {
      text-decoration: underline;
      background-color: transparent;
      border: none;
    }
  }

  .button-tip-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;

    button {
      width:  calc(100% - 44px);
    }
  }

  .select-more-dates-close-button {
    margin-top: 1rem;
    margin-bottom: 0;
    font-size: .7rem;
    text-decoration: underline;
    cursor: pointer;
  }
}

.flex {
    display: flex;
    column-gap: $primary_grid_column_gap;

    &--no-gap {
        gap: 0 !important;
    }
    &--gap-small {
        gap: 4px !important;
    }

    &--center {
        align-items: center;
    }

    &--justify-start {
        justify-content: start;
    }

    &--column {
        flex-direction: column;
        column-gap: initial;
        row-gap: $primary_grid_row_gap_mobile;
    }
    &--column-small-gap {
        flex-direction: column;
        column-gap: initial;
        row-gap: .7rem;
    }
}

.m-top {
    margin-top: $primary_margin_top !important;

    &--big {
        margin-top: $primary_margin_top_big_mobile !important;

        @include responsive(md) {
            margin-top: $primary_margin_top_small !important;
        }
    }
    &--medium {
        margin-top: $primary_margin_top_medium !important;
    }
    &--small {
        margin-top: $primary_margin_top_small !important;
    }
    &--extra-small {
        margin-top: $primary_margin_top_extra_small !important;
    }
}

.m-0 {
    margin-top: 0;
}

.mb-0{
    margin-bottom: 0;
}

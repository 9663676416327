@import "../../assets/sass/variables";
@import "../../assets/sass/mixins";

.step-1 {
    display: flex;
    flex-direction: column;

    .step-title {
        margin-bottom: -.5rem; // To hide h1 bottom line-height

        @include responsive(md) {
            margin-bottom: -1rem;
        }
    }

    // Address & Tasks Field
    .address-field {
        &__input-container {
            margin-top: 24px;
            width: $input_width;
        }
    }
    .tasks-field {
        width: $input_width;
    }
}

.jobform-partners {
    width: 45%;
    margin: 0 auto;
    margin-top: 5rem;

    .title {
        font-size: 13px;
        margin: 0 auto;
        text-align: center;
        color: #9b9b9b;
    }

    .partner-images {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        img {
            filter: grayscale(100%);
            padding: 0 1px;
            max-height: 13px;
            width: max-content;
            height: max-content;
        }

        #gamma {
            width: 18%;
        }

        #hornbach {
            width: 36%;
        }

        #praxis {
            width: 21%;
        }

        #bol {
            width: 28%;
        }
    }

    .subtitle {
        font-size: 12px;
        color: #9FBFC1;
        text-align: center;
    }
}

.subtitle {
    font-size: 12px;
    color: #9FBFC1;
    text-align: center;
}

.btm-details-msg {
    text-align: center;
    margin-bottom: 1.5rem;
    font-weight: 300;
    color: #9b9b9b;
    font-size: 16px;
    margin-top: 9px;
}

.label-subtitle {
    font-size: 18px;
    margin-bottom: 0.5rem;
    color: #9B9B9B;
}

.label-title {
    font-size: 26px;
    font-weight: 500;
    margin-top: 3rem;
    margin-bottom: 0;
    color: #4A4A4A;
}

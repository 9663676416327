@import "../../../assets/sass/variables";
@import "../../../assets/sass/mixins";

.small-bottom-text {
    display: block;
    text-align: left;
    font-weight: 700;
    color: #999;
    font-size: .8rem;
    margin-top: 9px;

    &--blue {
        color: #0c5460 !important;
        font-weight: 700;
        margin-top: 0 !important;
    }

    @include responsive(md) {
        font-size: .90rem;
    }
}

// Button
@import '../../../assets/sass/variables';
@import '../../../assets/sass/mixins';

button {
    cursor: pointer;
}

:is(a.secondary-cta.jobform-text, button.secondary-cta.jobform-text, input.secondary-cta.jobform-text) {
    display: inline-block;
    background-color: $primary_black;
    font-size: .88rem;
    padding: pxToRem(15) pxToRem(25);
    letter-spacing: 1.6px;
    border-radius: $primary_border_radius;
    color: $primary_white;
    font-weight: 700;
    border: none;
    text-transform: uppercase;
    transition: all .25s;
    position: relative;
    text-align: center;

    @include responsive(sm) {
        font-size: 1.05rem;
        padding: pxToRem(20) pxToRem(30);
    }

    &.disabled {
        background-color: $secondary_gray;
        pointer-events: none;

        &:hover {
            background-color: $secondary_gray;
        }
    }
    &:hover {
        background-color: $primary_blue;
    }
    &::before {
        background-color: rgba($primary_blue, .5);
    }
}

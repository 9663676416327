@import '../../assets/sass/variables';
@import '../../assets/sass/mixins';

.address-field {
    position: relative;
    width: 100%;

    &__location-icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        margin-left: 15px;
    }
    &__input-container { // Created to position the dropdown relatively to a parent element
        position: relative;
    }
    &__input {
        padding-left: 2.5rem;
        width: 100%;
        border-radius: $primary_border_radius $primary_border_radius 0 0;
    }
    &__info-icon {
        margin-top: 16px;
        cursor: pointer;

        // Show info message on info icon hover
        &:hover + span {
            opacity: 1;
            visibility: visible;
        }
    }
    &__info-message {
        width: 38%;
        opacity: 0;
        visibility: hidden;
        transition: .25s;
        position: absolute;
        z-index: 5;
        left: 45%;
        padding: .5rem 1.5rem;
        top: 85%;
        background-color: rgba(0, 0, 0, 0.75);
        color: $primary_white;
        font-size: .8rem;

        @include responsive(md) {
            left: 65%;
            padding: .8rem 1.75rem;
        }
    }
}

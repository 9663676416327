@import '../../assets/sass/variables';
@import '../../assets/sass/mixins';

.current-task-info,
.current-task-info--placeholder {
    height: 12px;
    margin-bottom: 1.4rem;
    margin-top: .875rem;

    @include responsive(sm) {
        height: 17.5px;
        margin-bottom: .5rem;
        margin-top: 1.5rem;
    }

    &__stars {
        column-gap: 5px !important;
    }

    &__text {
        font-weight: 700;
        font-size: .85rem;
        color: $input_placeholder_color;

        @include responsive(md) {
            font-size: .95rem;
        }
    }
}

.half-star-wrapper {
    position: relative;
}

.half-star {
    position: absolute;
    left: 0;
}

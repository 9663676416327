@import "../../../assets/sass/variables";
@import "../../../assets/sass/mixins";

.step-title {
    column-gap: 10px !important;

    &__back-arrow {
        cursor: pointer;
        transition: .2s;
        flex: 0 0 auto;

        &:hover {
            transform: scale(1.1);
        }
        &::before {
            content: " ";
            border-left: 3px solid #c5c5c5;
            border-bottom: 3px solid #c5c5c5;
            width: 8px;
            height: 8px;
            transform: rotate(45deg);
            cursor: pointer;
            display: block;
        }
    }

    p.step-title__text {
        font-size: 1.125rem;
        display: flex;
        flex-wrap: wrap;
    }
}

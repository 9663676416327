﻿@import 'base';

/* =======================
    ----JUMBO SECTION----
   ======================= */
$jumbo-background-color: #E6F2F7;
$jumbo-headingtext-color: $font-primary-color;
$jumbo-leadtext-color: $font-secondary-color;

//url(https://www.expatica.com/nl/wp-content/themes/expatica/assets/images/fallback-background.png) no-repeat center center;
//url(https://mamas.zoofy.nl/media/1126/slider1_0.jpg) no-repeat center center;
$jumbo-background-image: none;


/* =======================
    -----USP SECTION-----
   ======================= */
$usp-background-color: white;
$usp-title-color: $font-primary-color;
$usp-subtitle-color: $font-primary-color;


/* =======================
    PROFESSIONALS SECTION
   ======================= */
$professional-background-color: #E6F2F7;
$professional-heading-color: $font-primary-color;

$professional-category-text-color: #222;
$professional-button-background-color: white;
$professional-button-icon-color: $color-secondary;

$professional-category-text-hover-color: $color-white;
$professional-button-background-hover-color: $color-secondary;
$professional-button-icon-hover-color: $color-white;

/* =======================
    HOW IT WORKS SECTION
   ======================= */
$how-background-color: white;
$how-heading-color: $font-primary-color;
$how-title-color: $font-primary-color;
$how-subtitle-color: $font-primary-color;


/* =======================
    ---REVIEWS SECTION---
   ======================= */
$reviews-background-color: white;
$reviews-heading-color: $font-primary-color;
$reviews-lead-color: $font-primary-color;

$reviews-review-title: $font-primary-color;
$reviews-review-taskdate: $font-primary-color;
$reviews-review-content: $font-primary-color;


/* =======================
    -----MAP SECTION-----
   ======================= */
$map-background-color: white;
$map-heading-color: $font-primary-color;




/* =======================
    --CATEGORY SECTION--
   ======================= */
$category-background-color: #A3DFDE;
$category-heading-color: $font-primary-color;
$category-lead-color: #476464;
$category-title-color: $font-primary-color;
      /* TASK BUTTONS */
$task-background: $color-white;
$task-title-color: $font-primary-color;
$task-price-color: $color-secondary;
$task-border-color: $color-secondary;

$task-background-hover: $color-secondary;
$task-title-hover-color: $color-white;
$task-price-hover-color: $color-white;
$task-border-hover-color: $color-secondary;



/* =======================
    -----SEO SECTION-----
   ======================= */
$seo-category-background-color: white;
$seo-category-heading-color: $font-primary-color;
$seo-category-content-color: #555;

$seo-location-background-color: #F7F7F7;
$seo-location-heading-color: $font-primary-color;
$seo-location-link-color: #222;

/* =======================
    ---FOOTER SECTION---
   ======================= */
$footer-background-color: #F7F7F7;
$footer-heading-color: $font-primary-color;
$footer-link-color: $font-primary-color;

$footer-company-background: #F7F7F7;
$footer-company-color: $font-primary-color;
/* =======================
    -BREADCRUMB SECTION-
   ======================= */
//background color same as footer
$breadcrumb-icon-color: $font-primary-color;
$breadcrumb-text-color: #555;

@import '../../assets/sass/variables';
@import '../../assets/sass/mixins';

.calendar-wrapper-box {
  margin-bottom: 44px;
}

.calendar-wrapper-rel {
  position: relative;
  display: flex;
  justify-content: center;
  gap: 20px;

  & > div:last-child {
    max-height: 180px;
    margin-top: 60px !important;
  }

  @media screen and (max-width: 800px) {
    flex-wrap: wrap;
    justify-content: center;

  }
}

.close-calendar {
  position: absolute;
  top: 12px;
  right: 0;
  font-size: 1.2rem;
  cursor: pointer;
  transition: all .1s;
  display: flex;
  justify-content: flex-end;
  width: fit-content;
  margin-left: auto;
  color: $primary_black;

  &:hover {
    transform: scale(1.075);
  }

  @media only screen and (max-width: 800px) {
    right: -25px;
  }
}

.react-calendar {
  max-width: 290px;
  min-width: 290px;
  min-height: 380px;
  height: 100%;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  padding-bottom: .8rem;
  font-family: 'Roboto', sans-serif;

  &__navigation {
    margin-bottom: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;

    &__arrow, &__label {
      border: none;
      background-color: $primary_white;
      font-size: 2.5rem;
    }

    &__label {
      font-size: 1.25rem;
      flex-grow: .25 !important;
      margin: 0 .7rem;
      margin-bottom: -4.5px;
      text-transform: capitalize;
    }

    &__prev-button[disabled] {
      color: rgba($color: #000000, $alpha: .3);
      cursor: initial;
    }

    &__prev2-button, &__next2-button {
      display: none;
    }
  }

  &__month-view {

    &__weekdays {

      &__weekday {
        display: flex;
        justify-content: center;
        margin-bottom: .85rem;
        border-bottom: 1px solid rgba(0, 0, 0, .1);
        padding-bottom: .8rem;

        & * {
          text-decoration: none;
          color: #D8D8D8;
          font-weight: 700;
          text-transform: uppercase;
        }
      }
    }
  }

  &__tile {
    border: none;
    background-color: $primary_white;
    font-size: .95rem;
    padding: 18px 0;
    transition: all .25s;
    border-radius: $primary_border_radius;

    &[disabled] {
      color: rgba($color: #000000, $alpha: .3);

      &:hover {
        background-color: $primary_white !important;
        cursor: initial;
      }
    }

    &--rangeStart {
      border-top-left-radius: 100px;
      border-bottom-left-radius: 100px;

      &--big abbr {
        padding: 14px 14px !important;
      }

    }

    &--rangeEnd {
      border-top-right-radius: 100px;
      border-bottom-right-radius: 100px;

      &--big abbr {
        padding: 10px 14px !important;
      }
    }

    &--rangeBothEnds {
      background-image: initial;
      border-radius: 0;
    }

    &--inactive {
      background-image: linear-gradient(white, white 22%, white 7%, white 79%, white 79%) !important;
    }

    &--activeSingle {
      background-image: linear-gradient(white, white 22%, white 7%, white 79%, white 79%) !important;
    }

    &--active {
      background-image: linear-gradient(white, white 22%, #34b9b8 7%, #34b9b8 79%, white 79%);

      abbr {
        padding: 6px 10px;
        width: 30px;
        height: 30px;
        color: $primary_white;
       }
    }

    &--rangeStart, &--rangeEnd {
      padding: 18px 0;

      abbr {
        padding: 10px 10px;
        width: 30px;
        height: 30px;
        color: $primary_white;
        border-radius: 100%;
        background-color: rgba($primary_blue, 1);
        box-shadow: 0 1px 3px 0 #666;
      }

      &:hover {
        abbr {
          padding: 10px 10px;

          width: 30px;
          height: 30px;
          color: $primary_white;
          border-radius: 100%;
          background-color: $primary_blue;
        }
      }
    }
  }
}

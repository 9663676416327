@import '../../assets/sass/variables';
@import '../../assets/sass/sections';

.warranty-package {
  max-width: calc(50% - 16px);

  .insurance-promotion-label {
    font-size: 12px;
    height: 20px;
    color: #fff;
    background: #5db3b5;
    padding: 3px 5px;
    border-radius: 3px;
  }

  .warranty-plus {
    color: $primary_yellow;
    font-size: 1.4rem;
  }

  .warranty-package-title {
    font-size: .8rem;
    color: #979797;
    padding-left: 10px;
  }

  .checkbox-container {
    padding-left: 1.05rem;
    margin-bottom: 1.5rem;
  }

  .custom-warranty-checkbox {
    width: 13px;
    height: 13px;

    &:after {
      left: 5px;
      top: 2px;
      width: 2px;
      height: 5px;
      border: solid white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }

  &__checkbox {

    span {
      font-weight: 600;
      font-size: .9rem;
      color: $primary_gray;
    }
  }

  &__accordion {
    width: $input_width;
    box-shadow: 0 1px 1px 0;
    overflow: hidden;
    color: #ccc;

    // When accordion is opened
    &--opened {
      box-shadow: none;

      & .warranty-package__accordion__top {
        background-color: $primary_yellow;

        & span {
          color: $primary_white;
        }
      }

      & .warranty-package__accordion__bottom {
        background-image: url("../../assets/zoofy/images/guarantee.png");
        background-size: 60px 60px;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        max-height: 500px;
        border: 1px solid $primary_yellow;
        padding: 1rem;
        transition: all .5s;
      }
    }

    &__top {
      justify-content: space-between;
      cursor: pointer;
      padding: .4rem 1rem;

      & span {
        font-size: .95rem;
        color: $primary_gray;
        font-weight: 700;
      }

      & span:last-of-type {
        font-size: 2rem;
      }
    }

    &__bottom {
      max-height: 0;
      transition: all .3s;
      padding: 0 1rem;
      row-gap: .5rem !important;

      & p {
        font-weight: 700;
        margin: 0;
      }

      &__list {
        padding-left: 1rem !important;
        font-size: 16px;
        font-family: 'Roboto', sans-serif;
        display: flex;
        flex-direction: column;
        row-gap: .7rem;
        color: #979797;

        & li::marker {
          content: "✓";
          font-family: Lato, sans-serif;
          font-size: 17px;
          color: green;
          transform: rotate(45deg);
        }
      }

      &__link-to {
        width: max-content;
        color: $color-secondary;
        font-size: 1rem;
        font-weight: 400;
        text-decoration: none;

        &:hover {
          box-shadow: inset 0 -27px 0 -26px $color-secondary
        }

      }
    }
  }
}

.step-3 {

    & > span {
        text-align: center;
    }

    .sub-title-text {
        font-size: 25px;
        color: gray;
        font-weight: 500;
        font-family: Roboto, sans-serif;
    }

    > div:first-child {
        margin-bottom: 48px;
    }
    & .spinner-container {
        height: 125px;
    }
}

@import '../../assets/sass/variables';
@import '../../assets/sass/mixins';

.swiper-slide {
  max-width: 98px !important;
  width: 100% !important;
  margin-right: 25px;


  @media screen and (max-width: 560px) {
    margin-right: 20px;
  }
}

.single-date {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  filter: drop-shadow(0px 1px 1px #dfdfdf);
  padding: 5px 5px 9px 5px;
  color: $primary_blue;
  border: 1px solid $primary_blue;
  transition: all .3s;
  row-gap: 0 !important;
  cursor: pointer;
  height: 98px;
  border-radius: $primary_border_radius;

  .selected-dot {
    margin-top: 3.5px;
    background-color: $primary_white;
    width: 7px;
    height: 7px;
    border-radius: 50%;
  }

  &:hover, &--active {
    filter: drop-shadow(0px 1px 1px #dfdfdf);
    color: $primary_white;
    border: 1px solid $primary_blue;
    background-color: $input_focus_color;

    .selected-dot {
      background-color: $primary_white;
    }
  }

  &__day {
    font-size: 18px;
    height: 24px;
  }

  &__number {
    font-size: 26px;
  }

  &__month {
    font-size: 17px;
    height: 24px;
  }

  &__more {
    font-size: 15px;
    line-height: 18px;
    text-align: center;
  }

  &__more-img {
    padding-top: 8px;
  }

  & > span {
    pointer-events: none;
    font-family: "Roboto-Medium", sans-serif;
  }
}

.calendar-wrapper {
  position: relative;
  display: flex;
  max-width: 300px;
  width: 100%;
  gap: 10px;
}

.date-slider {
  display: flex;
  justify-content: center;
  width: 100%;
  overflow: hidden;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
}

.show {
  display: flex!important;
}

.selected-day {
  color: $primary_white;
  background-color: $primary_blue;
  border: 1px solid $primary_blue;
}

.swiper.swiper-horizontal {
  margin: 0;
  padding: 0;
}

// Swiper Slider Navigation Arrows
.swiper-button-prev, .swiper-button-next {
  color: $primary_blue !important;
  font-size: 1.5rem;
}

.swiper-button-prev {
  left: -.1% !important;
}

.swiper-button-next {
  right: -.1% !important;
}

.step-2__text-calendar > span.small-bottom-text {
  margin-top: 0 !important;
}


@import "../../assets/sass/variables";

.terms-and-condition {
  text-decoration: underline;
  font-weight: 700;
  font-size: .9rem;
  color: $primary_blue;
}

.terms-conditions-wrapper {
  display: flex;
  margin-bottom: 10px;

  span {
    margin: 0 !important;
  }
}

.checkbox-container {
  padding-left: 1.05rem;
  margin-bottom: 1.5rem;
}

.custom-warranty-checkbox {
  width: 13px;
  height: 13px;
  &:after {
    left: 4px!important;
    top: 1px!important;
    width: 2px!important;
    height: 5px!important;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}

.terms-conditions-done {
  margin: auto auto 8px auto;
  display: flex;
  gap: 2px;
  max-width: 400px;

  span {
    margin: 0!important;
  }
  label {

  }
}

.term-title {
  font-weight: 400;
  a {
    padding: 0 2px;
    font-size: 0.8rem!important;
  }
}


/**
 * Px to Em (for Media Queries) and Px to Rem
*/
$browser-context: 16; // Default

@function pxToEm($pixels, $context: $browser-context) {
  @return #{calc($pixels/$context)}em;
}
@function pxToRem($pixels, $context: $browser-context) {
    @return #{calc($pixels/$context)}rem;
}


/**
 * Media Queries
*/
$breakpoints: (
    'xxs': pxToEm(400),
    "xs":  pxToEm(480),
    "sm":  pxToEm(576),
    "md":  pxToEm(768),
    "lg":  pxToEm(992),
    "xl":  pxToEm(1200),
    "xxl": pxToEm(1350)
);
@mixin responsive($width, $type: min) {
    @if map_has_key($breakpoints, $width) {

        $width: map_get($breakpoints, $width);
        @if $type == max {
            $width: $width - pxToEm(1);
        }
        @media only screen and (#{$type}-width: $width) {
            @content;
        }

    }
}


/**
 * Font-Face
*/
@mixin font-face($name, $file) {
    @font-face {
        font-family: "#{$name}";
        src: url("../../../fonts/#{$file}.eot");
        src: url("../../../fonts/#{$file}.eot?#iefix") format("embedded-opentype"),
        url("../../../fonts/#{$file}.woff") format("woff"),
        url("../../../fonts/#{$file}.ttf") format("truetype"),
        url("../../../fonts/#{$file}.svg?#webfont") format("svg");
    }
}


/**
 * Here is a great Sass mixin for supporting retina-ready images you can
 * serve to Apple devices with a Retina Display. To use this mixin, you need
 * to provide two versions of the same image, one in single size (1x) and one in double size (2x).
 * You can use this mixin to provide a retina-ready background image for any HTML element.
*/
@mixin retina-image($image, $width, $height) {
   @media (min--moz-device-pixel-ratio: 1.3),
   (-o-min-device-pixel-ratio: 2.6/2),
   (-webkit-min-device-pixel-ratio: 1.3),
   (min-device-pixel-ratio: 1.3),
   (min-resolution: 1.3dppx) {
       background-image: url($image);
       background-size: $width $height;
   }
}


/**
 * Absolute Positioning
*/
@mixin absolute($top, $right, $bottom, $left) {
    position: absolute;
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
 }


/**
 * Custom Arrow
*/
 @mixin arrow($direction: down, $size: 5px, $color: $primary_black) {
    width: 0;
    height: 0;
    @if ($direction == left) {
       border-top: $size solid transparent;
       border-bottom: $size solid transparent;
       border-right: $size solid $color;
    }
    @else if ($direction == right) {
       border-top: $size solid transparent;
       border-bottom: $size solid transparent;
       border-left: $size solid $color;
    }
    @else if ($direction == down) {
       border-left: $size solid transparent;
       border-right: $size solid transparent;
       border-top: $size solid $color;
    }
    @else {
       border-left: $size solid transparent;
       border-right: $size solid transparent;
       border-bottom: $size solid $color;
    }
}

/**
 * Custom Triangle
*/
@mixin css-triangle($color, $direction, $size: 6px, $position: absolute, $round: false){
    @include pseudo($pos: $position);
    width: 0;
    height: 0;
    @if $round {
        border-radius: 3px;
    }
    @if ($direction == down) {
        border-left: $size solid transparent;
        border-right: $size solid transparent;
        border-top: $size solid $color;
        margin-top: 0 - round( $size / 2.5 );
    }
    @else if ($direction == up) {
        border-left: $size solid transparent;
        border-right: $size solid transparent;
        border-bottom: $size solid $color;
        margin-bottom: 0 - round( $size / 2.5 );
    }
    @else if ($direction == right) {
        border-top: $size solid transparent;
        border-bottom: $size solid transparent;
        border-left: $size solid $color;
        margin-right: -$size;
    }
    @else if  ($direction == left) {
        border-top: $size solid transparent;
        border-bottom: $size solid transparent;
        border-right: $size solid $color;
        margin-left: -$size;
    }
}

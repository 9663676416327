.stepper-wrapper {
  border: 1px solid #34B9B9;
  background-color: #34B9B9;
  padding: 6px;
  border-radius: 100%;
  width: 32px;
  height: 32px;
}

.step-wrapper {
  width: 100%;
  margin-top: 16px;
  display: flex;
  gap: 8px;
  align-items: center;
}

.stepper-container {
  width: 100%;
  max-width: 235px;
  margin: 0 auto;
}


.MuiStepLabel-root .Mui-completed {
  color: #34b9b9 !important;
}

.MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel {
  color: #fff;
}

.MuiStepLabel-root .Mui-active {
  color: white;
  border: 1px solid #34b9b9 !important;
  border-radius: 100%;
  padding-right: 0;

  circle {
    color: #fff;
  }
}

.MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel {
  color: #34b9b9 !important;
}

.MuiStepLabel-root .Mui-active .MuiStepIcon-text {
  fill: #34b9b9 !important
}

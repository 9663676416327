@import "mixins";
@import "variables";

:is(h1.jobform-text, h2.jobform-text, h3.jobform-text, h4.jobform-text, h5.jobform-text, h6.jobform-text) {
    margin-top: 24px;

    @include responsive(md) {
        margin-top: 32px;
    }
}

:is(h1.jobform-text) {
    margin: 0;
}

:is(h1.jobform-text, h2.jobform-text, h3.jobform-text, h4.jobform-text, p.jobform-text, span.jobform-text) {
    font-family: 'Lato', sans-serif;
}

h1.jobform-text {
    font-size: pxToRem(48);
    line-height: 55px;

    @include responsive(md) {
        font-size: pxToRem(65);
        line-height: 80px;
    }
}

h2.jobform-text, h3.jobform-text {
    font-size: pxToRem(42);
    line-height: 45px;

    @include responsive(md) {
        line-height: 35px;
    }
}

h4.jobform-text {
    font-size: pxToRem(32);
}

label.jobform-text {
    font-size: .92rem;
    font-weight: 700;
}

span.jobform-text {
    font-weight: 400;
    display: inline-block;
}

span.jobform-main-text {
    font-weight: bolder;
}

:is(input, select, textarea, datalist, a, button) {
    font-family: 'Roboto', sans-serif;
}

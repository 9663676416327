.time-checkboxes {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    opacity: 0;
    max-height: 0;
    transition: all .55s;
    font-weight: 600;
    &--show {
        opacity: 1;
        max-height: 800px;
    }

    &--size {
        max-width: 235px;
    }
    &__spinner {
        justify-content: flex-start;
        height: 200px;
    }

    @media screen and (max-width: 751px) {
        justify-content: center;
    }
}

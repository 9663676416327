.step-4 {

  &__title {
    margin-bottom: 1rem !important;
  }

  .step-title {
    font-family: Roboto, "Helvetica Neue", "sans-serif";
    font-weight: 400;
    line-height: 1.5;
    font-size: 16px;
    color: #999 !important;
    margin: 6px 0 36px 0;
  }

  .payment-footer-container {
    padding-top: 2rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
  }

  .payment-footer-text {
    text-align: center;
    font-family: Roboto,"Helvetica Neue","sans-serif";
    color: black;
  }
}

﻿@import 'sections';

.form-control::-webkit-input-placeholder {
  color: $color-grey;
}

.form-control {
  &:-moz-placeholder {
    color: $color-grey;
  }

  &:-ms-input-placeholder {
    color: $color-grey;
  }
}

.form-control::-moz-placeholder {
  color: $color-grey;
}

.form-control::-ms-input-placeholder {
  color: $color-grey;
}

.line-break {
  width: 100%;
  clear: both;
  float: none;
  height: 20px;
}

html {
  &.homepage {
    section.homepage-jumbo.desktop, section.homepage-jumbo.mobile {

      .search-form {
        h1 {
          color: white;
        }

        p.lead {
          color: white;
        }

        .example {
          color: white;
          font-weight: 500;
        }

        #task-picker {
          .input-group-prepend {
            border-left: 5px solid rgba(105, 116, 123, 0.35);
            border-top: 5px solid rgba(105, 116, 123, 0.35);
            border-bottom: 5px solid rgba(105, 116, 123, 0.35);
            border-bottom-left-radius: 15px;
            border-top-left-radius: 15px;
          }

          .typeahead__container {
            border-top: 5px solid rgba(105, 116, 123, 0.35);
            border-bottom: 5px solid rgba(105, 116, 123, 0.35);
          }

          .input-group-append {
            border-top: 5px solid rgba(105, 116, 123, 0.35);
            border-bottom: 5px solid rgba(105, 116, 123, 0.35);
            border-right: 5px solid rgba(105, 116, 123, 0.35);
            border-bottom-right-radius: 15px;
            border-top-right-radius: 15px;
          }
        }

        .mob-search-container {
          background-color: rgba(105, 116, 123, 0.35);
        }
      }

      .why {
        border-radius: 0;
      }

      section.desktop-partners {
        .partner-title {
          color: #fff;
          font-size: 1rem;
          font-weight: 700;
        }
      }

      section.mobile-partners {
        background: none;

        .partner-title {
          color: white;
        }
      }
    }

    section.popular-task-mobile {
      .see-all-tasks {
        display: none;
      }
    }
  }

  &.category {
    section.homepage-jumbo.desktop {
      padding-bottom: 100px;
    }
  }
}

body {
  font-family: Roboto, sans-serif;;
  display: flex;
  flex-direction: column;
  /*padding-top: 54px;*/
  /*Homepage Styling by section*/
  /*Jumbo Header*/
  .items-list {
    display: flex;
    flex-flow: row;
    align-items: flex-start;
    flex-wrap: wrap;

    .category-title {
      color: #338899;
      font-size: 1.3em;
      text-transform: capitalize;
    }

    .category-list-block {
      margin: 15px;
      width: calc(100% / 3.3);
      box-sizing: border-box;
    }

    .category-list {
      list-style: none;
      margin: 0;
      padding: 0;

      .category-list-item {
        width: 100%;
        margin: 15px 0;
      }
    }

    .item-title {
      margin: 5px 0;
      font-weight: 700;
      font-size: 1em;
      color: #333;
    }
  }

  .search-block {
    position: relative;

    .search-control {

      button {
        background: transparent;
        border: none;
        color: #999;
        position: absolute;
        top: 8px;
        right: 5px;
      }
    }
  }

  .discount-banner {
    display: none;
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
    justify-content: flex-start;
  }


  section.homepage-jumbo {
    background-color: $jumbo-background-color;
    min-height: calc(420px + 8vw);
    position: relative;
    /*&.mobile {
            background-image: url("/assets/zoofy/images/Zoofy-klus-hulp-mobile.jpg");
            background-size: cover;
            background-position: center;
        }*/
    .search-form {
      max-width: 700px;
      margin: auto;
      padding-top: 3rem;

      h1 {
        font-family: Roboto, sans-serif;;
        font-size: 40px;
        font-weight: bold;
        color: $jumbo-headingtext-color;
      }

      .example {
        color: scale-color($jumbo-leadtext-color, $lightness: -20%);
        text-align: left;
        margin-left: 3rem;
        margin-bottom: 3rem;
      }

      .input-group {
        input {
          @extend %extend_1;

          &.text {
            height: 70px;
            font-size: 1.25rem;
            outline: none;
            border: none !important;
            box-shadow: none !important;
            padding-left: 0;
          }

          &.text::placeholder {
            font-size: 1rem;
          }

          &.house-number {
            max-width: 140px;
            margin-left: 3px;
            padding-left: 1rem;
          }
        }

        .btn {
          border-top-right-radius: 9px;
          border-bottom-right-radius: 9px;
          padding: 1rem 2rem;
          font-size: 1.5rem;
          font-weight: 500;
          background-color: #FFA500;
          border: none;
          display: flex;
          align-items: center;

          .spinner {
            display: none;
          }

          &.searching {
            span {
              display: none;
            }

            i {
              display: none;
            }

            .spinner {
              display: block;
              margin-top: -5px;

              img {
                width: 25px;
              }
            }
          }

          &:hover {
            background-color: #f09b00;
          }

          > i {
            margin-right: .5rem;
          }
        }
      }

      button {
        @extend %extend_1;
      }

      .input-group-prepend {
        border-right: none !important;

        .input-group-text {
          background: $color-white;
          border: none;
          border-top-left-radius: 9px;
          border-bottom-left-radius: 9px;
          padding: 1.5rem 1.5rem 1.5rem 1.75rem;
        }

        .no-border-radius {
          border-radius: 0;
        }
      }

      p.lead {
        color: $jumbo-leadtext-color;
        margin-bottom: 2rem;
        font-size: 1.3rem;
      }

      .score {
        a {
          color: scale-color($jumbo-leadtext-color, $lightness: -10%);
          //darken( $jumbo-leadtext-color, 20% )
        }
      }
    }

    .js-typeahead {
      width: calc(25px + 21vw);
      margin: 0 auto;

      &:focus {
        border: 0;
        outline: none;
      }
    }

    .typeahead__result {
      position: absolute;
      background: $color-white;
      padding: 0;
      z-index: 2;
      width: 100%;
      box-shadow: 0 2px 4px $color-black-opacity-20;

      .typeahead__list {
        .typeahead__item {
          line-height: 1.8rem;
        }
      }

      > ul > li > a {
        text-decoration: none;
        padding: 0 0 0 1rem;
        margin: 0;
        width: 100%;
        display: block;

        &:hover {
          background: $color-secondary;
          color: $color-white !important;

          .typeahead__display {
            color: $color-white;
          }

          div {
            span {
              color: white;
            }
          }
        }

        div {
          span {
            color: #222;
          }

          .result-category {
            color: #999;
          }
        }
      }
    }

    .typeahead__container {
      position: relative;
    }

    .typeahead__list {
      padding: 0;
      width: 100%;
      list-style: none;
      margin-bottom: 0;

      .alert.alert-warning {
        display: block;
        line-height: 1.5rem;
        margin: 0;
      }
    }

    .typeahead__item {
      line-height: 2rem;
    }

    .typeahead__display {
      color: $color-black;
      font-size: calc(10px + 0.5vw);
    }

    .bootstrap-select {
      .dropdown-menu {
        top: -70px !important;
        border: none;
        border-radius: 0;
      }

      .dropdown-toggle {
        .filter-option {
          display: flex;
          background: $color-white;
          font-family: Roboto, sans-serif;;
          align-items: center;
          padding-left: .5rem;
        }

        .filter-option-inner-inner {
          font-size: 1.15rem;
          font-weight: 400;
        }
      }
    }

    section.desktop-partners {
      margin-left: 3rem;

      .partner-title {
        color: #000;
        font-size: 1rem;
        font-weight: 700;
      }

      #Karwei.image-container {
        width: 13% !important;
      }

      #Gamma.image-container {
        position: absolute;
        bottom: 40px;
        left: 13px;
        width: 8%;
      }

      .image-container {
        display: flex;
        width: 30%;
        align-items: center;
        margin-right: 20px;

        .media {
          .partner {
            width: 100%;
          }
        }
      }
    }

    &.mobile {
      display: block;

      .mob-search-container {
        display: flex;
        flex-direction: column;
        background-color: #CFDADE;
        padding: 0.3rem;
        border-radius: 12px;

        .fake-input {
          height: 4rem;
          border-radius: 12px;
          border: none;
          margin-bottom: 0.4rem;
          padding-left: 2rem;
          outline: none;
          text-align: left;
          background: white;
          color: #aaa;
        }

        .fake-input-button {
          height: 4rem;
          border-radius: 12px;
          color: #fff;
          background-color: #FFA500;
          font-size: 1.4rem;
          font-weight: 500;
        }
      }
    }
  }

  #mob-search-modal {
    background: white;
    overflow: auto;
    position: fixed;

    .modal-full {
      min-width: 100%;
      margin: 0;
    }

    .modal-full .modal-content {
      min-height: 100vh;
      border: none;
      border-radius: 0;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      max-height: 100vh;
      position: fixed;

      .modal-header {
        padding: 0;
        height: 4rem;
        position: sticky;
        position: -webkit-sticky;

        .close {
          margin: 0;
          height: 4rem;
          padding-right: 1.5rem;

          i {
            font-size: 1rem;
          }
        }

        .typeahead__container {
          width: 100%;
          height: 100%;
          position: absolute;


          .typeahead__result {
            position: absolute;
            width: 100%;
            top: 4rem;
            overflow: scroll;
            z-index: -1;
            -webkit-overflow-scrolling: touch;
          }

          .typeahead__list {
            padding-left: 0;

            .typeahead__item {
              list-style: none;
              min-height: 4rem;
              padding-left: 2rem;
              display: flex;
              align-items: center;

              &.active {
                a > .result-category {
                  color: white;
                }
              }

              a {
                color: black;

                .result-category {
                  color: #999;
                }

                &:hover {
                  .result-category {
                    color: white;
                  }
                }
              }
            }

            .alert.alert-warning {
              list-style: none;
              width: 110vw;
              border-radius: 0;
              margin-bottom: 0;
              z-index: -1;
            }
          }

          .typeahead__field {
            width: 100%;
            position: fixed;
            position: -webkit-sticky;
            /*top: 0;*/
            height: 4rem;
            border-bottom: 1px solid #eee;
            overflow: hidden;
            -webkit-overflow-scrolling: touch;
            transform: translate3d(0, 0, 0);
            -webkit-transform: translate3d(0, 0, 0);


            .typeahead__query {
              width: 100%;
              height: 100%;

              .js-typeahead {
                width: 100%;
                height: 100%;
                border: none;
                padding-left: 2rem;
                outline: none;
                position: absolute;
              }

              .close {
                /*bottom: 4rem;*/
                /*position: relative;*/
              }
            }
          }
        }
      }
    }
  }

  section.homepage-jumbo .why {
    background: $color-white;
    border-radius: 6px;
    margin-top: 3rem;

    .card-header {
      padding: 1.25rem 1.25rem 0 1.25rem;
      border: none;
      background: none;

      .usp-title {
        font-size: 1.25rem;
        font-weight: 500;
      }
    }

    .card-body {
      padding: 0 1.25rem 1.25rem 1.25rem;
    }
  }

  .why ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li:first-child i {
      color: #ffa825;
      font-size: 19px;
    }

    li {
      padding-top: 1rem;
      font-weight: lighter;
      font-size: .9rem;

      span {
        display: inline-block;
        max-width: 80%;
      }

      i {
        color: $color-secondary;
        font-size: 22px;
        margin-right: .5rem;
        margin-bottom: 1rem;
        display: inline-block;
        vertical-align: top;
      }
    }
  }

  /*Top Carousel Styling*/
  section.pro-carousel {
    background-color: #f1f8fa;
    text-align: center;
    padding: 3rem 11rem 2rem 11rem;

    .carousel-title {
      font-size: 2rem;
      font-weight: 500;
      margin-bottom: 0;
    }

    p.text-center {
      color: #777;
    }

    .carousel-card {
      margin: 1rem;
      display: block;
    }

    .card-header {
      background-color: #fff;
      margin-top: 5rem;
      border-radius: 6px 6px 0 0 !important;
      border: none !important;
      padding-bottom: 0;

      img.card-img-top.img-fluid {
        max-width: 70px;
        width: 70px;
        height: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: -60px;
        border-radius: 50%;
        background-color: #fed900;
      }

      p.card-subtitle {
        margin-top: 10px;
        color: #999;
        margin-bottom: 0;
      }
    }

    .card-body {
      background-color: #fff;
      border-bottom: 2px solid #f1f8fa;

      div.card-text {
        font-weight: bold;
        font-size: 20px;
        margin-bottom: 0;

        .zi {
          font-size: 19px;
          color: #34b9b9;
        }

        .z-witgoedmonteur:before,
        .z-computer:before {
          font-size: 21px;
        }
      }

      p.card-text {
        margin-top: 0;
        color: #999;
        font-size: 14px;
      }
    }

    .card-footer {
      background-color: #fff;
      border-radius: 0 0 6px 6px !important;
      padding: 1rem 1.25rem 1.25rem 1.25rem;
      border-top: none;

      p.card-text.region {
        font-weight: 400;
        font-size: 15px;
      }

      p.card-text {
        margin-top: 0;
        margin-bottom: 0;
        color: #999;
        font-size: 15px;
      }
    }

    span {
      &.score {
        display: flex;
        justify-content: center;
      }

      &.score.review {
        display: inline-block;
        overflow: hidden;
        position: relative;
        vertical-align: middle;
        line-height: 0;
        margin-bottom: 0;
      }

      &.stars-active.review {
        color: #eebd01;
        position: relative;
        z-index: 1;
        margin-bottom: 0;
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
      }

      &.stars-inactive.review {
        color: #777;
        position: absolute;
        margin-bottom: 0;
        top: 0;
        left: 3px;
      }
    }

    .score-wrap.review {
      display: inline-block;
      position: relative;
      margin-bottom: 0;
      padding-right: 10px;
    }

    .score {
      .review-stars {
        color: #999;
      }
    }

    .owl-next i, .owl-prev i {
      bottom: 0 !important;
    }

    .owl-dots {
      display: none;
    }
  }

  section.popular-task {
    background: #E6F2F7;
    padding: 1rem;
    padding-top: 3rem;
    padding-bottom: 2rem;

    .category-task-container {
      display: none;

      .see-all-tasks {
        display: none;
      }
    }

    .title {
      font-size: 1.4rem;
      padding-left: 1rem;
      margin-bottom: 0.5rem;
      font-weight: 700;
    }

    .popular-categories {
      display: flex;
      flex-wrap: nowrap;
      overflow-x: auto;
      padding-bottom: 1rem;
      margin-bottom: 1rem;

      .category-button {
        color: #69CACC;
        padding: 0.2rem 1rem;
        border-radius: 3rem;
        white-space: nowrap;
        font-size: 1.2rem;
        font-weight: 500;
        cursor: pointer;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none;
      }

      .category-button.selected {
        background: #34B9B9;
        color: white;
        padding: 0.2rem 1rem;
        border-radius: 3rem;
        white-space: nowrap;
        font-size: 1.2rem;
        font-weight: 500;
        transition: background-color 0.3s linear, color .3s linear;
      }
    }

    //chrome/chromium
    .popular-categories::-webkit-scrollbar {
      width: 1rem;
    }

    .popular-categories::-webkit-scrollbar-track {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    .popular-categories::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #34B9B9;
      outline: 2px solid slategrey;
    }

    .popular-categories::-webkit-scrollbar:vertical {
      display: none;
    }

    //firefox
    .popular-categories {
      scrollbar-color: #34B9B9 rgba(0, 0, 0, 0.3);
      scrollbar-width: thin;
    }

    .main-tasks-container {
      display: flex;
      justify-content: space-between;

      .main-task {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 0;
        margin-bottom: 1rem;
        padding: 1.5rem 1rem;
        border-radius: 1rem;
        /*background-image: url("/assets/zoofy/images/popular-task-image.png");*/
        background-repeat: no-repeat;
        background-size: cover;
        width: 66%;

        .task-title {
          color: white;
          font-size: 1.4rem;
          /*text-shadow: 0 0 5px black;*/
        }

        .review-container {
          padding-bottom: 0.7rem;

          .stars {
            display: inline-block;
            font-size: 16px;
            position: relative;
            overflow: hidden;
            line-height: 0;
            vertical-align: middle;
            z-index: 0;

            .stars-active {
              color: $color-stars-active;
              position: relative;
              z-index: 10;
              display: inline-block;
              overflow: hidden;
              white-space: nowrap;
            }

            .stars-inactive {
              color: $color-ccc;
              position: absolute;
              top: 0;
              left: 0;
            }
          }

          .review-text {
            color: white;
            font-size: 0.8rem;
            /*text-shadow: 0 0 5px black;*/
          }
        }

        .task-description {
          color: #fff;
          font-size: 1rem;
          margin-bottom: 1.5rem;
          width: 75%;
          /*text-shadow: 0 0 5px black;*/
        }

        .footer {
          display: flex;
          align-items: center;

          a {
            text-decoration: none;
            display: flex;
          }

          .from-price {
            font-weight: 500;
            color: white;
            padding-left: 1rem;
            /*text-shadow: 0 0 5px black;*/
          }

          a {
            display: block;
            background: none;
            color: white;
            border: .1rem solid #fff;
            border-radius: 10px;
            padding: 7px 10px 5px 10px;
            text-transform: uppercase;
            font-weight: 500;
            font-size: 0.9rem;
            cursor: pointer;
            transition: all 0.25s ease;
            max-width: 33%;
            text-align: center;
            /*text-shadow: 0 0 5px black;
        box-shadow: 0 0 2px black;*/
            &:hover {
              color: black;
              background: white;
              text-shadow: none;
              box-shadow: none;
            }
          }
        }
      }

      .main-task:nth-child(2) {
        width: 32%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .task-title {
          color: white;
          font-size: 1.4rem;
        }

        .review-container {
          padding-bottom: 0.7rem;

          .stars {
            display: inline-block;
            font-size: 16px;
            position: relative;
            overflow: hidden;
            line-height: 0;
            vertical-align: middle;
            z-index: 0;

            .stars-active {
              color: $color-stars-active;
              position: relative;
              z-index: 10;
              display: inline-block;
              overflow: hidden;
              white-space: nowrap;
            }

            .stars-inactive {
              color: $color-ccc;
              position: absolute;
              top: 0;
              left: 0;
            }
          }

          .review-text {
            color: white;
            font-size: 0.8rem;
          }
        }

        .task-description {
          color: #fff;
          font-size: 0.9rem;
          margin-bottom: 1.5rem;
          width: 100%;
        }

        .footer {
          display: flex;
          align-items: center;

          .from-price {
            font-weight: 500;
            color: white;
          }

          a {
            background: none;
            color: white;
            border: .1rem solid #fff;
            border-radius: 10px;
            padding: 7px 10px 5px 10px;
            text-transform: uppercase;
            font-weight: 500;
            font-size: 0.9rem;
            cursor: pointer;
            transition: all 0.25s ease;
            max-width: 45%;
            text-align: center;

            &:hover {
              color: black;
              background: white;
              border: .1rem solid #fff;
              border-radius: 10px;
              padding: 7px 10px 5px 10px;
              text-transform: uppercase;
              font-weight: 500;
              font-size: 0.9rem;
            }
          }
        }
      }
    }

    .other-task-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;

      a {
        text-decoration: none;
        width: 32%;
        margin-right: 1.1rem;

        .other-tasks {
          background: white;
          border-radius: 8px;
          padding: 0.7rem 1rem;
          margin-bottom: 1rem;
          border: 1px solid #fff;
          cursor: pointer;
          transition: all .25s ease;

          &:hover {
            border: 1px solid #34B9B9;
          }

          .other-task-title {
            font-size: 1.2rem;
            font-weight: 700;
            color: black;
          }

          .review-container {
            padding-bottom: 0.7rem;

            .stars {
              display: inline-block;
              font-size: 16px;
              position: relative;
              overflow: hidden;
              line-height: 0;
              vertical-align: middle;
              z-index: 0;

              .stars-active {
                color: $color-stars-active;
                position: relative;
                z-index: 10;
                display: inline-block;
                overflow: hidden;
                white-space: nowrap;
              }

              .stars-inactive {
                color: $color-ccc;
                position: absolute;
                top: 0;
                left: 0;
              }
            }

            .review-text {
              color: #777;
              font-size: 0.8rem;
            }
          }

          .from-price {
            color: #34B9B9;
            font-weight: 700;
            font-size: 1.1rem;
          }

          .in-area {
            color: #777;
            font-size: 0.75rem;
          }
        }
      }

      a:nth-child(3), a:nth-child(6) {
        margin-right: 0;
      }
    }
  }

  section.video {
    padding: 3rem 6rem;
    background: #f1f8fa;

    .container {
      max-width: 75%;
    }

    .embed-container {
      position: relative;
      padding-bottom: 56.25%;
      height: 0;
      overflow: hidden;
      max-width: 100%;
    }

    .embed-container iframe, .embed-container object, .embed-container embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  /*Explanation Section Styling*/
  section.explanation {
    padding: 3rem 6rem;
    background: #f1f8fa;

    &.explanation-desktop {
      .explanation-image-container {
        display: flex;
        flex-direction: row;
        background-size: contain;
        justify-content: space-between;
        align-items: center;
        min-height: 15rem;
        margin: 0 auto;
        //todo
        /*
                background: url('../src/assets/zoofy/images/how-arrows-desktop.svg') no-repeat center;
                */
        max-width: 75vw;

        .explanation-image {
          display: flex;
          flex-direction: column;

          img {
            position: relative;
            left: 1rem;
          }
        }

        .explanation-image:nth-child(4) {
          right: 1rem;
          position: relative;
        }
      }

      .explanation-text-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        span {
          font-weight: bold;
          font-size: 1.1rem;
          color: #334D6E;
          text-align: center;
          padding: 0 1rem;
          max-width: 20vw;
        }
      }
    }

    h2 {
      text-align: center;
    }

    p {
      text-align: center;
      color: #777;
      margin-bottom: 2rem;
    }
  }

  section.explanation-mobile {
    padding: 0 1.25rem 0 1.25rem;

    .heading {
      margin: 0;
    }

    .explanation-image-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      //todo
      /*            background: url('../src/assets/zoofy/images/how-arrows-mobile.svg');*/
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      justify-content: space-between;
      align-items: center;
      min-height: 33rem;
      position: relative;
      bottom: 2rem;

      .explanation-image {
        max-width: 48vw;
        width: 48vw;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;

        span {
          max-width: 50%;
          font-weight: bold;
          font-size: 1.1rem;
          color: #334D6E;
          text-shadow: 0 0 20px white;
        }
      }

      .explanation-image:nth-child(2) {
        position: relative;
        top: 3rem;
      }

      .explanation-image:nth-child(3) {
        position: relative;
        right: 3rem;
      }

      .explanation-image:nth-child(4) {
        position: relative;
        top: 7rem;
      }
    }
  }

  /*Publicity Section Styling*/
  section.publicity {
    padding: 3rem 0;
    border-top: none;

    h3 {
      text-align: center;
    }

    .media {
      padding: 1rem;
      align-items: center;
      justify-content: center;
      min-height: 120px;

      img {
        max-width: 100px;
        margin: auto;
        filter: grayscale(1);
      }
    }
  }

  /*Insurance Section Styling*/
  section.insurance {
    background-color: #F1F8FA;
    padding-bottom: 1rem;
    position: relative;
    margin-top: 0;

    p {
      color: #888787;
      font-weight: normal;
      text-align: center;
      font-size: 18px;
    }

    a {
      color: #34b9b9;
      font-weight: normal;
    }

    .full-width-border {
      width: 100%;
      padding: 3px;
      background: linear-gradient(to right, #ffcf55, #ffa825);
    }

    img.insurance-badge {
      display: block;
      width: 14%;
      max-width: 140px;
      margin-top: -4.5rem;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 2rem;
    }
  }

  /*Bottom Carousel Styling*/
  .customer-carousel {
    position: relative;

    .carousel-title {
      font-size: 2rem;
      font-weight: 500;
    }
  }

  .owl-prev {
    width: 15px;
    height: 100px;
    position: absolute;
    top: 40%;
    margin-left: -20px;
    display: block !important;
    border: 0 solid black;
    outline: none !important;

    i {
      right: 15px;
      font-size: 2rem;
      bottom: 60px;
      position: relative;
      outline: none;
    }
  }

  .owl-next {
    width: 15px;
    height: 100px;
    position: absolute;
    top: 40%;
    right: -25px;
    display: block !important;
    border: 0 solid black;
    outline: none !important;

    i {
      padding-left: 5px;
      font-size: 2rem;
      bottom: 60px;
      position: relative;
      outline: none;
    }
  }

  section.mobile-partners {
    display: none;
    padding: 2rem 3rem 2rem 3rem;
    background: #E6F2F7;

    .partner-title {
      font-size: 1rem;
      font-weight: 700;
      color: #777777;
    }
  }

  section.customer-carousel {
    background-color: #f1f8fa;
    text-align: center;
    padding: 2rem 11rem;

    h2 {
      margin-bottom: 0 !important;
    }

    p {
      &.mb-3 {
        margin-bottom: 2rem;
        color: #777;
      }

      &.card-text.name-city {
        font-weight: bold;
        color: #000;
        margin-top: 1rem;
        margin-bottom: 0;
      }
    }

    .card-body {
      background-color: #fff;
      border: 1px solid #ccc;
      position: relative;
      padding: 1rem 0 1rem 0;
      min-height: 210px;
      border-radius: 1.5%;

      i.zi {
        color: #34b9b9;
        font-size: 34px;
      }

      .card-subtitle {
        margin: 0;
        padding: 0.1rem;
        font-size: 13px;
        color: #999;
      }
    }

    .card-footer {
      background-color: transparent;
      border-top: none;

      .card-text.date {
        font-size: 13px;
        color: #666;
      }
    }

    .card-triangle {
      position: absolute;
      margin: auto;
      left: 0;
      right: 0;
      bottom: -10px;
      width: 20px;
      background-color: white;
      height: 20px;
      transform: rotate(45deg);
      border-right: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
    }

    span {

      &.score.review {
        display: inline-block;
        overflow: hidden;
        position: relative;
        margin-bottom: 0;
        vertical-align: middle;
        line-height: 0;
        font-size: 14px;
      }

      &.stars-active.review {
        color: #eebd01;
        position: relative;
        z-index: 1;
        margin-bottom: 0;
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
      }

      &.stars-inactive.review {
        color: #ccc;
        position: absolute;
        top: 0;
        margin-bottom: 0;
        left: 3px;
      }
    }

    .owl-item .carousel-card {
      margin: 1rem 2rem;
      display: block;
    }

    div.card-title {
      font-size: 16px;
      margin-bottom: 0;
      font-weight: 500;
    }

    .card-text .review-text.no-message {
      font-style: italic;
      color: #ccc;
      font-size: 0.8rem;
    }

    .card-text .review-text {
      color: #000;
      margin-top: 1rem;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      margin-bottom: 0;
      -webkit-transition: opacity .3s ease-in-out;
      -moz-transition: opacity .3s ease-in-out;
      -ms-transition: opacity .3s ease-in-out;
      -o-transition: opacity .3s ease-in-out;
      transition: opacity .3s ease-in-out;
    }

    .expand-review-text {
      color: $color-secondary;
      cursor: pointer;
      font-size: 0.8rem;
    }

    h2.card-title {
      font-weight: bold;
      font-size: 1.75rem;
    }

    .score-wrap.review {
      display: inline-block;
      position: relative;
      margin-bottom: 0;
      padding-right: 10px;
    }

    .owl-carousel {
    }

    .owl-nav {
      .owl-next {
        padding-right: 3rem !important;
      }

      .owl-prev {
        padding-left: 2rem !important;
      }
    }
  }

  section.pro-review {
    overflow: hidden;
    position: relative;

    .image-overlay {
      position: absolute;
      top: 0;
      width: 50%;
      right: 0;
      height: 100%;
    }

    .image-overlay::after {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      right: 0;
      background-color: #ccc;
      opacity: 0.5;
    }

    img {
      //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
      border-radius: 0;
      margin: 0;
    }

    .image-overlay-text {
      position: relative;
      z-index: 10;
      padding: 8rem;
      margin-left: -3rem;
    }

    .image-overlay-button {
      margin-top: 2rem;
      text-align: center;
      color: $color-black;

      h5 {
        font-size: 1.5rem;
      }
    }

    #submit-button {
      color: $color-white;
      background-color: #34b9b9;
      font-size: 1.5rem;

      &:hover {
        background-color: #2c9696;
      }
    }

    #heading {
      font-size: 27px;
      color: $color-black;
      margin-bottom: 2rem;
    }

    #name {
      font-weight: bold;
      color: $color-black;
      font-size: 22px;
      line-height: 1;
    }

    #job {
      font-size: 22px;
      color: #666;
    }
  }

  &.jobform {
    background: $color-white;

    section#map {
      position: fixed !important;
      width: 100vw;
      height: 152vh;
      margin-top: -52vh;
      opacity: 1;
      top: 0;
    }

    //desktop
    .step-container {
      display: flex;
      justify-content: space-around;
      align-items: center;
      border-bottom: 2px solid #fed900;
      padding-bottom: 20px;

      .step {
        text-align: center;
        width: 30%;

        &:after {
          content: "1";
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          bottom: -14px;
          padding: 15px;
          width: 20px;
          height: 20px;
          background: #f9c600;
          border-radius: 50%;
        }

        &.current {
          font-weight: 700;
          color: #000;

          &:after {
            background: #fed900;
          }
        }

        &.complete {
          color: forestgreen;
          font-weight: 700;

          &:after {
            font-weight: 400;
          }

          i.fa.fa-check {
            display: inline-block;
            color: forestgreen;
          }
        }

        i.fa.fa-check {
          display: none;
        }
      }

      .step-0 {
        &:after {
          content: "1";
          color: black;
          margin-left: 76px;
        }
      }

      .step-1 {
        color: #989898;

        &:after {
          content: "2";
          color: black;
          margin-left: 76px;
        }
      }

      .step-2 {
        color: #989898;

        &:after {
          content: "3";
          color: black;
          margin-left: 80px;
        }
      }

      i.fa.fa-arrow-right {
        color: black;
      }
    }

    #stepper-nav-container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      button {
        font-size: 1rem;
        background-color: #ffa500;
        border-color: #ffa500;

        &:hover {
          background-color: #e29200;
          border-color: #e29200;
        }
      }

      #stepper-prev {
        visibility: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        width: 50px;
        height: 50px;

        i {
          font-size: 1.4rem;
        }
      }

      #stepper-next {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        width: 50px;
        height: 50px;

        i {
          font-size: 1.4rem;
        }
      }
    }

    #job-form-steps {
      .calendar-dow-header {
        th {
          color: #212529;
        }
      }

      .calendar-dow {
        td {
          color: #212529;
        }
      }

      .time-range {
        color: #212529;
      }

      .time-price {
        span {
          color: #212529;
        }
      }

      .time-servicefee {
        color: #212529;
      }

      .steps.clearfix {
        display: none;
      }
    }

    section.jobform-desktop.slider-calendar {
      .card {
        width: 50%;
        max-width: 900px;
        margin: 4rem auto;

        .card-body {
          padding: 1.25rem 4rem;
          min-height: 35rem;
        }
      }

      .job {
        h3 {
          font-size: 42px;
          color: #4A4A4A;
          margin-top: 3rem;
          margin-bottom: 0;
        }
      }

      .jobform-stepper {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 0.9rem;

        .title {
          color: #777;
          font-size: 20px;
        }

        .stepper-container {
          width: 100%;
          display: flex;
          justify-content: center;

          .stepper-back-button {
            visibility: hidden;
            padding-top: 4px;
            position: relative;
            right: 35%;
            font-size: 1.1rem;
            color: #c5c5c5;
          }

          svg {
            padding: 4px 2px;
          }

          span {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            border: 1px solid black;
            width: 32px;
            height: 32px;
            text-align: center;
            /*padding: 0 7px;*/
            .fa-check {
              position: relative;
              top: 1px;
            }
          }

          #stepper1 {
            color: #34B9B9;
            border-color: #34B9B9;
            background: white;
            font-size: 18px;
          }

          #stepper2, #stepper3, #stepper4 {
            color: white;
            border-color: #D8D8D8;
            background: #D8D8D8;
            font-size: 18px;
          }
        }
      }

      #jobFormDesktopSteps {

        .form-field {
          margin-top: 0;

          .form-field__control {
            background: #fff;
            border-radius: 8px 8px;
            overflow: hidden;
            position: relative;
            width: 100%;

            .form-field__label {
              top: 24px;
              padding: 20px 12px 0;

              span {
                padding: 1px 3px
              }
            }

            .form-field__input, .form-field__textarea {
              border: 1px solid #ccc;
              padding: 0 !important;
              padding-left: 20px !important;
            }
          }
        }

        .form-field.form-field--is-active, .form-field.form-field--is-filled {
          .form-field__control {
            .form-field__label {
              -webkit-transform: translateY(-32px);
              transform: translateY(-32px);

              span {
                background: white;
              }
            }
          }
        }

        .content {

          #wizard {
            overflow: unset;

            section {
              .last-button {
                padding-top: 1rem;

                .input-group-append {
                  margin: 0 auto;
                  display: flex;
                  justify-content: center;

                  .jf-stepper-next {
                    background: #FFA500;
                    border-color: #FFA500;
                    padding: 10px 9rem;
                    font-size: 26px;
                    font-weight: 500;

                    &:disabled {
                      background: #D8D8D8;
                      border-color: #d8d8d8;
                    }
                  }
                }

                .btm-details-msg {
                  color: #9B9B9B;
                  text-align: center;
                  font-size: 16px;
                  margin-top: 9px;
                }
              }
            }

            .content.clearfix {
              background: white;

              section.address-validation {
                .job.desktop {
                  .taskInfo {
                    font-size: 1rem;
                    color: #9B9B9B;

                    span {
                      font-weight: normal;
                    }

                    span.stars {
                      display: inline-block;
                      font-size: 16px;
                      position: relative;
                      overflow: hidden;
                      vertical-align: middle;
                      z-index: 0;

                      .stars-wrap {
                        .stars-active {
                          color: #eebd01;
                          position: relative;
                          z-index: 10;
                          display: inline-block;
                          overflow: hidden;
                          white-space: nowrap;
                        }

                        .stars-inactive {
                          color: #ccc;
                          position: absolute;
                          top: 0;
                          left: 0;
                        }
                      }
                    }

                    #from-price {
                    }

                    .based-on {
                      color: #999;
                      font-size: 0.9rem;
                      margin: 0 0 1rem 0;
                    }
                  }
                }

                .label-title {
                  font-size: 26px;
                  font-weight: 500;
                  margin-top: 3rem;
                  margin-bottom: 0;
                  color: #4A4A4A;
                }

                .label-subtitle {
                  font-size: 18px;
                  margin-bottom: 0.5rem;
                  color: #9B9B9B;
                }

                .row.no-gutters.input-wrap {
                  display: flex;
                  flex-direction: row;
                  justify-content: unset;
                  align-items: baseline;

                  .postcode-input {
                    width: 40%;
                    margin-right: 1.5rem;

                    .form-field {
                      margin-top: 0;

                      .form-field__control {
                        background: #fff;
                        border-radius: 8px 8px;
                        overflow: hidden;
                        position: relative;
                        width: 100%;

                        .form-field__label {
                          top: 24px;
                          padding: 20px 12px 0;
                          font-size: 20px;

                          span {
                            padding: 1px 3px
                          }
                        }

                        .form-field__input {
                          font-size: 22px;
                          border: 1px solid #ccc;
                          height: 70px;
                          padding: 0 !important;
                          padding-left: 20px !important;
                        }
                      }
                    }

                    .form-field.form-field--is-active, .form-field.form-field--is-filled {
                      .form-field__control {
                        .form-field__label {
                          -webkit-transform: translateY(-32px);
                          transform: translateY(-32px);

                          span {
                            background: white;
                          }
                        }
                      }
                    }
                  }

                  .number-input {
                    width: 20%;

                    .form-field {
                      margin-top: 0;

                      .form-field__control {
                        background: #fff;
                        border-radius: 8px 8px;
                        overflow: hidden;
                        position: relative;
                        width: 100%;

                        .form-field__label {
                          top: 24px;
                          padding: 20px 12px 0;
                          font-size: 20px;

                          span {
                            padding: 1px 3px
                          }
                        }

                        .form-field__input {
                          font-size: 22px;
                          border: 1px solid #ccc;
                          height: 70px;
                          padding: 0 !important;
                          padding-left: 20px !important;
                        }
                      }
                    }

                    .form-field.form-field--is-active, .form-field.form-field--is-filled {
                      .form-field__control {
                        .form-field__label {
                          -webkit-transform: translateY(-32px);
                          transform: translateY(-32px);

                          span {
                            background: white;
                          }
                        }
                      }
                    }
                  }
                }

                .validation-message {
                  padding-top: 3rem;

                  #location-check-icon {
                    color: #34b9b9;
                    font-size: 1.5rem;
                  }

                  #validate-address {
                    font-weight: 100;
                    font-size: 12px;
                    color: #9b9b9b;
                  }

                  #invalid-address {
                    color: crimson;
                    font-weight: 100;
                    font-size: 12px;
                  }
                }

                .jobform-partners {
                  width: 45%;
                  margin: 0 auto;
                  margin-top: 5rem;

                  .title {
                    font-size: 13px;
                    margin: 0 auto;
                    text-align: center;
                    color: #9b9b9b;
                  }

                  .partner-images {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;

                    img {
                      filter: grayscale(100%);
                      padding: 0 1px;
                      width: 20%;
                      height: 20%;
                    }

                    #gamma {
                      width: 18%;
                    }

                    #hornbach {
                      width: 36%;
                    }

                    #praxis {
                      width: 21%;
                    }

                    #bol {
                      width: 28%;
                    }
                  }

                  .subtitle {
                    font-size: 12px;
                    color: #9FBFC1;
                    text-align: center;
                  }
                }
              }

              .when {

                #spoed-option-alert {
                  background-color: white;
                  border-color: white;

                  .time-checkbox-container {
                    .time-checkbox-label {
                      width: 15%;
                      padding-top: 3px;
                      padding-left: 5px;

                      .time-section {
                      }

                      .checkmark {
                        border-radius: 2px;
                      }

                      input:checked ~ .checkmark {
                        background-color: #34b9b9;
                        border-color: #34b9b9;
                      }

                      .checkmark:after {
                        border: solid #fff;
                        border-width: 0 3px 3px 0;
                        -webkit-transform: rotate(45deg);
                        -ms-transform: rotate(45deg);
                        transform: rotate(45deg);
                      }
                    }

                    .time-details {
                      p {
                        border: none;
                      }
                    }

                    .time-details {
                      .time-price {
                        .price-rate {
                        }

                        .rate-type {
                          display: inline-block;
                          color: #9B9B9B;
                          font-size: 12px;
                          font-weight: 400;
                        }
                      }
                    }
                  }
                }

                .row.date-picker {
                  .slider-title {
                    font-size: 32px;
                    color: #4a4a4a;
                    font-weight: 500;
                    padding: 2rem 0;
                  }

                  .scroll-container {
                    padding-left: 5px;

                    #newDates {
                      .date {
                        min-width: 13%;
                        max-width: 15%;
                        border-radius: 10%;

                        .day-day {
                          font-size: 18px;
                        }

                        .day-date {
                          .number {
                            font-size: 26px;
                          }

                          .month {
                            font-size: 18px;
                          }
                        }
                      }
                    }
                  }

                  .calendar-container {
                    display: flex;
                    margin-top: 1rem;
                    padding-left: 15px;
                    padding-bottom: 10rem;

                    p {
                      font-size: 18px;
                      color: #9b9b9b;
                    }

                    img {
                      padding-left: 20px;
                      height: 30px;
                    }
                  }

                  .bg.desktop-calendar-container {
                    max-width: 100%;
                    display: flex;

                    #my-calendar {
                      width: 55%;
                      padding-bottom: 3rem;

                      .zabuto_calendar {
                        .table {
                          td {
                            padding: 4px 2px;
                          }

                          .calendar-month-header {

                            td.calendar-header {
                              span {
                                float: unset;
                                text-align: center;
                                color: #4A4A4A;
                                font-size: 16px;
                                font-weight: 500;
                                padding: 0;
                              }
                            }

                            td {
                              .calendar-month-navigation {
                                i {
                                  color: #4A4A4A;
                                }
                              }
                            }

                            td {
                              background-color: white;
                            }
                          }

                          .calendar-dow-header {
                            th {
                              background: white;
                              color: #D8D8D8;
                            }
                          }

                          .calendar-dow {
                            td {
                              background: white;

                              .day {
                                margin: 5px auto;
                                height: 30px;
                                width: 30px;

                                .badge.badge-event {
                                  background: #34b9b9;
                                  color: white !important;
                                  border-radius: 50%;
                                  text-align: center;
                                  top: 0;
                                  font-weight: 400;
                                  padding-top: 22%;
                                  width: 100%;
                                  height: 100%;


                                  &:after {
                                    content: "";
                                  }
                                }
                              }
                            }
                          }

                          tr {
                            &:last-child {
                              height: unset;
                            }

                            td {
                              div.day {
                                margin: 5px;
                                border-radius: 50%;
                                height: 26px;
                                width: 26px;
                                font-variant-numeric: proportional-nums;
                                font-size: 14px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                padding: 1px 0 0 0;
                              }

                              div.day.activeDay {
                                --box-shadow-color: #666;
                                box-shadow: 0 1px 3px 0 var(--box-shadow-color);
                              }

                              div.day.withinRange, div.day.withinRangeConfirmed {
                                /*&.afterStartRange {
                                                    border-radius: 0;
                                                    --box-shadow-color: #34b9b8;
                                                    box-shadow: -20px 0 0 -1.5px var(--box-shadow-color);
                                                    position: relative;
                                                    top: 0.5px;
                                                }

                                                &.beforeEndRange {
                                                    border-radius: 0;
                                                    --box-shadow-color: #34b9b8;
                                                    box-shadow: 25px 0.5px 0 0 var(--box-shadow-color);
                                                }*/
                              }

                              &.startRange, &.startRangeConfirmed {
                                background-image: linear-gradient(white, white 22%, #34b9b8 7%, #34b9b8 79%, white 79%);
                                border-top-left-radius: 100vw;
                                border-bottom-left-radius: 100vw;

                                div.day {
                                  color: white;
                                  padding-top: 0;
                                  /*--box-shadow-color: #666;
                                                    box-shadow: 0 1px 3px 0 var(--box-shadow-color);*/
                                  span {
                                    --box-shadow-color: #666;
                                    box-shadow: 0 1px 3px 0 var(--box-shadow-color);
                                  }
                                }
                              }

                              &.endRange, &.endRangeConfirmed {
                                background-image: linear-gradient(white, white 22%, #34b9b8 7%, #34b9b8 79%, white 79%);
                                border-top-right-radius: 100vw;
                                border-bottom-right-radius: 100vw;

                                div.day {
                                  color: white;
                                  padding-top: 0;
                                  /*--box-shadow-color: #666;
                                                    box-shadow: 0 1px 3px 0 var(--box-shadow-color);*/
                                  span {
                                    --box-shadow-color: #666;
                                    box-shadow: 0 1px 3px 0 var(--box-shadow-color);
                                  }
                                }
                              }

                              &.withinRange, &.withinRangeConfirmed {
                                background-image: linear-gradient(white, white 22%, #34b9b8 7%, #34b9b8 79%, white 79%);

                                div.day {
                                  color: white;
                                }
                              }
                            }
                          }
                        }
                      }
                    }

                    //checkboxes when selecting from calendar
                    .options-checkboxes {
                      width: 50%;

                      .time-option-container {
                        flex-direction: column;
                        margin-left: 3rem;
                        margin-top: 4rem;

                        .time-checkbox-container {
                          width: 100%;

                          .time-checkbox-label {
                            width: 33%;

                            .time-section {
                              font-size: 16px;
                              color: #30B8B8;
                            }
                          }

                          .time-details {
                            .time-price {
                              border-color: #30B8B8;

                              .price-rate {
                              }

                              .rate-type {
                                display: inline-block;
                                color: #9B9B9B;
                                font-size: 12px;
                                font-weight: 400;
                              }
                            }

                            .time-servicefee {
                              border-color: #30B8B8;
                            }
                          }
                        }
                      }
                    }
                  }
                }

                //checkboxes when selecting from sliding calendar
                .options-checkboxes {
                  display: none;
                  margin-left: unset !important;
                  width: 100%;

                  .time-option-container {
                    display: flex;
                    flex-wrap: wrap;
                    width: 100%;

                    .time-checkbox-container {
                      width: 50%;

                      .time-checkbox-label {
                        .checkmark {
                          border-radius: 2px;
                        }

                        input:checked ~ .checkmark {
                          background-color: #34b9b9;
                          border-color: #34b9b9;
                        }
                      }

                      .checkmark:after {
                        border: solid #fff;
                        border-width: 0 3px 3px 0;
                        -webkit-transform: rotate(45deg);
                        -ms-transform: rotate(45deg);
                        transform: rotate(45deg);
                      }

                      .time-details {
                        .time-price {
                          border-color: #30B8B8;

                          .price-rate {
                          }

                          .rate-type {
                            display: inline-block;
                            color: #9B9B9B;
                            font-size: 12px;
                            font-weight: 400;
                          }
                        }

                        .time-servicefee {
                          border-color: #30B8B8;
                        }
                      }
                    }
                  }
                }
              }

              section {
                .comment {
                  .comment-title {
                    font-size: 32px;
                    color: #4a4a4a;
                    font-weight: 500;
                  }

                  .form-field {
                    .form-field__control {
                      .form-field__label {
                        padding: 15px 12px 0;

                        span {
                          color: #9B9B9B;
                        }
                      }

                      #comment-textarea {
                        padding-left: 20px !important;
                        padding-top: 15px !important;
                      }
                    }
                  }

                  .form-field.form-field--is-filled {
                    .form-field__control {
                      .form-field__label {
                        padding: 15px 12px 0;

                        span {
                          color: #34b9b9;
                        }
                      }

                      #comment-textarea {
                        padding-left: 20px !important;
                        padding-top: 15px !important;
                      }
                    }
                  }

                  .form-field.form-field--is-active, .form-field.form-field--is-filled {
                    .form-field__control {
                      .form-field__label {
                        -webkit-transform: translateY(-22px);
                        transform: translateY(-22px);

                        span {
                          background: white;
                        }
                      }
                    }
                  }

                  .dynamic-fields {
                    bottom: 15px;

                    #dynamic-field-counter {
                      position: relative;
                      left: 94%;
                      top: 20px;
                      z-index: 1;
                      font-size: 0.8rem;
                      margin-bottom: 0;
                      cursor: pointer;

                      #dynamic-field-counter-prev {
                        display: none;
                      }
                    }

                    .hide-dynamic-field {
                      display: none;
                    }

                    .dynamic {
                      input {
                        height: 3rem;
                        font-size: 16px;
                      }

                      .select-field {
                        border: 1px solid #ccc;
                        padding: 10px;
                      }
                    }
                  }

                  .upload-container {
                    margin-top: 2rem;
                    width: 100%;
                    //todo
                    /*
                                        background-image: url("../src/assets/zoofy/images/upload-img-icon.png");
                                        */
                    background-repeat: no-repeat;
                    background-size: 50px 50px;
                    background-position: center;

                    p {
                      text-align: center;
                      padding-bottom: 8rem;
                      font-weight: 500;
                      color: #555
                    }
                  }

                  .detail.thumbnails {
                    width: 85%;
                    margin: 0 auto;
                    margin-bottom: 2rem;
                    display: flex;
                    flex-wrap: wrap;

                    .image-container {
                      width: 27%;
                      margin: 5px;

                      img {
                        width: 80px;
                        height: 80px;
                        object-fit: cover;
                      }
                    }

                    #upload-spinner {
                      position: relative;
                      right: 22%;
                      top: 35px;
                    }
                  }

                  #uploadedLightboxMob {
                    display: none;
                    position: fixed;
                    z-index: 1;
                    padding-top: 100px;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    overflow: auto;
                    background-color: rgba(0, 0, 0, .75);
                  }
                }
              }

              section {
                #personalDetails {
                  display: block;

                  .who {
                    .name {
                      margin-bottom: 0;
                    }

                    .question {
                      color: #4A4A4A;
                      font-size: 32px;
                    }

                    .text-muted.subtitle {
                      font-size: 16px;
                      color: #9b9b9b;
                    }

                    .row {
                      .col-6.input {
                        height: 77px;
                      }

                      .email-confirm {
                        padding-left: 0;
                      }

                      .form-field {
                        .form-field__control {
                          .form-field__label {
                            top: 19px;

                            &:before {
                              content: "";
                            }

                            span {
                              color: #9B9B9B;
                            }
                          }

                          .form-field__input {
                            height: 53px;
                          }
                        }

                        .invalid-feedback {
                          display: none;
                        }
                      }

                      .form-field.form-field--is-filled {
                        .form-field__control {
                          .form-field__label {
                            top: 28px;
                          }
                        }
                      }

                      .form-field.form-field--is-filled.form-field--is-valid {
                        .form-field__control {
                          input {
                            border: 2px solid #34B9B9;
                          }
                        }
                      }

                      .form-field.form-field--is-valid {
                        .form-field__control {
                          .form-field__label {
                            top: 28px;
                          }
                        }
                      }

                      .form-field.form-field--is-filled.form-field--is-invalid {
                        .form-field__control {
                          .form-field__label {
                            top: 28px;
                          }
                        }
                      }

                      .form-field.form-field--is-invalid {
                        .form-field__control {
                          .form-field__label {
                            top: 9px;

                            span {
                              padding: 1px 6px;
                              background: white;
                            }
                          }
                        }
                      }

                      .form-field.form-field--is-active {
                        .form-field__control {
                          .form-field__label {
                            top: 28px;
                          }
                        }
                      }

                      .insurance-checkbox {
                        padding-bottom: 10px;
                        padding-left: 16px;
                        padding-top: 30px;
                        width: 54%;

                        .ins-container {
                          display: flex;
                          align-items: flex-start;
                          width: 88%;

                          label {
                            font-size: 0.8rem;
                            color: #979797;
                            padding-left: 10px;
                          }

                          .insurance-promotion-label {
                            font-size: 12px;
                            color: white;
                            background: #5DB3B5;
                            padding: 1px 5px;
                            border-radius: 3px;
                            margin-left: 5px;
                          }
                        }

                        #insurance {
                          -webkit-appearance: checkbox;
                          position: relative;
                          top: 4px;
                          padding: 0 !important;
                        }

                        #insurance-link {
                          text-decoration: none;

                          .open-container.info-button {
                            background: #ffa500;
                            padding: 10px;
                            width: 88%;
                            color: white;
                            display: flex;
                            justify-content: space-between;
                            box-shadow: 0 1px 3px #ccc;

                            p {
                              color: white;
                              font-size: 14px;
                              margin-bottom: 0;
                            }

                            #insurance-icon {
                              color: white;
                            }
                          }
                        }

                        #insurance-link.collapsed {
                          .open-container.info-button {
                            padding: 10px;
                            box-shadow: 0 1px 1px 0;
                            width: 88%;
                            display: flex;
                            justify-content: space-between;
                            color: #ccc;
                            background: white;

                            p {
                              font-size: 14px;
                              color: #9b9b9b;
                              margin-bottom: 0;
                            }

                            #insurance-icon {
                              padding: 0;
                              font-size: 1.4rem;
                              color: #FFA500;
                            }
                          }
                        }


                        .collapse.show {
                        }

                        #collapseExample {
                          width: 88%;

                          .insurance-info-card {
                            width: 100%;
                            margin-top: 1px;
                            padding: 1.25rem;
                            //todo
                            /*
                                                        background-image: url("../src/assets/zoofy/images/guarantee.png");
                                                        */
                            background-repeat: no-repeat;
                            background-position: center;
                            background-size: 30%;
                            min-height: unset;
                            border: 1px solid #ffa500;
                            border-top: none;
                            border-radius: 0;

                            .ins-title {
                              font-size: 1.2rem;
                              font-weight: 500;
                              color: #979797;
                            }

                            .insurance-price {
                              font-weight: 700;
                              font-size: 1rem;
                              color: #979797;
                            }

                            .ins-item {
                              color: #979797;

                              i {
                                color: #008000;
                              }
                            }
                          }
                        }
                      }

                      #insuranceModal {
                        margin: 0 auto;
                      }
                    }

                    .input-group-append {
                      display: flex;
                      justify-content: center;

                      .submit-button-container {
                        display: flex;
                        justify-content: center;
                        flex-direction: column;

                        .safe-hands {
                          color: #34b9b9;
                          font-size: .8rem;
                          text-align: center;
                          padding-top: 15px;
                          margin-bottom: 5px;
                        }

                        .jf-stepper-submit {
                          background-color: #ffa500 !important;
                          border-color: #ffa500 !important;
                          color: #fff;
                          font-weight: 500;
                          outline: none !important;
                          font-size: 1rem;
                          white-space: normal;
                          border-radius: 3px;
                          padding: 10px 5px;
                          width: 25vw;
                          margin: 0 auto;
                          margin-bottom: 15px;
                        }
                      }
                    }

                    .text-muted.terms {
                      margin: 0 auto;
                      width: 25vw;
                      font-weight: 200;
                      font-size: 16px;
                    }

                    #whatsappModal {
                      margin: 0 auto;
                      position: fixed;

                      .modal-content {
                        margin: 0 1rem;
                        border-radius: .5rem;
                        bottom: 75px;
                        font-family: Helvetica;
                        color: #4A4A4A;

                        .modal-header {
                          padding: 0.5rem;
                          border-color: #34b9b9;
                          margin: 0 1rem;

                          .header-container {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: center;
                            width: 100%;

                            img {
                              width: 30px;
                            }

                            .modal-title {
                              color: #30B8B8;
                              text-align: center;
                              font-size: 1.5rem;
                              font-weight: 300;
                              padding-left: 0.5rem;
                            }
                          }

                          button {
                            outline: none;
                            position: relative;
                            bottom: 4px;
                            left: 15px;
                            background: #9b9b9b;
                            border-radius: 50%;
                            height: 20px;
                            width: 20px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            opacity: 1;
                            color: #fff;
                          }
                        }

                        .modal-body {
                          padding: 0 0.5rem;

                          .body-main {
                            text-align: center;
                            font-weight: 200;
                            padding-top: 1rem;
                            margin-bottom: 0;
                            font-size: 0.8rem;
                          }

                          .body-secondary {
                            text-align: center;
                            font-weight: 200;
                            font-size: .75rem;
                          }

                          .time-checkbox-container {
                            padding-left: 0;
                            left: 3rem;

                            .time-checkbox-label {
                              width: 10%;
                            }

                            .time-detail {
                              position: relative;
                              top: 10px;

                              .time-servicefee {
                                padding: 0;
                                font-size: .6rem;
                                margin-bottom: 0;
                                border: none;
                              }
                            }

                            input:checked ~ .checkmark {
                              background-color: #34b9b9;
                              border-color: #34b9b9;
                              border-radius: 3px;
                            }

                            .checkmark {
                              height: 20px;
                              width: 20px;
                              border-radius: 3px;
                              border: 1px solid #969696;
                            }

                            .checkmark:after {
                              left: 6px;
                              top: 0;
                              border: solid #fff;
                              border-width: 0 3px 3px 0;
                              -webkit-transform: rotate(45deg);
                              -ms-transform: rotate(45deg);
                              transform: rotate(45deg);
                            }
                          }
                        }

                        .modal-footer {
                          border: none;

                          #whatsapp-opt-in-button {
                            text-align: center;
                            width: 90%;
                            background: #FEA500;
                            color: white;
                            margin: 0 auto;
                            padding: 0.5rem;
                            border-radius: 0.2rem;
                            font-size: 1.2rem;
                            cursor: pointer;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            #selectMoreDatesModal {
              .modal-content {
                margin: 0 1rem;
                border-radius: .5rem;
                bottom: 75px;
                font-family: Helvetica;
                color: #4A4A4A;

                .modal-header {
                  padding: 0.5rem;
                  border-color: #34b9b9;
                  margin: 0 1rem;

                  .header-container {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    width: 100%;

                    img {
                      width: 30px;
                    }

                    .modal-title {
                      color: #30B8B8;
                      text-align: center;
                      font-size: 1.5rem;
                      font-weight: 300;
                      padding-left: 0.5rem;
                    }
                  }

                  button {
                    outline: none;
                    position: relative;
                    bottom: 4px;
                    left: 15px;
                    background: #9b9b9b;
                    border-radius: 50%;
                    height: 20px;
                    width: 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    opacity: 1;
                    color: #fff;
                  }
                }

                .modal-body {
                  padding: 0 0.5rem;

                  .body-main {
                    text-align: center;
                    font-weight: 200;
                    padding-top: 1rem;
                    margin-bottom: 0;
                    font-size: 0.9rem;
                  }

                  .body-secondary {
                    text-align: center;
                    font-weight: 200;
                    font-size: 0.8rem;
                  }
                }

                .modal-footer {
                  border: none;
                  display: flex;
                  flex-direction: column;
                  align-items: center;

                  #select-more-dates-button {
                    text-align: center;
                    width: 90%;
                    background: #FEA500;
                    color: white;
                    margin: 0 auto;
                    padding: 0.5rem;
                    border-radius: 0.2rem;
                    font-size: 1.2rem;
                    cursor: pointer;
                  }

                  .select-more-dates-close-button {
                    margin-top: 1rem;
                    margin-bottom: 0;
                    font-size: 0.7rem;
                    text-decoration: underline;
                    cursor: pointer;
                  }
                }
              }
            }
          }
        }
      }
    }

    section.slider-calendar {
      background: white;

      #jobFormMobile {
        background: white;

        .number-input {
          background: white;
        }

        .form-field {
          margin-top: 0;

          .form-field__label {
            top: 24px;
            padding: 10px 12px 0;
          }

          .form-field__input {
            font-size: 15px;
            border: 1px solid #ccc;
            height: 44px;
            padding: 0 !important;
            padding-left: 20px !important;
          }

          .form-field__textarea.comment {
            font-size: 15px;
            border: 1px solid #ccc;
            height: 44px;
            padding: 0 !important;
            padding-left: 20px !important;
          }
        }

        .form-field.form-field--is-active, .form-field.form-field--is-filled {
          .form-field__label {
            span {
              /*width: calc(25% + 30px);*/
              display: inline-block;
              background: white;
              transform: translate(0px, -4px);
              padding: 0 8px;
            }
          }

          .form-field__input {
            /*border: 1px solid #ccc;
                        height: 44px;*/
            border-color: #34b9b9;
          }

          .form-field__input.is-invalid {
            border-color: crimson;
          }

          .form-field__textarea.comment {
            border-color: #34b9b9;
            padding-top: 20px !important;
          }
        }

        #mobile-calendar, #rebookCalendar {
          .zabuto_calendar {
            .calendar-month-header {
              td {
                background-color: white;
              }
            }

            .calendar-dow-header {
              th {
                background-color: white;
                color: #D8D8D8;
                font-weight: 400;
              }
            }

            .calendar-dow {
              td {
                background-color: white;
                color: #4a4a4a;

                .day {
                  .badge.badge-event {
                    background: #34b9b9;
                    color: white !important;
                    padding-top: 4px;
                    border-radius: 50%;
                    text-align: center;
                    height: 26px;
                    width: 26px;
                    top: 0;
                    font-weight: 400;

                    &:after {
                      content: "";
                    }
                  }
                }
              }
            }
          }
        }
      }

      #dual-branding-container-mobile {
        margin: 0 auto;
      }
    }

    #jobFormMobile {

      .job {
        h3 {
          font-weight: bold;
          font-size: 1.64rem;
        }
      }

      .jobform-stepper {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 0.9rem;

        .title {
          color: #777;
        }

        .stepper-container {
          width: 100%;
          display: flex;
          justify-content: center;

          .stepper-back-button {
            visibility: hidden;
            padding-top: 4px;
            position: relative;
            right: 63px;
            font-size: 1.1rem;
            color: #c5c5c5;
          }

          svg {
            padding: 0 2px;
          }

          span {
            border-radius: 50%;
            border: 1px solid black;
            width: 23px;
            height: 23px;
            text-align: center;
            /*padding: 0 7px;*/
            .fa-check {
              position: relative;
              top: 1px;
            }
          }

          #stepper1 {
            color: #34B9B9;
            border-color: #34B9B9;
            background: white;
          }

          #stepper2, #stepper3, #stepper4 {
            color: white;
            border-color: #D8D8D8;
            background: #D8D8D8;
          }
        }
      }

      .mobJobForm.taskInfo {
        font-size: 1rem;

        span {
          font-weight: normal;
        }

        span.stars {
          display: inline-block;
          font-size: 16px;
          position: relative;
          overflow: hidden;
          vertical-align: middle;
          z-index: 0;

          .stars-wrap {
            .stars-active {
              color: #eebd01;
              position: relative;
              z-index: 10;
              display: inline-block;
              overflow: hidden;
              white-space: nowrap;
            }

            .stars-inactive {
              color: #ccc;
              position: absolute;
              top: 0;
              left: 0;
            }
          }
        }

        #from-price {
        }

        .based-on {
          color: #999;
          font-size: 0.9rem;
          margin: 0 0 1rem 0;
        }
      }


      #wizard {
        width: 100vw;

        .content.clearfix {
          background: white;
          width: 84%;

          #wizard-p-1 {
            width: 100vw;
          }
        }

        .job {
          padding-bottom: 20px;

          h5 {
            color: #4A4A4A;
            font-weight: 500;
          }

          .mobJobForm.taskInfo {
            span:nth-child(2), span:nth-child(3), span:nth-child(4) {
              color: #9B9B9B;
            }
          }
        }

        section.address-validation {
          .label-subtitle {
            margin-bottom: 0.5rem;
          }

          .validation-message {
            padding-top: 0.5rem !important;

            #location-check-icon {
              color: #34b9b9;
            }

            #validate-address {
              color: #34b9b9;
              font-weight: 100;
              font-size: .8rem;
            }

            #invalid-address {
              color: crimson;
              font-weight: 100;
              font-size: .8rem;
            }
          }

          .jobform-partners {
            width: 65%;
            margin: 0 auto;
            margin-top: 2.5rem;

            .title {
              font-size: .63rem;
              margin: 0;
            }

            .partner-images {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;

              img {
                filter: grayscale(100%);
                padding: 0 1px;
                width: 20%;
                height: 20%;
              }

              #gamma {
                width: 18%;
              }

              #hornbach {
                width: 36%;
              }

              #praxis {
                width: 21%;
              }

              #bol {
                width: 28%;
              }
            }

            .subtitle {
              font-size: 0.7rem;
              color: #9FBFC1;
              text-align: center;
            }
          }
        }

        .when {
          .slider-title {
            font-weight: 500;
            color: #4a4a4a;
          }

          .scroll-container {
            display: flex;
          }

          .options-checkboxes {
            display: none;

            .time-checkbox-container {
              .checkmark {
                border-radius: 2px;
              }
            }
          }

          #spoed-option-alert {
            width: 84%;
            background: none;
            border: none;

            .time-checkbox-container {
              .time-checkbox-label {
                width: 37%;

                .time-section {
                  position: relative;
                  top: 2px;
                }
              }
            }

            .spoed-message {
              color: #9b9b9b;
              font-size: 0.8rem;
              padding-top: 1rem;
              font-weight: 300;
            }
          }

          .calendar-container {
            display: flex;
            flex-direction: row;
            margin: 15px;
            padding-top: 10px;
            width: 75%;

            p {
              font-size: 0.9rem;
              color: #777;
            }

            img {
              height: 25px;
            }

            i {
              font-size: 1.5rem;
              color: #777;
            }
          }

          .bg {
            padding-top: 5px;
            width: 84%;

            #mobile-calendar {
              .zabuto_calendar {
                #calendar-next, #calendar-prev {
                  color: #555;
                }

                #calendar-prev {
                  position: relative;
                  left: -12.5rem;
                }

                .calendar-header {
                  span {
                    color: #555;
                    position: relative;
                    left: 44px;
                  }
                }

                .table tr:last-child {
                  border-bottom: none;
                  height: unset;
                }

                .table {
                  tr:first-child {
                    background: none;
                  }

                  tr {
                    &:hover {
                      background: none;
                    }

                    td {
                      div.day {
                        margin: 5px;
                        padding-top: 1px;
                        border-radius: 50%;
                        height: 26px;
                        width: 26px;
                        font-variant-numeric: proportional-nums;
                        font-size: 14px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 0;
                      }

                      div.day.activeDay {
                        --box-shadow-color: #666;
                        box-shadow: 0 1px 3px 0 var(--box-shadow-color);
                      }

                      div.day.withinRange, div.day.withinRangeConfirmed {
                        /*&.afterStartRange {
                                                    border-radius: 0;
                                                    --box-shadow-color: #34b9b8;
                                                    box-shadow: -20px 0 0 -1.5px var(--box-shadow-color);
                                                    position: relative;
                                                    top: 0.5px;
                                                }

                                                &.beforeEndRange {
                                                    border-radius: 0;
                                                    --box-shadow-color: #34b9b8;
                                                    box-shadow: 25px 0.5px 0 0 var(--box-shadow-color);
                                                }*/
                      }

                      &.startRange, &.startRangeConfirmed {
                        background-image: linear-gradient(white, white 22%, #34b9b8 7%, #34b9b8 79%, white 79%);
                        border-top-left-radius: 100vw;
                        border-bottom-left-radius: 100vw;

                        div.day {
                          color: white;
                          padding-top: 0;
                          /*--box-shadow-color: #666;
                                                    box-shadow: 0 1px 3px 0 var(--box-shadow-color);*/
                          span {
                            --box-shadow-color: #666;
                            box-shadow: 0 1px 3px 0 var(--box-shadow-color);
                          }
                        }
                      }

                      &.endRange, &.endRangeConfirmed {
                        background-image: linear-gradient(white, white 22%, #34b9b8 7%, #34b9b8 79%, white 79%);
                        border-top-right-radius: 100vw;
                        border-bottom-right-radius: 100vw;

                        div.day {
                          color: white;
                          padding-top: 0;
                          /*--box-shadow-color: #666;
                                                    box-shadow: 0 1px 3px 0 var(--box-shadow-color);*/
                          span {
                            --box-shadow-color: #666;
                            box-shadow: 0 1px 3px 0 var(--box-shadow-color);
                          }
                        }
                      }

                      &.withinRange, &.withinRangeConfirmed {
                        background-image: linear-gradient(white, white 22%, #34b9b8 7%, #34b9b8 79%, white 79%);

                        div.day {
                          color: white;
                        }
                      }
                    }
                  }

                  .badge-event::after {
                    bottom: 16px;
                  }
                }
              }
            }
          }

          .time-checkbox-container {
            .time-checkbox-label {
              width: 32%;

              input:checked ~ .checkmark {
                background-color: #34b9b9;
                border-color: #34b9b9;
              }
            }

            .checkmark:after {
              border: solid #fff;
              border-width: 0 3px 3px 0;
              -webkit-transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              transform: rotate(45deg);
            }
          }

          .time-details {
            .time-price {
              .price-rate {
                color: #4a4a4a;
              }

              .rate-type {
                display: inline-block;
                color: #9B9B9B;
                font-size: 12px;
                font-weight: 400;
              }

              border-left: 2px solid #008f9d;
            }

            .time-servicefee {
              border-left: 2px solid #008f9d;
              color: #9B9B9B;
            }
          }
        }

        #selectMoreDatesModal {
          .modal-content {
            margin: 0 1rem;
            border-radius: .5rem;
            bottom: 75px;
            font-family: Helvetica;
            color: #4A4A4A;

            .modal-header {
              padding: 0.5rem;
              border-color: #34b9b9;
              margin: 0 1rem;

              .header-container {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                width: 100%;

                img {
                  width: 30px;
                }

                .modal-title {
                  color: #30B8B8;
                  text-align: center;
                  font-size: 1.5rem;
                  font-weight: 300;
                  padding-left: 0.5rem;
                }
              }

              button {
                outline: none;
                position: relative;
                bottom: 4px;
                left: 15px;
                background: #9b9b9b;
                border-radius: 50%;
                height: 20px;
                width: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                opacity: 1;
                color: #fff;
              }
            }

            .modal-body {
              padding: 0 0.5rem;

              .body-main {
                text-align: center;
                font-weight: 200;
                padding-top: 1rem;
                margin-bottom: 0;
                font-size: 0.9rem;
              }

              .body-secondary {
                text-align: center;
                font-weight: 200;
                font-size: 0.8rem;
              }
            }

            .modal-footer {
              border: none;
              display: flex;
              flex-direction: column;
              align-items: center;

              #select-more-dates-button {
                text-align: center;
                width: 90%;
                background: #FEA500;
                color: white;
                margin: 0 auto;
                padding: 0.5rem;
                border-radius: 0.2rem;
                font-size: 1.2rem;
              }

              .select-more-dates-close-button {
                margin-top: 1rem;
                margin-bottom: 0;
                font-size: 0.7rem;
                text-decoration: underline;
              }
            }
          }
        }

        #currentChosenDate {
          display: none;
        }

        .comment {
          .form-field.form-field--is-filled {
            .form-field__label {
              color: #34b9b9;
            }
          }

          .form-field__label {
            color: #a4a4a4;
          }


          .form-check-label {
            font-weight: 500;
            font-size: 18px;
            color: #555;
            position: relative;
            top: 10px;
            padding-bottom: 0;
            z-index: 1;
          }

          .upload-container {
            margin-top: 2rem;
            width: 100%;
            //todo
            /*
                        background-image: url("../src/assets/zoofy/images/upload-img-icon.png");
                        */
            background-repeat: no-repeat;
            background-size: 50px 50px;
            background-position: center;

            p {
              text-align: center;
              padding-bottom: 8rem;
              font-weight: 500;
              color: #555
            }
          }

          .detail.thumbnails {
            width: 85%;
            margin: 0 auto;
            margin-bottom: 2rem;
            display: flex;
            flex-wrap: wrap;

            .image-container {
              width: 27%;
              margin: 5px;

              img {
                width: 80px;
                height: 80px;
                object-fit: cover;
              }
            }

            #upload-spinner {
              position: relative;
              right: 55px;
              top: 35px;
            }
          }

          #uploadedLightboxMob {
            display: none;
            position: fixed;
            z-index: 1;
            padding-top: 100px;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            overflow: auto;
            background-color: rgba(0, 0, 0, .75);
          }

          #comment-placeholder {
            font-size: 0.8rem;
            font-weight: 200;
          }

          #comment-textarea {
            height: 200px;
          }

          .dynamic-fields {
            bottom: 15px;

            #dynamic-field-counter {
              position: relative;
              left: 83%;
              top: 20px;
              z-index: 1;
              font-size: 0.8rem;
              margin-bottom: 0;

              #dynamic-field-counter-prev {
                display: none;
              }
            }

            .hide-dynamic-field {
              display: none;
            }

            .dynamic {
              .select-field {
                border: 1px solid #ccc;
                padding: 10px;
              }
            }
          }
        }

        .text-muted.description {
          font-size: 0.9rem;
          padding: 2rem 0;

          .substring {
            font-weight: 500;
            color: #34b9b9;
            cursor: pointer;
          }
        }

        .label-title {
          font-size: 1.1rem;
          font-weight: 500;
          margin: 0;
          margin-top: 1rem;
          color: #4A4A4A;
        }

        .label-subtitle {
          color: #777;
          font-size: 0.96rem;
        }

        #customer-options {

          .option-title {
            color: #888;
            padding: 0 0.2rem;
            padding-top: 1rem;
          }

          #slots {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;

            .overview-option {
              background: #f2f2f2;
              padding: .5rem .5rem;
              margin: 0.2rem 0;
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 49%;
              border-radius: 4px;
              border: 1px solid #34b9b9;

              i {
                font-size: 1.3rem;
                color: #34B9B9;
              }

              .date-time {
                text-transform: capitalize;
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                .date {
                  font-size: 0.8rem;
                }

                .time {
                  font-size: 0.6rem;
                  color: #888;
                }
              }

              .rate {
                font-size: 1rem;
                font-weight: 700;
              }
            }
          }
        }

        .who {
          margin: 0 20px;
          padding-top: 1rem;

          .subtitle {
            font-size: .9rem;
            margin-bottom: 0;
          }

          .row {
            .row.dynamic {
              width: 100%;
              margin: 0 auto;
              margin-bottom: 0;
            }
          }

          .firstname {
            z-index: 1;
          }

          .lastname {
            position: relative;
            top: -10px;
          }

          .email {
            z-index: 2;
          }

          .email-confirm {
            z-index: 1;
            position: relative;
            top: -10px;
          }

          .telephone {
            position: relative;
            top: -20px;
          }

          .form-field.form-field--is-filled {
            .form-field__label {
              color: #34b9b9;
            }
          }

          .form-field__label {
            color: #a4a4a4;
            left: 10px;
          }

          .form-field.form-field--is-filled {
            .form-field__input.is-invalid {
              border-color: #dc143c;
            }

            .form-field__input.is-valid {
              border-color: #34b9b9;
            }
          }

          .mobile-checkbox {
            padding-bottom: 10px;

            .ins-container {
              display: flex;
              align-items: flex-start;

              label {
                font-size: 0.8rem;
                color: #979797;
                padding-left: 10px;
              }
            }

            #insurance {
              -webkit-appearance: checkbox;
              position: relative;
              top: 4px;
              padding: 0 !important;
            }

            .open-container.info-button {
              padding: 10px;
              box-shadow: 0 1px 1px 0;

              #insurance-icon {
                padding: 0;
                font-size: 1.4rem;
              }
            }
          }

          .submit-button-container {
            width: 100%;

            .safe-hands {
              color: #34B9B9;
              font-size: 0.8rem;
              text-align: center;
              padding-top: 15px;
            }

            .jf-stepper-submit {
              border-radius: 3px;
              padding: 5px;
              width: 100%;
              margin: 0 auto;
              margin-bottom: 15px;
              white-space: normal;
              font-size: 1.3rem;
            }
          }

          .text-muted.terms {
            font-size: 0.8rem;
            font-weight: 200;
          }

          #whatsappModal {
            position: fixed;

            .modal-content {
              margin: 0 1rem;
              border-radius: .5rem;
              bottom: 75px;
              font-family: Helvetica, sans-serif;
              color: #4A4A4A;

              .modal-header {
                padding: 0.5rem;
                border-color: #34b9b9;
                margin: 0 1rem;

                .header-container {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  justify-content: center;
                  width: 100%;

                  img {
                    width: 30px;
                  }

                  .modal-title {
                    color: #30B8B8;
                    text-align: center;
                    font-size: 1.5rem;
                    font-weight: 300;
                    padding-left: 0.5rem;
                  }
                }

                button {
                  outline: none;
                  position: relative;
                  bottom: 4px;
                  left: 15px;
                  background: #9b9b9b;
                  border-radius: 50%;
                  height: 20px;
                  width: 20px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  opacity: 1;
                  color: #fff;
                }
              }

              .modal-body {
                padding: 0 0.5rem;

                .body-main {
                  text-align: center;
                  font-weight: 200;
                  padding-top: 1rem;
                  margin-bottom: 0;
                  font-size: 0.8rem;
                }

                .body-secondary {
                  text-align: center;
                  font-weight: 200;
                  font-size: .75rem;
                }

                .time-checkbox-container {
                  padding-left: 0;
                  left: 3rem;

                  .time-checkbox-label {
                    width: 10%;
                  }

                  .time-detail {
                    position: relative;
                    top: 10px;

                    .time-servicefee {
                      padding: 0;
                      font-size: .6rem;
                      margin-bottom: 0;
                      border: none;
                    }
                  }

                  input:checked ~ .checkmark {
                    background-color: #34b9b9;
                    border-color: #34b9b9;
                    border-radius: 3px;
                  }

                  .checkmark {
                    height: 20px;
                    width: 20px;
                    border-radius: 3px;
                    border: 1px solid #969696;
                  }

                  .checkmark:after {
                    left: 6px;
                    top: 0;
                    border: solid #fff;
                    border-width: 0 3px 3px 0;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                  }
                }
              }

              .modal-footer {
                border: none;

                #whatsapp-opt-in-button {
                  text-align: center;
                  width: 90%;
                  background: #FEA500;
                  color: white;
                  margin: 0 auto;
                  padding: 0.5rem;
                  border-radius: 0.2rem;
                  font-size: 1.2rem;
                }
              }
            }
          }
        }
      }

      .jf-stepper-next {
        border-radius: 3px;
        padding: 5px;
        width: 90%;
        margin: 0 auto;
        font-weight: 400;
        font-size: 1.4rem;
      }

      .jf-stepper-next:disabled, .jf-stepper-submit:disabled {
        background: #d8d8d8 !important;
        border: 1px solid #d8d8d8 !important;
      }

      .btm-details-msg {
        font-size: .7rem;
        color: #999;
        text-align: center;
        margin-bottom: 1.5rem;
        padding-top: 15px;
        font-weight: 300;
      }
    }

    .insurance-container {
      .insurance-info-button {
        position: relative;
        right: 0;
        bottom: 8px;
        outline: none;

        img {
          height: 15px;
          width: 15px;
          cursor: pointer;
        }
      }
    }

    .desktop-checkbox {
      width: 100%;

      a {
        text-decoration: none;
      }

      .insurance-promotion-label {
        background: #34B9B9;
        color: white;
        font-size: 0.7rem;
        padding: 2px 4px;
        border-radius: 3px;
        margin-left: 7px;
      }

      .open-container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
          margin: 0;
        }

        i {
          padding-right: 15px;
          color: #FFA500;
        }
      }

      .info-button {
        background: #fff;
        color: #444;
        margin: 0;
        padding: 1rem;
        box-shadow: 1px 2px 5px rgba(0, 0, 0, .1);
      }

      .open-container.info-button.open {
        background: #FFA500;
        color: white;

        i {
          color: white;
        }
      }

      .insurance-info-card.open {
        border: 1px solid #FFA500;
      }

      .insurance-info-card {
        //todo
        /*     background-image: url("../src/assets/zoofy/images/guarantee.png");*/
        background-repeat: no-repeat;
        background-position: center;
        background-size: 30%;
        border-radius: 0 0 0 0 !important;
        box-shadow: 1px 1px 5px rgba(0, 0, 0, .1);

        .ins-title {
          font-size: 1.2rem;
          font-weight: 500;
          color: #979797;
        }

        .insurance-price {
          font-weight: 700;
          font-size: 1rem;
          color: #979797;
        }

        p {
          font-size: 0.9rem;
        }

        .ins-item {
          color: #979797;

          i {
            color: green;
          }
        }
      }
    }

    .mobile-checkbox {
      width: 95%;
      padding-top: 10px;

      a {
        text-decoration: none;
      }

      .insurance-promotion-label {
        background: #34B9B9;
        color: white;
        font-size: 0.7rem;
        padding: 3px 6px;
        border-radius: 3px;
        margin-left: 7px;
      }

      .open-container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
          margin: 0;
        }

        i {
          padding-right: 15px;
          color: #FFA500;
        }
      }

      .info-button {
        background: #fff;
        color: #444;
        margin: 0;
        padding: 1.25rem;

        p {
          font-size: 0.9rem;
          color: #979797;
        }
      }

      .open-container.info-button.open {
        background: #FFA500;
        color: white;

        i {
          color: white;
        }
      }

      .insurance-info-card.open {
        border: 1px solid #FFA500;
      }

      .insurance-info-card {
        //todo
        /*
                background-image: url("../src/assets/zoofy/images/guarantee.png");
                */
        background-repeat: no-repeat;
        background-position: center;
        background-size: 45%;
        border-radius: 0 0 0 0 !important;
        box-shadow: 1px 1px 5px rgba(0, 0, 0, .1);

        .ins-title {
          font-size: 1.2rem;
          font-weight: 500;
          color: #979797;
        }

        .insurance-price {
          font-weight: 700;
          font-size: 1rem;
          color: #979797;
        }

        p {
          font-size: 0.9rem;
        }

        .ins-item {
          color: #979797;

          i {
            color: green;
          }
        }
      }
    }
  }


  &.notfound {
    background: $bgcolor-primary;
    font-family: Roboto, sans-serif;;
    font-size: 18px;

    a {
      color: $color-almost-black;
      text-decoration: none;

      &:hover {
        color: $color-almost-black;
        text-decoration: none;
      }
    }

    h2 {
      font-size: 26px;
      color: $color-555;
    }

    p {
      color: $color-333;
      margin-top: 2rem;

      &.lead {
        font-weight: bold;
      }
    }

    .brand h1 {
      font-size: 50px;
      color: $color-almost-black;
      font-family: Roboto, sans-serif;
      font-weight: bold;
      margin: 0;
    }

    ul li a {
      color: $color_53;
    }
  }

  &.blog {
    .card-columns {
      display: flex;
      flex-wrap: wrap;
    }

    .col-article {
      padding-left: 0;
      display: inline-flex;
      height: auto;
      margin-bottom: 1rem;
    }

    .article {
      background: $color-white;
      padding: 1.5rem;
      height: auto;

      .date {
        font-size: .85rem;
      }

      h4 {
        margin-bottom: 0;
        font-size: 1.25rem;
      }

      img {
        margin-right: 1.5rem;
        width: 100% !important;
        max-height: 100% !important;
        height: auto !important;
      }

      .tags .badge {
        margin-bottom: .75rem;
        margin-right: .25rem;
        background: $color-secondary;
      }

      .lead p {
        margin-bottom: 0;
        font-size: .9rem;
      }

      .blog-title {
        font-size: 1.25rem;
        font-weight: 500;
      }
    }

    .sidebar {
      background: $color-white;
      padding: 1.5rem;

      .sidebar-title {
        font-weight: bold;
      }

      ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          padding: 1rem 0;

          a {
            font-size: .9rem;
          }
        }
      }

      h1 {
        margin-bottom: 1rem;
        font-size: 1.5rem;
      }

      p.recent {
        text-transform: uppercase;
        font-size: 13px;
        font-weight: bold;
        margin-bottom: 0;
      }
    }

    &.article section.content .article {
      background: $color-white;
      padding: 4rem;
      margin-bottom: 3rem;
    }

    .page-item .page-link {
      color: #34b9b9;
    }

    .page-item.active .page-link {
      border: 1px solid #34b9b9;
      background-color: #34b9b9;
      color: #fff;
    }
  }

  section.rebookNew {
    background: #F9F9F9;

    .container {
      display: flex;
      margin-top: 3rem;

      .main-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0 1rem;
        width: 70%;

        .job-info {
          background: white;
          padding: 1.5rem 1.5rem;
          border-radius: 10px;

          .heading {
            display: flex;
            flex-direction: row;
            margin-bottom: 3rem;

            .map {
              height: 120px;
              border-radius: 50%;
            }

            .info-container {
              display: flex;
              flex-direction: column;
              padding-left: 1.5rem;

              .job-title {
                color: #323C47;
                font-weight: 500;
                font-size: 1.5rem;
              }

              .job-address {
                color: #999;
                font-size: 1.2rem;
                margin-bottom: 1rem;
              }

              .status {
                color: #777;
              }
            }
          }

          .choose-row {
            padding-bottom: 2rem;
          }

          .card-container {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;

            .card {
              width: 47%;
              margin: 0 5px 5px 0;
              border-radius: 8px;
              transition: background-color 0.3s, color 0.3s;
              cursor: pointer;
              margin-bottom: 1rem;
              border: 1px solid $color-secondary;
              box-shadow: none;

              &:hover, &.selected {
                background-color: $color-secondary;
                transition: background-color 0.3s, color 0.3s;

                .card-title {
                  color: $color-white !important;
                }

                .card-date {
                  color: $color-white !important;
                }

                .card-time {
                  color: $color-white !important;
                }

                .card-price {
                  color: $color-white !important;
                }

                .card-service-fee {
                  color: $color-white !important;
                }

                .card-title {
                  &:before {
                    content: "\f058" !important;
                    color: #323C47 !important;
                  }
                }
              }

              .card-body {
                display: flex;

                .card-left {
                  width: 60%;
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;

                  .card-title {
                    margin-bottom: 1rem;
                    color: $color-secondary;

                    &:before {
                      content: "\f111";
                      font-family: "Font Awesome 5 Free", sans-serif;
                      color: #b7b7b7;
                      background: #E2E2E2;
                      border-radius: 50%;
                      font-size: 1rem;
                      margin-right: 0.5rem;
                    }
                  }

                  .card-date {
                    color: black;
                    text-transform: capitalize;
                    margin-bottom: 0;
                    font-weight: 700;
                    font-size: .9rem;
                  }

                  .card-time {
                    font-size: .7rem;
                    margin-bottom: 0;
                    color: black;
                  }
                }

                .card-right {
                  width: 40%;
                  display: flex;
                  flex-direction: column;
                  justify-content: flex-end;

                  .card-price {
                    color: black;
                    text-align: right;
                    margin-bottom: 0;
                    font-size: 0.8rem;
                  }

                  .card-service-fee {
                    font-size: .7rem;
                    text-align: right;
                    margin-bottom: 0;
                    color: #ACACAC;
                  }
                }

                .price-bold {
                  font-weight: 700;
                  font-size: 1.3rem;
                }
              }
            }

            .calendar-card-container {
              width: 48%;
            }

            .calendar-card {
              width: 100%;

              .card-title {
                color: $color-secondary;
                font-size: 1rem;
                margin-bottom: 0.3rem !important;
              }

              .card-time {
                font-size: 0.6rem !important;
                color: #acacac;
              }
            }
          }
        }
      }

      .side-content {
        padding: 1.5rem 1.5rem;

        .title {
          font-size: 1.5rem;
          color: #323C47;
          margin-bottom: 1.5rem;
          font-weight: 500;
        }

        .chosen-options {

          .option {
            margin-bottom: 2rem;

            .date {
              font-size: 0.8rem;
              text-transform: uppercase;
              font-weight: 500;
              color: #999;
              margin-bottom: 0.5rem;
            }

            .time {
              font-size: 1rem;
              color: #323C47;
              margin-bottom: 0.5rem;
            }
          }
        }

        .rebook-button {
          color: white;
          background: $color-secondary;
          padding: .5rem 2rem;
          text-align: center;
          border-radius: 50px;
          text-transform: uppercase;
          font-weight: 500;
          cursor: pointer;

          &:hover {
            background: #2a9c9c;
          }
        }
      }
    }
  }

  section.rebook {
    padding: 3rem 0 5rem 0 !important;
    background: #f9f9f9;

    .page-title {
      padding-bottom: 1rem;
    }

    .page-subtitle {
      padding-bottom: 2rem;
    }

    .choose-row {
      padding-bottom: 2rem;
    }

    .card-container {
      display: flex;
      flex-wrap: wrap;

      .card {
        width: 47%;
        margin: 0 5px 5px 0;
        border-radius: 8px;
        transition: background-color 0.3s, color 0.3s;
        cursor: pointer;

        &:hover, &.selected {
          background-color: $color-secondary;
          transition: background-color 0.3s, color 0.3s;

          .card-title {
            color: $color-white !important;
          }

          .card-date {
            color: $color-white !important;
          }

          .card-time {
            color: $color-white !important;
          }

          .card-price {
            color: $color-white !important;
          }

          .card-service-fee {
            color: $color-white !important;
          }
        }

        .card-body {
          display: flex;

          .card-left {
            width: 60%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .card-title {
              margin-bottom: 0.6rem;
            }

            .card-date {
              color: $color-secondary;
              margin-bottom: 0;
              font-weight: 700;
              font-size: .9rem;
            }

            .card-time {
              font-size: .7rem;
              margin-bottom: 0;
            }
          }

          .card-right {
            width: 40%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .card-price {
              color: $color-secondary;
              text-align: right;
              margin-bottom: 0;
              font-size: 0.8rem;
            }

            .card-service-fee {
              font-size: .7rem;
              text-align: right;
              margin-bottom: 0;
              color: #ACACAC;
            }
          }

          .price-bold {
            font-weight: 700;
            font-size: 1.3rem;
          }
        }
      }

      .calendar-card {
        width: 100%;

        .card-title {
          color: $color-secondary;
          font-size: 1rem;
          margin-bottom: 0.3rem !important;
        }

        .card-time {
          font-size: 0.6rem !important;
          color: #acacac;
        }
      }
    }

    .type-selector-card {
      background: #fff;
      cursor: pointer;
      box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1);
      padding: 1rem;
      margin: 10px 0;
      border-radius: 8px;
      align-items: center;
      transition: 0.3s;
      position: relative;
      min-height: 6rem;
      z-index: 10;

      .type-selected-title {
        color: #34B9B9;
        font-weight: bold;
      }

      .type-selected-title i {
        margin-right: .25rem;
      }

      .type-selected-subtitle {
        color: #999;
        font-size: 14px;
      }
    }

    .type-selected-details {
      width: auto;
      padding: 1rem 0.9rem .35rem 0.9rem;
      background: #34b9b9;
      color: #fff;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      margin-top: -15px;
    }

    .type-selected-price {
      display: inline-block;
      font-size: 1.5rem;
      font-weight: bold;
      letter-spacing: -1px;
      color: black;
      line-height: 1rem;

      span.from {
        display: inline-block;
        font-size: 13px;
        line-height: 1.5rem;
        margin-top: -10px;
      }
    }

    .service-fee {
      display: inline-block;
      font-size: 12px;
    }

    .type-selected-servicefee {
      display: inline-block;
      font-weight: normal;
      color: #fff;
      margin-top: .25rem;
    }

    .selected {

      .type-selector-card {
        background: #34b9b9;
        color: #fff;
        box-shadow: none;
      }

      .type-selected-title,
      .type-selected-subtitle,
      .type-selected-price,
      .type-selected-servicefee,
      .service-fee {
        color: #fff;
      }
    }

    .type-selector-card:hover {
      background: #34b9b9;
      color: #fff;
      box-shadow: none;
    }

    .type-selector-card:hover .type-selected-title,
    .type-selector-card:hover .type-selected-subtitle {
      color: #fff;
    }

    .time-checkbox-container {
      display: flex;
      position: relative;
      padding-left: 35px;
      margin-bottom: 12px;
      cursor: pointer;
      font-size: 1.2rem;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    .time-details .rate-type {
      /*display: none;*/
    }

    /* Hide the browser's default checkbox */
    .time-checkbox-container input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    /* Create a custom checkbox */
    .checkmark {
      position: absolute;
      top: 5px;
      left: 0;
      height: 25px;
      width: 25px;
      background-color: #fff;
      border: 1px solid #ccc;
    }

    /* On mouse-over, add a grey background color */
    .time-checkbox-container:hover input ~ .checkmark {
      background-color: #fafafa;
    }

    /* When the checkbox is checked, add a blue background */
    .time-checkbox-container input:checked ~ .checkmark {
      background-color: #fff;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    /* Show the checkmark when checked */
    .time-checkbox-container input:checked ~ .checkmark:after {
      display: block;
    }

    /* Style the checkmark/indicator */
    .time-checkbox-container .checkmark:after {
      left: 9px;
      top: 3px;
      width: 7px;
      height: 14px;
      border: solid #34B9B9;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    .modal {
      .modal-content {
        .modal-body {
          border-radius: 0.3rem;
          padding: 0;

          button.close {
            position: absolute;
            right: 25px;
            top: 20px;
            z-index: 10;
          }

          .bg {
            background: #fafafa;
            padding: 2rem;
            border-radius: 0.3rem;
          }

          .options {
            padding: 2rem;
            border-radius: 0.3rem;
          }

          .time-option-container {
            h3 {
              margin-bottom: 1.5rem;
            }
          }
        }
      }
    }

    .checkbox-confirm {
      height: 25px;
      width: 25px;
      left: 3rem;
      margin-top: 0.5rem;
    }

    .checkbox-label {
      position: absolute;
      left: 4rem;
      margin-top: 0.5rem;
    }

    .button-row {
      padding-top: 3rem;
      padding-left: .5rem;

      .go-back {
        color: $color-secondary;
        font-weight: 700;
      }
    }

    #rebook-error {
      display: none;
      margin-top: 1rem;
      margin-left: 1rem;
    }
  }

  .zoofy-menu-items {
    margin-left: auto !important;
    margin-right: 1rem !important;
  }

  #menu-item-container {
    margin-left: auto !important;
    margin-right: 1rem !important;
  }

  &.document {
    .aanmeld-text {
      font-size: 0.8rem;
    }
  }

  section.garantie {
    padding-bottom: 0;

    .section1 {

      .row {
        flex-direction: column;
      }

      h1 {
        font-size: 3rem;
        width: 40%;
      }

      p {
        width: 40%;
        font-size: 1.1rem;
      }

      .garantieCTA {
        width: 20%;
        background: #F3A83B;
        color: white;
        border-color: #F3A83B;
      }
    }

    .divider1 {
      position: relative;
      top: 7rem;

      .fakeButton {
        background: #75BEC1;
        width: 28vw;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 2px;
        padding: 2.5rem 10px;
        text-align: center;

        p {
          margin: 0;
          font-weight: 500;
          font-size: 2rem;
          color: white;
        }
      }
    }

    .section2 {
      background: white;
      padding: 10rem 0 0 0;
      max-width: 100%;

      .row {
        flex-direction: row;
        justify-content: space-between;
        width: 60%;
        margin: 0 auto;
      }

      .about-subtitle {
        font-size: 1.25rem;
        padding-top: 2rem;
      }

      .list-item-title {
        font-size: 1.5rem;
        color: #75BEC1;
        font-weight: 700;
        padding-bottom: 20px;
      }

      .list-items {
        list-style: none;
        padding-left: 0;

        .list-item {
          padding-bottom: 20px;

          .fa-check {
            color: #75BEC1;
          }
        }
      }
    }

    .section3 {
      background: white;
      padding: 0 0 0 0;
      max-width: 100%;

      .row {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 60%;
        margin: 0 auto;
      }

      .how-title {
        font-size: 1.35rem;
        text-align: center;
        padding-bottom: 3rem;
      }

      .how-list-items {
        list-style: none;
        padding-left: 0;
        display: flex;

        .how-list-item {
          padding: 0 1rem 3rem 1rem;
          font-size: 1rem;
          font-weight: 700;

          .list-numbers {
            background: #F3A83B;
            border-radius: 50%;
            color: white;
            margin-right: 10px;
            padding: 10px 15px;
          }
        }
      }
    }

    .section4 {
      padding-top: 3rem;

      .row {
        text-align: center;
        display: flex;
        flex-direction: row-reverse;

        .title-container {
          width: 40%;
          text-align: left;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
        }

        .pro-container {
          width: 60%;
          display: flex;
        }
      }

      .professional-title {
        padding-bottom: 2rem;
      }

      .professional-subtitle {
        text-align: left;
        font-size: 1.25rem;
      }

      .carousel-card {
        background: white;
        margin: 0 auto;
        border-radius: 15px;
        margin-bottom: 20px;
        width: 40%;

        .card-header {
          background: white;
          display: flex;
          flex-direction: column;
          align-items: center;
          border: none;

          img {
            border-radius: 50%;
            background: #d8d8d8;
            width: 50%;
          }

          .card-subtitle {
            background: white;
            margin-top: 10px;
            font-size: 1.3rem;
            font-weight: 700;
            margin-bottom: 0;
          }

          .score {
            border-bottom: 1px solid #c7c7c7;
            padding: 0 30px;

            .score-wrap {
              margin-bottom: 1.5rem;

              i {
                color: #75BEC1;
                font-size: 1.2rem;
              }
            }
          }
        }

        .card-body {
          background: white;
          text-align: center;
          font-style: italic;
          padding-top: 0.6rem;

          .card-text {
            color: #797979
          }
        }

        .card-footer {
          background: white;
          border: none;
        }
      }
    }

    .divider2 {
      position: relative;
      top: 4rem;

      .fakeButton {
        background: white;
        width: 35vw;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 1px;
        padding: 30px;

        p {
          margin: 0;
          font-weight: 500;
          text-align: center;
          font-size: 1.6rem;
        }
      }
    }

    .section5 {
      padding: 15rem 10.5rem 3rem 10.5rem;
      max-width: 100%;
      background: #75BEC1;

      .row {
        justify-content: space-evenly;
        align-items: center;
      }

      .footer-title {
        font-size: 1.7rem;
        font-weight: 700;
      }

      .footer-subtitle {
        font-size: 1.2rem;
        width: 50%;
      }

      .download-file {
        background: white;
        border-radius: 2px;
        padding: 8px 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 5rem;

        i {
          color: #F3A83B;
          font-size: 2rem;
          padding-right: 8px;
        }

        p {
          margin: 0;
          padding: 0;
          font-size: 0.9rem;
        }
      }
    }
  }

  section.new-status.desktop {
    margin: 2rem auto;
    max-width: 38vw;

    div {
      .stepper-container {
        display: flex;
        flex-direction: column;

        .main-content {
          border: 1px solid #E2E2E2;
          padding: 0 1rem;
          margin-top: 1rem;

          .book-more-container {
            width: 60%;
            margin: 3rem auto;
            margin-bottom: 2rem;
            display: flex;
            justify-content: center;

            a {
              border-radius: 3px;
              background-color: #F8AF29 !important;
              border-color: #F8AF29 !important;
              width: 90%;
              font-size: 20px;
              font-weight: 500;
              letter-spacing: 0;
              line-height: 24px;
              padding: 10px;
            }
          }
        }

        .footer-container {
          width: 20vw;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          margin-top: 1rem;
          position: absolute;
          left: 73%;

          .option-container {
            background-color: #34b9b9;
            padding: 1rem 1rem;
            width: 20vw;
          }
        }
      }
    }

    .top-content-container {
      display: flex;
      justify-content: space-between;

      .id-and-task {
        .appointment-id {
          color: #4A4A4A;
          font-family: Roboto, sans-serif;
          font-size: 18px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 21px;
        }

        .task {
          color: #9B9B9B;
          padding-top: 5px;
          font-size: 16px;
          font-weight: 300;
          letter-spacing: 0;
          line-height: 18px;
        }
      }
    }

    .details {
      .details-button {
        color: #4A4A4A;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 17px;
        cursor: pointer;
      }
    }

    .stepper-container {
      padding-top: 1rem;

      .step {
        padding-top: 1rem;
        padding-bottom: 1rem;

        .more-information {
          display: flex;
          align-items: center;
          padding-left: 2rem;
          color: #D8D8D8;
          font-family: "Helvetica";
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 17px;
          padding-top: 5px;
          cursor: pointer;

          i {
            padding-left: 5px;
          }
        }

        .more-information-review-container {
          display: none;
          padding-left: 2rem;
          padding-top: 5px;
        }

        .circle-and-title {
          display: flex;
          align-items: center;

          .circle {
            height: 15px;
            width: 15px;
            background-color: #D8D8D8;
            border-radius: 50%;
            margin-right: 1rem;
          }

          .circle.active {
            background-color: #417505;
          }

          .title {
            color: #4A4A4A;
            font-family: Roboto;
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 19px;
          }
        }

        .text {
          color: #9B9B9B;
          font-size: 14px;
          font-weight: 300;
          letter-spacing: 0;
          line-height: 17px;
          padding-left: 2rem;
          padding-top: 3px;

          .cancel-button {
            color: #34b9b9;
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }

      .step-2-container {
        .more-information {
          display: flex;
          align-items: center;
          padding-left: 2rem;
          color: #D8D8D8;
          font-family: "Helvetica";
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 17px;
          padding-top: 5px;

          i {
            padding-left: 5px;
          }
        }

        .professional-information-container {
          padding-left: 2rem;
          padding-top: 5px;

          .header-container {
            display: flex;
            align-items: center;
            padding-top: 5px;

            .icons-images {
              height: 59px;
              width: 59px;
              background-color: #D8D8D8;
              border-radius: 50%;
              margin-right: 11px;
              background-position: center;
              background-repeat: no-repeat;
              background-size: cover;

              img {
                height: 30px;
                width: 30px;
                position: relative;
                top: 42px;
                left: 15px;
              }
            }

            .professional-info-container {
              color: #9B9B9B;
              font-family: "Helvetica";
              font-size: 14px;
              font-weight: 500;
              letter-spacing: 0;
              line-height: 17px;

              .professional-name {
                text-transform: capitalize;
              }

              .professional-reviews {
              }

              .professional-review-score {
                i {
                  color: #F8E71C;
                }
              }
            }

            .pro-review {
              background: #FFFFFF 0 0  no-repeat padding-box;
              box-shadow: 0 3px 6px #EAEAEA;
              border: 1px solid #BEBEBE;
              border-radius: 14px;
              width: 100%;
              padding: 14px 21px;
              column-gap: 14px;
              align-items: start;

              &_container {
                display: flex;
                column-gap: 3px;
              }

              &_avatar_container {
                width: 75px;
                height: 75px;
              }

              &_icon {
                background: #34b9b9;
                width: 90%;
                height: 90%;
                margin: auto;
                color: white;
                position: relative;
                font-size: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
              }

              &_avatar {
                object-fit: cover;
                width: inherit;
                height: inherit;
                border-radius: 50%;
              }

              &_since {
                font-size: 0.7em;
                background: #A3DFDE;
                border-radius: 15px;
                padding: 3px 9px;
              }
            }

            .owl-next i, .owl-prev i {
              font-size: 1rem;
              color: #CECECE;
            }
          }

          .professional-text {
            color: #9B9B9B;
            font-size: 14px;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 17px;
            padding-top: 20px;
          }

          .professional-reviews-container {
            display: none;
            padding: 0 30px;
          }
        }
      }
    }

    .book-more-container {
      width: 100%;
      margin-top: 3rem;
      display: flex;
      justify-content: center;

      a {
        border-radius: 3px;
        background-color: #F8AF29 !important;
        border-color: #F8AF29 !important;
        width: 90%;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 24px;
        padding: 10px;
      }
    }

    .footer-container {
      width: 20vw;
      display: flex;
      justify-content: center;
      align-items: center;

      .option-container {
        .your-appointment {
          color: #FFFFFF;
          font-family: Helvetica, sans-serif;
          font-size: 20px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 25px;
          text-align: center;
        }

        .option-time {
          color: #FFFFFF;
          font-size: 18px;
          font-weight: 300;
          letter-spacing: 0;
          line-height: 22px;
          text-align: center;
          padding-top: 5px;
        }

        .option-date {
          color: #FFFFFF;
          font-size: 18px;
          font-weight: 300;
          letter-spacing: 0;
          line-height: 22px;
          text-align: center;
          padding-top: 5px;
          text-transform: capitalize;
        }
      }
    }

    .appointment-cancelled {
      width: 91vw;
      right: 23px;
      position: relative;
      display: block !important;

      .back {
        color: #D8D8D8;
        font-size: 14px;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 17px;
        position: relative;
        bottom: 10px;

        i {
          padding-right: 3px;
        }
      }

      .title-container {
        margin-top: 2rem;

        .title {
          color: #D0021B;
          font-family: Roboto;
          font-size: 18px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 21px;
          text-align: center;
          text-decoration: underline;
          margin-bottom: 5px;
        }

        .subtitle {
          color: #4A4A4A;
          font-size: 16px;
          font-weight: 300;
          letter-spacing: 0;
          line-height: 18px;
          text-align: center;
        }
      }

      .details-container {
        margin: 0 1.5rem;
        margin-top: 2rem;

        .id-and-task {
          .appointment-id {
            color: #4A4A4A;
            font-family: Roboto;
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 21px;
          }

          .task {
            color: #9B9B9B;
            padding: 5px 0;
            font-size: 16px;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 18px;
          }

          #dual-branding-container-mobile {
            font-size: 14px;
            padding-bottom: 10px;
            color: #ababab;
          }
        }

        .address-container {
          .street {
            color: #9B9B9B;
            font-size: 14px;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 17px;
          }

          .postcode-city {
            color: #9B9B9B;
            font-size: 14px;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 17px;
          }
        }

        .expandables {
          padding-top: 15px;

          .more-information {
            padding: 3px 0;
            color: #4A4A4A;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 17px;

            i {
              padding-left: 3px;
            }
          }
        }

        .garantie-container {
          padding-top: 25px;

          .title {
            color: #4a4a4a;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 17px;
          }

          .price {
            color: #9B9B9B;
            font-size: 14px;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 17px;
          }

          .subtitle {
            color: #9B9B9B;
            font-size: 14px;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 17px;
          }

          .link {
            color: #34b9b9;
            font-size: 14px;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 17px;
            text-decoration: underline;
          }
        }
      }
    }

    #cancelModal {
      .modal-content {
        margin: 0 1rem;
        border-radius: .5rem;
        color: #4A4A4A;
        padding: 1rem;

        .modal-header {
          padding: 0.5rem;
          border: none;
          margin: 0 1rem;

          button {
            outline: none;
            position: relative;
            bottom: 22px;
            left: 35px;
            background: #9b9b9b;
            border-radius: 50%;
            height: 20px;
            width: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            opacity: 1;
            color: #fff;
          }
        }

        .modal-body {
          padding: 0 0.5rem;

          .title {
            color: #4A4A4A;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 17px;
          }

          .text {
            color: #4A4A4A;
            font-size: 14px;
            font-weight: 100;
            letter-spacing: 0;
            line-height: 17px;
          }

          .cancel-reasons-container {
            width: 100%;

            .cancel-label {
              color: #4A4A4A;
              font-size: 14px;
              font-weight: 300;
              letter-spacing: 0;
              line-height: 17px;
            }

            #cancel-reasons {
              width: 100%;
              border-color: #D8D8D8;
              color: #9B9B9B;
              font-weight: 300;
              font-size: 12px;
              padding: 3px;
            }
          }
        }

        .modal-footer {
          border: none;
          margin-top: 1.5rem;

          #cancel-submit-button, #pay-cancel-submit-button {
            text-align: center;
            width: 90%;
            background: #34B9B9;
            color: white;
            margin: 0 auto;
            padding: 3px;
            border-radius: 0.2rem;
            font-size: 20px;
            cursor: pointer;
          }
        }
      }
    }

    .appointment-details {
      width: 38vw;
      display: none;
      border: 1px solid #e2e2e2;
      margin-top: 2rem;
      padding-bottom: 3rem;

      .back {
        color: #D8D8D8;
        font-size: 14px;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 17px;
        position: relative;
        bottom: 40px;
        cursor: pointer;

        i {
          padding-right: 3px;
        }
      }

      .title-container {
        margin-top: 2rem;

        .title {
          color: #D0021B;
          font-family: Roboto;
          font-size: 18px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 21px;
          text-align: center;
          text-decoration: underline;
          margin-bottom: 5px;
        }

        .subtitle {
          color: #4A4A4A;
          font-size: 16px;
          font-weight: 300;
          letter-spacing: 0;
          line-height: 18px;
          text-align: center;
        }
      }

      .details-container {
        margin: 0 1.5rem;
        margin-top: 2rem;

        .id-and-task {
          .appointment-id {
            color: #4A4A4A;
            font-family: Roboto;
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 21px;
          }

          .task {
            color: #9B9B9B;
            padding: 5px 0;
            font-size: 16px;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 18px;
          }

          #dual-branding-container-mobile {
            font-size: 14px;
            padding-bottom: 10px;
            color: #ababab;
            font-weight: 300;
          }
        }

        .address-container {
          .street {
            color: #9B9B9B;
            font-size: 14px;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 17px;
          }

          .postcode-city {
            color: #9B9B9B;
            font-size: 14px;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 17px;
          }
        }

        .expandables {
          padding-top: 15px;

          .more-information {
            padding: 5px 0;
            margin: 15px 0;
            color: #4A4A4A;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 17px;
            cursor: pointer;

            i {
              padding-left: 3px;
            }
          }

          .details-booking-container {
            display: none;
            padding-top: 5px;

            .details-container {
              display: flex;
              margin: 0;

              .key-value {
                display: flex;
                flex-direction: column;
                width: 45%;
                margin-bottom: 1rem;

                .key {
                  color: #4a4a4a;
                  font-size: 14px;
                  font-weight: 300;
                  letter-spacing: 0;
                  line-height: 17px;
                  margin-bottom: 0;
                }

                .value {
                  color: #9B9B9B;
                  font-size: 14px;
                  font-weight: 300;
                  letter-spacing: 0;
                  line-height: 17px;
                }
              }
            }

            .comment-container {
              .key {
                color: #4a4a4a;
                font-size: 14px;
                font-weight: 300;
                letter-spacing: 0;
                line-height: 17px;
                margin-bottom: 0;
              }

              .value {
                color: #9B9B9B;
                font-size: 14px;
                font-weight: 300;
                letter-spacing: 0;
                line-height: 17px;
              }
            }
          }

          .available-times-container {
            display: none;
            padding-top: 5px;

            .day-container {
              padding: 5px 0;

              .date-text {
                color: #9B9B9B;
                font-size: 14px;
                font-weight: 300;
                letter-spacing: 0;
                line-height: 17px;
              }

              .option-time {
                color: #9B9B9B;
                font-size: 14px;
                font-weight: 300;
                letter-spacing: 0;
                line-height: 17px;
              }
            }

            .rebook-button {
              color: #A3DFDE;
              font-size: 14px;
              font-weight: 500;
              letter-spacing: 0;
              line-height: 17px;
              padding-bottom: 15px;
              padding-top: 10px;
              cursor: pointer;
            }
          }

          .uploaded-photos-container {
            display: none;
            padding-top: 10px;

            .detail.thumbnails {
              display: flex;
              flex-wrap: wrap;

              div {
                width: 27%;
                margin: 0 8px;
              }

              img {
                width: 80px;
                height: 80px;
                object-fit: cover;
                margin-bottom: 20px;
              }

              .delete-image {
                position: relative;
                bottom: 50%;
                right: 10%;
                background: #9b9b9b;
                border-radius: 50%;
                padding: 0 5px;
                color: #fff;
                cursor: pointer;
              }

              .loader {
                position: relative;
                right: 26%;
                top: 10px;
                height: 50px;
                width: 50px;
              }
            }

            .upload-more {
              color: #A3DFDE;
              font-size: 14px;
              font-weight: 500;
              letter-spacing: 0;
              line-height: 17px;
              padding-top: 10px;
              cursor: pointer;
            }
          }
        }

        .garantie-container {
          padding-top: 25px;

          .title {
            color: #4a4a4a;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 17px;
            padding-bottom: 3px;
          }

          .price {
            color: #9B9B9B;
            font-size: 14px;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 17px;
            display: flex;
            align-items: center;

            .check {
              border-radius: 50%;
              background-color: #34b9b9;
              width: 15px;
              height: 15px;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-right: 3px;

              i {
                color: white;
                font-size: 9px;
              }
            }
          }

          .subtitle {
            color: #9B9B9B;
            font-size: 14px;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 17px;
          }

          .link {
            color: #34b9b9;
            font-size: 14px;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 17px;
            text-decoration: underline;
          }
        }
      }

      .row {
        &.has-coupon {
          flex-direction: column;
        }

        &.coupon {
          /*padding: 1.5rem 1.75rem 1.5rem 1.75rem;
                width: 100%;*/
          .value {
            color: #9b9b9b;
            font-size: 14px;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 17px;
            margin-left: 1.5rem;
            padding-left: 15px;
          }

          .input {
            padding: 0;
            margin-top: 2rem;

            .title {
              color: #4a4a4a;
              font-size: 14px;
              font-weight: 500;
              letter-spacing: 0;
              line-height: 17px;
              margin: 0 1.5rem;
              padding-left: 15px;
            }

            &.desktop {
              display: none;
            }

            .coupon-container {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 1rem;
              margin: 0 1.5rem;

              .form-field {
                margin-top: 0;
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;

                .form-field__control {
                  border-bottom-right-radius: 0 !important;
                  border-top-right-radius: 0 !important;
                }

                #coupon-input-button, #coupon-input-button-mob {
                  padding: 11px 15px !important;
                  border-left: none;
                  margin-top: 0;
                  border-radius: 7px;
                  border-top-left-radius: 0 !important;
                  border-bottom-left-radius: 0 !important;
                  border: 1px solid #D8D8D8;
                  background: #D8D8D8;
                  border-color: #D8D8D8;
                  text-transform: uppercase;
                  color: #FFFFFF;
                  font-size: 12px;
                  font-weight: 500;
                  letter-spacing: 0;
                  line-height: 15px;

                  &:hover {
                    background-color: #999;
                    border-color: #999;
                  }
                }

                #coupon-input-button.remove, #coupon-input-button-mob.remove {
                  display: none;
                  padding: 11px 15px !important;
                  border: 1px solid #D8D8D8;
                  border-left: none;
                  margin-top: 0;
                  border-radius: 7px;
                  border-top-left-radius: 0 !important;
                  border-bottom-left-radius: 0 !important;
                  border-left: 1px solid #D8D8D8;
                  background: black;
                  border-color: black;
                  color: white;
                  text-transform: uppercase;
                  cursor: pointer;
                  font-size: 1rem;
                  font-weight: 700;

                  &:hover {
                    background-color: black;
                    border-color: black;
                  }
                }

                .form-field__control {
                  width: 100%;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                }
              }

              input {
                padding: 9px 15px !important;
                font-size: 12px;
                width: 100%;
                margin-top: 0;
                border-right: none;
                border-top-right-radius: 0 !important;
                border-bottom-right-radius: 0 !important;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
                border: 1px solid #D8D8D8;
                color: #D8D8D8;
              }

              input::placeholder {
                color: #D8D8D8;
              }
            }
          }

          .alert.alert-success.success-label {
            display: none;
            margin: 0 1.5rem;
          }

          .alert.alert-danger.danger-label {
            display: none;
            margin: 0 1.5rem;
          }
        }
      }
    }

    #rebookModal {
      .modal-content {
        border-radius: 0.5rem;

        .modal-body {
          border-radius: 0.5rem;

          .close {
            position: relative;
            bottom: 29px;
            left: 23px;
            background: #9B9B9B;
            color: white;
            padding: 3px 9px;
            border-radius: 50%;
            opacity: 1;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .options-checkboxes {
            .time-option-container {
              .time-options-title {
                padding: 1rem 0;
              }
            }
          }
        }

        .modal-footer {
          padding-top: 0;
          padding-bottom: 30px;

          #rebook-submit-button {
            background: #F8AF29;
            padding: 9px 3px;
            border-radius: 5px;
            color: white;
            width: 90%;
            text-align: center;
            margin: 0 auto;
            font-size: 25px;
          }
        }
      }
    }

    #rebookCalendar {
      .zabuto_calendar {
        padding: 20px;

        .calendar-month-header {
          td {
            background-color: white;
          }
        }

        .calendar-dow-header {
          th {
            background-color: white;
            color: #D8D8D8;
            font-weight: 400;
            border: none;
          }
        }

        .calendar-dow {
          td {
            background-color: white;
            color: #4a4a4a;

            .day {
              .badge.badge-event {
                background: #34b9b9;
                color: white !important;
                padding-top: 4px;
                border-radius: 50%;
                text-align: center;
                height: 33px;
                width: 33px;
                top: 0;
                font-weight: 400;

                &:after {
                  content: "";
                }
              }
            }

            div.day {
              margin: 5px auto;
              padding-top: 1px;
              border-radius: 50%;
              height: 26px;
              width: 26px;
              font-variant-numeric: proportional-nums;
              font-size: 14px;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 0;
            }

            div.day.activeDay {
              --box-shadow-color: #666;
              box-shadow: 0 1px 3px 0 var(--box-shadow-color);
            }

            div.day.withinRange, div.day.withinRangeConfirmed {
              /*&.afterStartRange {
                                                    border-radius: 0;
                                                    --box-shadow-color: #34b9b8;
                                                    box-shadow: -20px 0 0 -1.5px var(--box-shadow-color);
                                                    position: relative;
                                                    top: 0.5px;
                                                }

                                                &.beforeEndRange {
                                                    border-radius: 0;
                                                    --box-shadow-color: #34b9b8;
                                                    box-shadow: 25px 0.5px 0 0 var(--box-shadow-color);
                                                }*/
            }

            &.startRange, &.startRangeConfirmed {
              background-image: linear-gradient(white, white 22%, #34b9b8 7%, #34b9b8 79%, white 79%);
              border-top-left-radius: 100vw;
              border-bottom-left-radius: 100vw;

              div.day {
                color: white;
                padding-top: 0;
                /*--box-shadow-color: #666;
                                                    box-shadow: 0 1px 3px 0 var(--box-shadow-color);*/
                span {
                  --box-shadow-color: #666;
                  box-shadow: 0 1px 3px 0 var(--box-shadow-color);
                }
              }
            }

            &.endRange, &.endRangeConfirmed {
              background-image: linear-gradient(white, white 22%, #34b9b8 7%, #34b9b8 79%, white 79%);
              border-top-right-radius: 100vw;
              border-bottom-right-radius: 100vw;

              div.day {
                color: white;
                padding-top: 0;
                /*--box-shadow-color: #666;
                                                    box-shadow: 0 1px 3px 0 var(--box-shadow-color);*/
                span {
                  --box-shadow-color: #666;
                  box-shadow: 0 1px 3px 0 var(--box-shadow-color);
                }
              }
            }

            &.withinRange, &.withinRangeConfirmed {
              background-image: linear-gradient(white, white 22%, #34b9b8 7%, #34b9b8 79%, white 79%);

              div.day {
                color: white;
              }
            }
          }
        }

        #calendar-next, #calendar-prev {
          color: #555;
        }

        #calendar-prev {
          /*position: relative;
                    left: -14.5rem;*/
        }

        .calendar-header {
          span {
            color: #555;
            position: relative;
            left: 44px;
          }
        }

        .table tr:last-child {
          border-bottom: none;
        }

        .table {
          tr:first-child {
            background: none;
          }

          tr:last-child {
            height: unset;
          }

          tr {
            &:hover {
              background: none;
            }

            td {
              div.day {
                margin: 5px auto;
                padding-top: 1px;
                border-radius: 50%;
                text-align: center;
                height: 33px;
                width: 33px;
                font-variant-numeric: proportional-nums;
                font-weight: 500;
              }

              div.day.activeDay {
                --box-shadow-color: #666;
                box-shadow: 0 1px 3px 0 var(--box-shadow-color);
              }
            }
          }

          .badge-event::after {
            bottom: 16px;
          }
        }

        .calendar-month-header {
          td {
            background-color: white;
          }
        }

        .calendar-dow-header {
          th {
            background-color: white;
            color: #D8D8D8;
            font-weight: 400;
          }
        }

        .calendar-dow {
          td {
            background-color: white;
            color: #4a4a4a;

            .day {
              .badge.badge-event {
                background: #34b9b9;
                color: white !important;
                padding-top: 8px;
                border-radius: 50%;
                text-align: center;
                height: 33px;
                width: 33px;
                top: 0;
                font-weight: 400;
                font-weight: 500;

                &:after {
                  content: "";
                }
              }
            }
          }
        }
      }
    }

    .time-option-container.mobile-rebook {
      margin: 0 20px;

      #currentChosenDate {
        position: absolute;
        visibility: hidden;
      }

      .time-checkbox-container {
        display: flex;
        position: relative;
        padding-left: 45px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 1.2rem;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        .time-checkbox-label {
          width: 35%;
        }
      }

      .time-details {
        .time-price, .time-servicefee {
          padding-left: 20px;
        }
      }

      .time-details .rate-type {
        display: none;
      }

      /* Hide the browser's default checkbox */
      .time-checkbox-container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }

      /* Create a custom checkbox */
      .checkmark {
        position: absolute;
        top: 5px;
        left: 0;
        height: 25px;
        width: 25px;
        background-color: #fff;
        border: 1px solid #ccc;
      }

      /* On mouse-over, add a grey background color */
      .time-checkbox-container:hover input ~ .checkmark {
        background-color: #fafafa;
      }

      /* When the checkbox is checked, add a blue background */
      .time-checkbox-container input:checked ~ .checkmark {
        background-color: #34b9b9;
      }

      /* Create the checkmark/indicator (hidden when not checked) */
      .checkmark:after {
        content: "";
        position: absolute;
        display: none;
      }

      /* Show the checkmark when checked */
      .time-checkbox-container input:checked ~ .checkmark:after {
        display: block;
      }

      /* Style the checkmark/indicator */
      .time-checkbox-container .checkmark:after {
        left: 9px;
        top: 3px;
        width: 7px;
        height: 14px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }


  section.new-status.mobile {
    margin: 2rem 40px;

    .top-content-container {
      display: flex;
      justify-content: space-between;

      .id-and-task {
        .appointment-id {
          color: #4A4A4A;
          font-family: Roboto;
          font-size: 18px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 21px;
        }

        .task {
          color: #9B9B9B;
          padding-top: 5px;
          font-size: 16px;
          font-weight: 300;
          letter-spacing: 0;
          line-height: 18px;
        }
      }
    }

    .details {
      .details-button {
        color: #4A4A4A;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 17px;
      }
    }

    .stepper-container {
      padding-top: 1rem;

      .step {
        padding-top: 1rem;

        .more-information {
          display: flex;
          align-items: center;
          padding-left: 2rem;
          color: #D8D8D8;
          font-family: "Helvetica";
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 17px;
          padding-top: 5px;

          i {
            padding-left: 5px;
          }
        }

        .more-information-review-container {
          display: none;
          padding-left: 2rem;
          padding-top: 5px;
        }

        .circle-and-title {
          display: flex;
          align-items: center;

          .circle {
            height: 15px;
            width: 15px;
            background-color: #D8D8D8;
            border-radius: 50%;
            margin-right: 1rem;
          }

          .circle.active {
            background-color: #417505;
          }

          .title {
            color: #4A4A4A;
            font-family: Roboto;
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 19px;
          }
        }

        .text {
          color: #9B9B9B;
          font-size: 14px;
          font-weight: 300;
          letter-spacing: 0;
          line-height: 17px;
          padding-left: 2rem;
          padding-top: 3px;

          .cancel-button {
            color: #34b9b9;
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }

      .step-2-container {
        .more-information {
          display: flex;
          align-items: center;
          padding-left: 2rem;
          color: #D8D8D8;
          font-family: "Helvetica";
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 17px;
          padding-top: 5px;

          i {
            padding-left: 5px;
          }
        }

        //mobile
        .professional-information-container {
          // display: none;
          padding-left: 2rem;
          padding-top: 5px;

          .header-container {
            display: flex;
            align-items: center;
            padding-top: 5px;

            .icons-images {
              height: 59px;
              width: 59px;
              background-color: #D8D8D8;
              border-radius: 50%;
              margin-right: 11px;
              //todo
              /*
                            background-image: url("../src/assets/zoofy/images/no-photos.svg");
                            */
              background-position: center;
              background-repeat: no-repeat;
              background-size: cover;

              img {
                height: 30px;
                width: 30px;
                position: relative;
                top: 42px;
                left: 15px;
              }
            }

            .professional-info-container {
              color: #9B9B9B;
              font-family: "Helvetica";
              font-size: 14px;
              font-weight: 500;
              letter-spacing: 0;
              line-height: 17px;

              .professional-name {
                text-transform: capitalize;
              }

              .professional-reviews {
              }

              .professional-review-score {
                i {
                  color: #F8E71C;
                }
              }
            }

            .pro-review {
              background: #FFFFFF 0% 0% no-repeat padding-box;
              box-shadow: 0 3px 6px #EAEAEA;
              border: 1px solid #BEBEBE;
              border-radius: 14px;
              width: 100%;
              padding: 14px;
              column-gap: 14px;
              align-items: start;

              &_avatar_container {
                width: 75px;
                height: 75px;
              }

              &_icon {
                background: #34b9b9;
                width: 95%;
                height: 95%;
                margin: auto;
                color: white;
                position: relative;
                font-size: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
              }

              &_avatar {
                object-fit: cover;
                width: inherit;
                height: inherit;
                border-radius: 50%;
              }

              &_since {
                font-size: 0.8em;
                background: #A3DFDE;
                border-radius: 15px;
                padding: 5px 9px;
              }

              .professional-reviews-container {
                display: none;
                padding: 0 30px;
              }

              .owl-next i, .owl-prev i {
                font-size: 1rem;
                color: #CECECE;
              }
            }
          }

          .professional-text {
            color: #9B9B9B;
            font-size: 14px;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 17px;
            padding-top: 20px;
          }
        }
      }
    }

    .book-more-container {
      width: 100%;
      margin-top: 3rem;
      display: flex;
      justify-content: center;

      a {
        border-radius: 3px;
        background-color: #F8AF29 !important;
        border-color: #F8AF29 !important;
        width: 90%;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 24px;
        padding: 10px;
      }
    }

    .footer-container {
      background-color: #34b9b9;
      height: 7rem;
      width: 100vw;
      position: relative;
      right: 40px;
      margin-top: 3rem;
      display: flex;
      justify-content: center;
      align-items: center;

      .option-container {
        .your-appointment {
          color: #FFFFFF;
          font-family: "Helvetica";
          font-size: 20px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 25px;
          text-align: center;
        }

        .option-time {
          color: #FFFFFF;
          font-size: 18px;
          font-weight: 300;
          letter-spacing: 0;
          line-height: 22px;
          text-align: center;
          padding-top: 5px;
        }

        .option-date {
          color: #FFFFFF;
          font-size: 18px;
          font-weight: 300;
          letter-spacing: 0;
          line-height: 22px;
          text-align: center;
          padding-top: 5px;
          text-transform: capitalize;
        }
      }
    }

    .appointment-cancelled {
      width: 91vw;
      right: 23px;
      position: relative;
      display: block !important;

      .back {
        color: #D8D8D8;
        font-size: 14px;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 17px;
        position: relative;
        bottom: 10px;

        i {
          padding-right: 3px;
        }
      }

      .title-container {
        margin-top: 2rem;

        .title {
          color: #D0021B;
          font-family: Roboto;
          font-size: 18px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 21px;
          text-align: center;
          text-decoration: underline;
          margin-bottom: 5px;
        }

        .subtitle {
          color: #4A4A4A;
          font-size: 16px;
          font-weight: 300;
          letter-spacing: 0;
          line-height: 18px;
          text-align: center;
        }
      }

      .details-container {
        margin: 0 1.5rem;
        margin-top: 2rem;

        .id-and-task {
          .appointment-id {
            color: #4A4A4A;
            font-family: Roboto;
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 21px;
          }

          .task {
            color: #9B9B9B;
            padding: 5px 0;
            font-size: 16px;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 18px;
          }

          #dual-branding-container-mobile {
            font-size: 14px;
            padding-bottom: 10px;
            color: #ababab;
          }
        }

        .address-container {
          .street {
            color: #9B9B9B;
            font-size: 14px;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 17px;
          }

          .postcode-city {
            color: #9B9B9B;
            font-size: 14px;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 17px;
          }
        }

        .expandables {
          padding-top: 15px;

          .more-information {
            padding: 3px 0;
            color: #4A4A4A;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 17px;

            i {
              padding-left: 3px;
            }
          }
        }

        .garantie-container {
          padding-top: 25px;

          .title {
            color: #4a4a4a;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 17px;
          }

          .price {
            color: #9B9B9B;
            font-size: 14px;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 17px;
          }

          .subtitle {
            color: #9B9B9B;
            font-size: 14px;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 17px;
          }

          .link {
            color: #34b9b9;
            font-size: 14px;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 17px;
            text-decoration: underline;
          }
        }
      }
    }

    #cancelModal {
      .modal-content {
        margin: 0 1rem;
        border-radius: .5rem;
        color: #4A4A4A;
        padding: 1rem;

        .modal-header {
          padding: 0.5rem;
          border: none;
          margin: 0 1rem;

          button {
            outline: none;
            position: relative;
            bottom: 22px;
            left: 35px;
            background: #9b9b9b;
            border-radius: 50%;
            height: 20px;
            width: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            opacity: 1;
            color: #fff;
          }
        }

        .modal-body {
          padding: 0 0.5rem;

          .title {
            color: #4A4A4A;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 17px;
          }

          .text {
            color: #4A4A4A;
            font-size: 14px;
            font-weight: 100;
            letter-spacing: 0;
            line-height: 17px;
          }

          .cancel-reasons-container {
            width: 100%;

            .cancel-label {
              color: #4A4A4A;
              font-size: 14px;
              font-weight: 300;
              letter-spacing: 0;
              line-height: 17px;
            }

            #cancel-reasons {
              width: 100%;
              border-color: #D8D8D8;
              color: #9B9B9B;
              font-weight: 300;
              font-size: 12px;
              padding: 3px;
            }
          }
        }

        .modal-footer {
          border: none;
          margin-top: 1.5rem;

          #cancel-submit-button, #pay-cancel-submit-button {
            text-align: center;
            width: 90%;
            background: #34B9B9;
            color: white;
            margin: 0 auto;
            padding: 3px;
            border-radius: 0.2rem;
            font-size: 20px;
          }
        }
      }
    }

    .appointment-details {
      width: 91vw;
      right: 23px;
      position: relative;
      display: none;

      .back {
        color: #D8D8D8;
        font-size: 14px;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 17px;
        position: relative;
        bottom: 10px;

        i {
          padding-right: 3px;
        }
      }

      .title-container {
        margin-top: 2rem;

        .title {
          color: #D0021B;
          font-family: Roboto;
          font-size: 18px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 21px;
          text-align: center;
          text-decoration: underline;
          margin-bottom: 5px;
        }

        .subtitle {
          color: #4A4A4A;
          font-size: 16px;
          font-weight: 300;
          letter-spacing: 0;
          line-height: 18px;
          text-align: center;
        }
      }

      .details-container {
        margin: 0 1.5rem;
        margin-top: 2rem;

        .id-and-task {
          .appointment-id {
            color: #4A4A4A;
            font-family: Roboto;
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 21px;
          }

          .task {
            color: #9B9B9B;
            padding: 5px 0;
            font-size: 16px;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 18px;
          }

          #dual-branding-container-mobile {
            font-size: 14px;
            padding-bottom: 10px;
            color: #ababab;
            font-weight: 300;
          }
        }

        .address-container {
          .street {
            color: #9B9B9B;
            font-size: 14px;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 17px;
          }

          .postcode-city {
            color: #9B9B9B;
            font-size: 14px;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 17px;
          }
        }

        .expandables {
          padding-top: 15px;

          .more-information {
            padding: 5px 0;
            color: #4A4A4A;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 17px;

            i {
              padding-left: 3px;
            }
          }

          .details-booking-container {
            display: none;
            padding-top: 5px;

            .details-container {
              display: flex;
              margin: 0;

              .key-value {
                display: flex;
                flex-direction: column;
                width: 45%;
                margin-bottom: 1rem;

                .key {
                  color: #4a4a4a;
                  font-size: 14px;
                  font-weight: 300;
                  letter-spacing: 0;
                  line-height: 17px;
                  margin-bottom: 0;
                }

                .value {
                  color: #9B9B9B;
                  font-size: 14px;
                  font-weight: 300;
                  letter-spacing: 0;
                  line-height: 17px;
                }
              }
            }

            .comment-container {
              .key {
                color: #4a4a4a;
                font-size: 14px;
                font-weight: 300;
                letter-spacing: 0;
                line-height: 17px;
                margin-bottom: 0;
              }

              .value {
                color: #9B9B9B;
                font-size: 14px;
                font-weight: 300;
                letter-spacing: 0;
                line-height: 17px;
              }
            }
          }

          .available-times-container {
            display: none;
            padding-top: 5px;

            .day-container {
              padding: 5px 0;

              .date-text {
                color: #9B9B9B;
                font-size: 14px;
                font-weight: 300;
                letter-spacing: 0;
                line-height: 17px;
              }

              .option-time {
                color: #9B9B9B;
                font-size: 14px;
                font-weight: 300;
                letter-spacing: 0;
                line-height: 17px;
              }
            }

            .rebook-button {
              color: #A3DFDE;
              font-size: 14px;
              font-weight: 500;
              letter-spacing: 0;
              line-height: 17px;
              padding-bottom: 15px;
              padding-top: 10px;
            }
          }

          .uploaded-photos-container {
            display: none;
            padding-top: 10px;

            .detail.thumbnails {
              display: flex;
              flex-wrap: wrap;

              div {
                width: 27%;
                margin: 0 8px;
              }

              img {
                width: 80px;
                height: 80px;
                object-fit: cover;
              }

              .delete-image {
                position: relative;
                bottom: 86px;
                left: 69px;
                background: #9b9b9b;
                border-radius: 50%;
                padding: 0 5px;
                color: #fff;
              }
            }

            .upload-more {
              color: #A3DFDE;
              font-size: 14px;
              font-weight: 500;
              letter-spacing: 0;
              line-height: 17px;
              padding-top: 10px;
            }
          }
        }

        .garantie-container {
          padding-top: 25px;

          .title {
            color: #4a4a4a;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 17px;
            padding-bottom: 3px;
          }

          .price {
            color: #9B9B9B;
            font-size: 14px;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 17px;
            display: flex;
            align-items: center;

            .check {
              border-radius: 50%;
              background-color: #34b9b9;
              width: 15px;
              height: 15px;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-right: 3px;

              i {
                color: white;
                font-size: 9px;
              }
            }
          }

          .subtitle {
            color: #9B9B9B;
            font-size: 14px;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 17px;
          }

          .link {
            color: #34b9b9;
            font-size: 14px;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 17px;
            text-decoration: underline;
          }
        }
      }

      .row {
        &.has-coupon {
          flex-direction: column;
        }

        &.coupon {
          /*padding: 1.5rem 1.75rem 1.5rem 1.75rem;
                width: 100%;*/
          .value {
            color: #9b9b9b;
            font-size: 14px;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 17px;
            margin-left: 1.5rem;
            padding-left: 15px;
          }

          .input {
            padding: 0;
            margin-top: 2rem;

            .title {
              color: #4a4a4a;
              font-size: 14px;
              font-weight: 500;
              letter-spacing: 0;
              line-height: 17px;
              margin: 0 1.5rem;
              padding-left: 15px;
            }

            &.desktop {
              display: none;
            }

            .coupon-container {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 1rem;
              margin: 0 1.5rem;

              .form-field {
                margin-top: 0;
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;

                .form-field__control {
                  border-bottom-right-radius: 0 !important;
                  border-top-right-radius: 0 !important;
                }

                #coupon-input-button, #coupon-input-button-mob {
                  padding: 11px 15px !important;
                  border-left: none;
                  margin-top: 0;
                  border-radius: 7px;
                  border-top-left-radius: 0 !important;
                  border-bottom-left-radius: 0 !important;
                  border: 1px solid #D8D8D8;
                  background: #D8D8D8;
                  border-color: #D8D8D8;
                  text-transform: uppercase;
                  color: #FFFFFF;
                  font-size: 12px;
                  font-weight: 500;
                  letter-spacing: 0;
                  line-height: 15px;

                  &:hover {
                    background-color: #999;
                    border-color: #999;
                  }
                }

                #coupon-input-button.remove, #coupon-input-button-mob.remove {
                  display: none;
                  padding: 11px 15px !important;
                  border: 1px solid #D8D8D8;
                  border-left: none;
                  margin-top: 0;
                  border-radius: 7px;
                  border-top-left-radius: 0 !important;
                  border-bottom-left-radius: 0 !important;
                  border-left: 1px solid #D8D8D8;
                  background: black;
                  border-color: black;
                  color: white;
                  text-transform: uppercase;
                  cursor: pointer;
                  font-size: 1rem;
                  font-weight: 700;

                  &:hover {
                    background-color: black;
                    border-color: black;
                  }
                }

                .form-field__control {
                  width: 100%;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                }
              }

              input {
                padding: 9px 15px !important;
                font-size: 12px;
                width: 100%;
                margin-top: 0;
                border-right: none;
                border-top-right-radius: 0 !important;
                border-bottom-right-radius: 0 !important;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
                border: 1px solid #D8D8D8;
                color: #D8D8D8;
              }

              input::placeholder {
                color: #D8D8D8;
              }
            }
          }

          .alert.alert-success.success-label {
            display: none;
            margin: 0 1.5rem;
          }

          .alert.alert-danger.danger-label {
            display: none;
            margin: 0 1.5rem;
          }
        }
      }
    }

    #rebookModal {
      .modal-content {
        border-radius: 0.5rem;

        .modal-body {
          border-radius: 0.5rem;

          .close {
            position: relative;
            bottom: 29px;
            left: 23px;
            background: #9B9B9B;
            color: white;
            padding: 3px 9px;
            border-radius: 50%;
            opacity: 1;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        .modal-footer {
          padding-top: 0;
          padding-bottom: 30px;

          #rebook-submit-button {
            background: #F8AF29;
            padding: 9px 3px;
            border-radius: 5px;
            color: white;
            width: 90%;
            text-align: center;
            margin: 0 auto;
            font-size: 25px;
          }
        }
      }
    }

    #rebookCalendar {
      .zabuto_calendar {
        .calendar-month-header {
          td {
            background-color: white;
          }
        }

        .calendar-dow-header {
          th {
            background-color: white;
            color: #D8D8D8;
            font-weight: 400;
            border: none;
          }
        }

        .calendar-dow {
          td {
            background-color: white;
            color: #4a4a4a;

            .day {
              .badge.badge-event {
                background: #34b9b9;
                color: white !important;
                padding-top: 4px;
                border-radius: 50%;
                text-align: center;
                height: 33px;
                width: 33px;
                top: 0;
                font-weight: 400;

                &:after {
                  content: "";
                }
              }
            }

            div.day {
              margin: 5px;
              padding-top: 1px;
              border-radius: 50%;
              height: 26px;
              width: 26px;
              font-variant-numeric: proportional-nums;
              font-size: 14px;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 0;
            }

            div.day.activeDay {
              --box-shadow-color: #666;
              box-shadow: 0 1px 3px 0 var(--box-shadow-color);
            }

            div.day.withinRange, div.day.withinRangeConfirmed {
              /*&.afterStartRange {
                                                    border-radius: 0;
                                                    --box-shadow-color: #34b9b8;
                                                    box-shadow: -20px 0px 0px -1.5px var(--box-shadow-color);
                                                    position: relative;
                                                    top: 0.5px;
                                                }

                                                &.beforeEndRange {
                                                    border-radius: 0;
                                                    --box-shadow-color: #34b9b8;
                                                    box-shadow: 25px 0.5px 0px 0px var(--box-shadow-color);
                                                }*/
            }

            &.startRange, &.startRangeConfirmed {
              background-image: linear-gradient(white, white 22%, #34b9b8 7%, #34b9b8 79%, white 79%);
              border-top-left-radius: 100vw;
              border-bottom-left-radius: 100vw;

              div.day {
                color: white;
                padding-top: 0px;
                /*--box-shadow-color: #666;
                                                    box-shadow: 0 1px 3px 0 var(--box-shadow-color);*/
                span {
                  --box-shadow-color: #666;
                  box-shadow: 0 1px 3px 0 var(--box-shadow-color);
                }
              }
            }

            &.endRange, &.endRangeConfirmed {
              background-image: linear-gradient(white, white 22%, #34b9b8 7%, #34b9b8 79%, white 79%);
              border-top-right-radius: 100vw;
              border-bottom-right-radius: 100vw;

              div.day {
                color: white;
                padding-top: 0px;
                /*--box-shadow-color: #666;
                                                    box-shadow: 0 1px 3px 0 var(--box-shadow-color);*/
                span {
                  --box-shadow-color: #666;
                  box-shadow: 0 1px 3px 0 var(--box-shadow-color);
                }
              }
            }

            &.withinRange, &.withinRangeConfirmed {
              background-image: linear-gradient(white, white 22%, #34b9b8 7%, #34b9b8 79%, white 79%);

              div.day {
                color: white;
              }
            }
          }
        }

        #calendar-next, #calendar-prev {
          color: #555;
        }

        #calendar-prev {
          position: relative;
          left: -14.5rem;
        }

        .calendar-header {
          span {
            color: #555;
            position: relative;
            left: 44px;
          }
        }

        .table tr:last-child {
          border-bottom: none;
        }

        .table {
          tr:first-child {
            background: none;
          }

          tr:last-child {
            height: unset;
          }

          tr {
            &:hover {
              background: none;
            }

            td {
              div.day {
                margin: 5px;
                padding-top: 1px;
                border-radius: 50%;
                text-align: center;
                height: 33px;
                width: 33px;
                font-variant-numeric: proportional-nums;
                font-weight: 500;
              }

              div.day.activeDay {
                --box-shadow-color: #666;
                box-shadow: 0px 1px 3px 0px var(--box-shadow-color);
              }
            }
          }

          .badge-event::after {
            bottom: 16px;
          }
        }

        .calendar-month-header {
          td {
            background-color: white;
          }
        }

        .calendar-dow-header {
          th {
            background-color: white;
            color: #D8D8D8;
            font-weight: 400;
          }
        }

        .calendar-dow {
          td {
            background-color: white;
            color: #4a4a4a;

            .day {
              .badge.badge-event {
                background: #34b9b9;
                color: white !important;
                padding-top: 8px;
                border-radius: 50%;
                text-align: center;
                height: 33px;
                width: 33px;
                top: 0px;
                font-weight: 400;
                font-weight: 500;

                &:after {
                  content: "";
                }
              }
            }
          }
        }
      }
    }

    .time-option-container.mobile-rebook {
      margin: 0px 20px;

      #currentChosenDate {
        position: absolute;
        visibility: hidden;
      }

      .time-checkbox-container {
        display: flex;
        position: relative;
        padding-left: 45px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 1.2rem;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        .time-checkbox-label {
          width: 35%;
        }
      }

      .time-details {
        .time-price, .time-servicefee {
          padding-left: 20px;
        }
      }

      .time-details .rate-type {
        display: none;
      }

      /* Hide the browser's default checkbox */
      .time-checkbox-container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }

      /* Create a custom checkbox */
      .checkmark {
        position: absolute;
        top: 5px;
        left: 0;
        height: 25px;
        width: 25px;
        background-color: #fff;
        border: 1px solid #ccc;
      }

      /* On mouse-over, add a grey background color */
      .time-checkbox-container:hover input ~ .checkmark {
        background-color: #fafafa;
      }

      /* When the checkbox is checked, add a blue background */
      .time-checkbox-container input:checked ~ .checkmark {
        background-color: #34b9b9;
      }

      /* Create the checkmark/indicator (hidden when not checked) */
      .checkmark:after {
        content: "";
        position: absolute;
        display: none;
      }

      /* Show the checkmark when checked */
      .time-checkbox-container input:checked ~ .checkmark:after {
        display: block;
      }

      /* Style the checkmark/indicator */
      .time-checkbox-container .checkmark:after {
        left: 9px;
        top: 3px;
        width: 7px;
        height: 14px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }
}


html {
  &.homepage.no-webp {
    body {
      section.homepage-jumbo.mobile {
        //todo
        /*
                background-image: url("../src/assets/zoofy/images/Zoofy-klus-hulp-mobile.jpg");
                */
        background-size: cover;
        background-position: center;
      }
    }
  }

  &.homepage.webp {
    body {
      section.homepage-jumbo.mobile {
        //todo
        /*                background-image: url("../src/assets/zoofy/images/Zoofy-klus-hulp-mobile.webp");*/
        background-size: cover;
        background-position: center;
      }
    }
  }
}

/* Bootstrap table styling */
table {
  width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
  border-spacing: 2px;
  border-collapse: collapse;

  tr:first-child {
    background-color: rgba(0, 0, 0, .075);
  }

  tr:hover {
    background-color: rgba(0, 0, 0, .075);
  }

  td, th {
    padding: .75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
  }
}

/* Custom partner page styling */
section.partners.page-overwrite {
  background: transparent;
  padding: 0;
  border-top: none;
}

section.partners.page-overwrite h3 {
  text-align: left;
}

.navbar {
  padding: 0;

  .nav-title {
    font-size: 1.25rem;
    font-weight: 500;

    &.nav-padding {
      padding: 0 1.5rem;
    }
  }

  &.bg-yellow {
    background: $color-primary;
  }

  .nav-item {
    float: right;
    font-weight: 600;
    padding: .5rem .5rem;
    font-size: 15px;

    &.mobile {
      display: none;

      a.categories {
        display: block;
        color: #34b9b9;
        padding: 0.2rem 0;
        font-weight: normal;
        vertical-align: middle;
        line-height: 2.5rem;
        font-size: 1rem;
      }

      a.categories .zi {
        display: inline-block;
        width: 30px;
      }

      a.categories.witgoedmonteur {
        position: relative;
        left: -4px;

        .z-witgoedmonteur {

          &:before {
            padding-right: 0;
            font-size: 20px;
            position: relative;
            top: 3px;
          }
        }
      }

      a.categories.ongedierte {
        position: relative;
        left: -4px;

        .z-ongedierte {

          &:before {
            padding-right: 0;
            font-size: 20px;
            position: relative;
            top: 3px;
          }
        }
      }

      a.categories.computer {
        position: relative;
        left: -4px;

        .z-computer {

          &:before {
            padding-right: 0;
            font-size: 20px;
            position: relative;
            top: 3px;
          }
        }
      }
    }

    &.dropleft {
      .dropdown-menu {
        top: 15px;
        right: 95%;
      }
    }

    &.dropdown.multi-level-dropdown {

      .dropdown-submenu {
        position: relative;

        &:active {
          background-color: #ccc;
        }

        .dropdown-menu {

          .dropdown-item {

            &:active {
              background-color: #ccc;
            }

            a {
              display: inline-block;
              width: 100%;
              height: 100%;
              color: #212529;

              &:active {
                background-color: #ccc;
              }
            }
          }
        }

        a.dropdown-toggle {
          display: inline-block;
          width: 100%;
          height: 100%;
          color: #212529;
        }

        a:hover {
          text-decoration: none;
        }
      }

      .dropdown-submenu > .dropdown-menu {
        top: 0;
        left: 100%;
        margin-top: -6px;
        margin-left: -1px;
        -webkit-border-radius: 0 6px 6px 6px;
        -moz-border-radius: 0 6px 6px;
        border-radius: 0 6px 6px 6px;
      }

      .dropdown-submenu:hover > .dropdown-menu {
        display: block;
      }

      .dropdown-submenu > a:after {
        display: block;
        content: " ";
        float: right;
        width: 0;
        height: 0;
        border-color: transparent;
        border-style: solid;
        border-width: 5px 0 5px 5px;
        border-left-color: #212529;
        margin-top: 5px;
        margin-right: -10px;
      }

      .dropdown-submenu:hover > a:after {
        border-left-color: #000;
      }

      .dropdown-submenu.pull-left {
        float: none;
      }

      .dropdown-submenu.pull-left > .dropdown-menu {
        left: 100%;
        margin-left: 10px;
        -webkit-border-radius: 6px 0 6px 6px;
        -moz-border-radius: 6px 0 6px 6px;
        border-radius: 6px 0 6px 6px;
      }
    }
  }

  .nav-item.pro {
    div {
      display: none;
    }
  }

  .zoofy-menu-items.partner {
    div {
      display: none;
    }
  }

  .language {
    .nav-title {
      display: none;
    }
  }

  .btn-pro {
    background: $color-secondary;
    color: $color-white !important;
    font-weight: bold;
    font-size: .75rem;
    padding: .3rem .6rem;
    margin-top: .25rem;
    border: 1px solid $color-secondary-hover;

    &:hover {
      background: $color-secondary-hover;
    }
  }

  .navbar-brand {
    z-index: 5;

    .nav-logo-subtitle {
      font-size: 0.9rem;
    }
  }

  .navbar-brand img {
    width: 65px;
  }

  #navbarDropdown {
    color: $font-primary-color;
  }

  #navbarSupportedContent {
    .dropdown-menu.show {
      padding: .75rem .5rem;

      a.dropdown-item:active {
        background: none;
      }
    }
  }

  .dropdown-menu .dropdown-item {
    padding-left: 1rem;

    img.flag {
      max-width: 20px;
    }
  }

  .dropdown-item.categories {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #34b9b9;

    .nav-icon {
      min-width: 1rem;
      height: 20px;
      width: 20px;
      margin: 0 !important;

      &:before {
        font-size: 15px;
        color: #34b9b9;
      }
    }

    &.witgoedmonteur {
      .z-witgoedmonteur {
        position: relative;
        right: 5px;
        bottom: 2px;

        &:before {
          padding-right: 0;
          font-size: 20px;
          position: relative;
          top: 3px;
        }
      }
    }

    &.ongedierte {
      .z-ongedierte {
        position: relative;
        right: 5px;
        bottom: 2px;

        &:before {
          padding-right: 0;
          font-size: 20px;
          position: relative;
          top: 3px;
        }
      }
    }

    &.computer {
      .z-computer {
        position: relative;
        right: 5px;
        bottom: 2px;

        &:before {
          padding-right: 0;
          font-size: 20px;
          position: relative;
          top: 3px;
        }
      }
    }
  }

  .popular-task-container.nav-item.mobile {
    .task-link {
      text-decoration: none;
      font-weight: 400;
      padding-left: 20px;
      color: #34b9b9;
    }
  }
}


footer {
  background-color: $footer-background-color;
  padding: 2rem 0;
  margin-top: 3px;

  nav {
    ol.breadcrumb {
      margin: 0;
      background: none;
      padding: 0;

      li.breadcrumb-item.active {
        color: $breadcrumb-text-color
      }

      li.breadcrumb-item a {
        color: $breadcrumb-icon-color;
      }
    }

    ul li {
      display: inline-block;
      padding-right: 2rem;
    }
  }

  h4 {
    color: $footer-heading-color;
    margin-bottom: 1rem;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      font-family: $font-primary;
      line-height: 2rem;
    }
  }

  .footer-link {
    ul {
      li {
        a {
          color: $footer-link-color;
        }
      }
    }

    .app-store-container {
      .title {
        font-weight: 500;
      }

      .app-store-image {
        width: 175px;
        padding: 1px 0px;
      }
    }
  }

  &.company {
    background: $footer-company-background;
    font-size: 15px;

    .copyright {
      float: right;
    }

    .footer-link {
      ul {
        li {
          color: $footer-company-color;
        }
      }
    }

    .powered {
      img {
        display: inline-block;
        width: 50px;
        margin-left: 3px;
      }

      a {
        text-decoration: none;
        color: $color-555;
      }
    }
  }

  a.all-locations {
    color: $color-secondary;
    font-weight: bold;
  }

  &.seo {
    background: $seo-location-background-color;

    h4 {
      color: $seo-location-heading-color;
    }

    .footer-link a {
      color: $seo-location-link-color;
    }
  }

  &.seo .col-links {
    column-count: 4;
    column-gap: 20px;

    a {
      display: block;
    }
  }
}

.card {
  border: none;
  box-shadow: 0 1px 3px $color-black-opacity-20;
}

#category-image {
  max-width: 33%;
}

#task-image {
  max-width: 90%;
  margin-top: 4rem;
}

#top-search {
  display: none;
  background: none;
  border: none;

  &:focus {
    outline: none;
  }
}

.typeahead__empty {
  padding: .25rem 1rem;
}

section {
  font-family: $font-primary;

  a {
    color: $color-secondary;

    &:hover {
      color: $color-secondary;
    }
  }

  &.jumbo {
    background: $jumbo-background-color;
    min-height: calc(420px + 3vw);
    position: relative;

    .search-form {
      max-width: 700px;
      margin: auto;
      padding-top: 3rem;

      h1 {
        font-family: $font-primary;
        font-size: 40px;
        font-weight: bold;
        color: $jumbo-headingtext-color;
      }

      .example {
        color: scale-color($jumbo-leadtext-color, $lightness: -20%);
        text-align: left;
        margin-left: 3rem;
      }

      .input-group {
        input {
          @extend %extend_1;

          &.text {
            height: 70px;
            border: none;
            font-size: 1.25rem;
            outline: none;
            border: none !important;
            box-shadow: none !important;
            padding-left: 0;
          }

          &.text::placeholder {
            font-size: 1rem;
          }

          &.house-number {
            max-width: 140px;
            margin-left: 3px;
            padding-left: 1rem;
          }
        }

        .btn {
          border-top-right-radius: 9px;
          border-bottom-right-radius: 9px;
          padding: 1rem 2rem;
          font-size: 1.5rem;
          font-weight: 500;
          background-color: $color-secondary;
          border: none;
          display: flex;
          align-items: center;

          .spinner {
            display: none;
          }

          &.searching {
            span {
              display: none;
            }

            i {
              display: none;
            }

            .spinner {
              display: block;
              margin-top: -5px;

              img {
                width: 25px;
              }
            }
          }

          &:hover {
            background-color: $color-secondary-hover;
          }

          > i {
            margin-right: .5rem;
          }
        }
      }

      button {
        @extend %extend_1;
      }

      .input-group-prepend {
        border-right: 1px solid $color-black;

        .input-group-text {
          background: $color-white;
          border: none;
          border-top-left-radius: 9px;
          border-bottom-left-radius: 9px;
          padding: 1.5rem 1.5rem 1.5rem 1.75rem;
        }

        .no-border-radius {
          border-radius: 0;
        }
      }

      p.lead {
        color: $jumbo-leadtext-color;
        margin-bottom: 2rem;
        font-size: 1.3rem;
      }

      .score {
        a {
          color: scale-color($jumbo-leadtext-color, $lightness: -10%);
          //darken( $jumbo-leadtext-color, 20% )
        }
      }
    }

    .js-typeahead {
      width: calc(25px + 25vw);
      margin: 0 auto;

      &:focus {
        border: 0;
        outline: none;
      }
    }

    .typeahead__result {
      position: absolute;
      background: $color-white;
      padding: 0;
      z-index: 1;
      width: 100%;
      box-shadow: 0 2px 4px $color-black-opacity-20;

      > ul > li > a {
        text-decoration: none;
        padding: 0 0 0 1rem;
        margin: 0;
        width: 100%;
        display: block;

        &:hover {
          background: $color-secondary;
          color: $color-white;

          .typeahead__display {
            color: $color-white;
          }
        }
      }
    }

    .typeahead__container {
      position: relative;
    }

    .typeahead__list {
      padding: 0;
      width: 100%;
      list-style: none;
      margin-bottom: 0;

      .alert.alert-warning {
        display: block;
        line-height: 1.5rem;
        margin: 0;
      }
    }

    .typeahead__item {
      line-height: 2rem;
    }

    .typeahead__display {
      color: $color-black;
      font-size: calc(10px + 0.5vw);
    }

    .bootstrap-select {

      .dropdown-menu {
        top: -70px !important;
        border: none;
        border-radius: 0;
      }

      .dropdown-toggle {
        .filter-option {
          display: flex;
          background: $color-white;
          font-family: $font-primary;
          align-items: center;
          padding-left: .5rem;
        }

        .filter-option-inner-inner {
          font-size: 1.15rem;
          font-weight: 400;
        }
      }
    }
  }

  .houses {
    position: absolute;
    bottom: 0;
    left: 0;
    display: none;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    max-width: 380px;
  }

  .category-image {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    max-width: 95%;
  }

  &.usps {
    background: $usp-background-color;

    .usp {
      padding: 2rem 0;

      > div {
        display: inline-block;
        max-width: 80%;

        > h3 {
          color: $usp-title-color;
          font-size: 18px;
          line-height: 1rem;
          margin-top: .5rem;
        }

        > p {
          color: $usp-subtitle-color;
          font-size: 12px;
          margin: 0;
        }
      }
    }

    img {
      display: inline-block;
      vertical-align: top;
      max-width: 50px;
      max-height: 50px;
      margin-right: 5px;
    }
  }

  &.professionals {
    background: $professional-background-color;
    padding-top: 3rem;
    padding-bottom: 3rem;

    .professional-category-title {
      color: $professional-heading-color;
      font-size: 26px;
      margin-bottom: 2rem;
      font-weight: 500;
    }

    .col-pro {
      margin-bottom: 2rem;
    }

    .pro {
      display: block;
      width: 100%;
      padding-bottom: 100%;
      position: relative;
      background: $professional-button-background-color;
      cursor: pointer;
      border-radius: 5px;

      .content {
        position: absolute;
        width: 100%;
        height: 100%;
        text-align: center;
        padding-top: 25%;

        .zi {
          color: $professional-button-icon-color;
          font-size: 50px;
          display: block;
          margin-bottom: 1rem;
        }

        .label {
          display: block;
          color: $professional-category-text-color;
          font-size: calc(10px + 0.3vw);
          font-weight: 500;
        }
      }

      &:hover {
        background: $professional-button-background-hover-color;
        color: $professional-category-text-hover-color;

        .zi {
          color: $professional-button-icon-hover-color;
        }

        .label {
          color: $professional-category-text-hover-color;
        }
      }
    }
  }

  &.how {
    background: #f4f4f4;
    padding-top: 5rem;
    padding-bottom: 2rem;
    margin-top: -1rem;
    text-align: center;

    .explanation {
      min-height: 80px;

      h4 {
        color: $how-title-color;
        font-size: 18px;
      }

      p {
        font-size: 16px;
        color: $how-subtitle-color;
      }
    }

    h2 {
      color: $how-heading-color;
      margin: 1rem 0 3rem;
    }

    .col-how {
      text-align: center;

      img {
        max-width: 90%;
        max-height: 75px;
        margin-bottom: 3rem;
      }
    }

    #task-picker {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
    }

    .search-form {
      button {
        @extend %extend_2;
      }

      .input-group input {
        @extend %extend_2;
      }
    }

    .input-group-text {
      background-color: $color-white;
    }

    .js-typeahead {
      width: 100%;
      margin: 0 auto;
      font-family: $font-primary;
      height: 40px;
      border-left: none;
      border-top: 1px solid $color-ccc;
      border-bottom: 1px solid $color-ccc;
      font-size: calc(8px + 0.5vw);

      &:focus {
        outline: none;
      }
    }

    .typeahead__result {
      position: absolute;
      background: $color-white;
      padding: 0;
      left: -40px;
      z-index: 1;
      width: 130%;
      box-shadow: 0 2px 4px $color-black-opacity-20;

      > ul > li > a {
        text-decoration: none;
        padding: 0 0 0 1rem;
        text-align: left;
        margin: 0;
        width: 100%;
        display: block;

        &:hover {
          background: $color-secondary;
          color: $color-white;

          .typeahead__display {
            color: $color-white;
          }
        }
      }
    }

    .typeahead__container {
      position: relative;
    }

    .typeahead__list {
      padding: 0;
      width: 100%;
      list-style: none;
      margin-bottom: .5rem;
    }

    .typeahead__item {
      line-height: 2rem;
    }

    .typeahead__display {
      color: $color-black;
      font-size: calc(10px + 0.5vw);
    }

    #button-addon2 {
      background-color: $color-secondary;
      border-color: $color-secondary;

      > img {
        margin-bottom: 0;
      }
    }
  }

  &.partners {
    padding: 3rem 0;
    border-top: 1px solid #f5f5f5;
    background-color: #f5f5f5;

    h3 {
      text-align: center;
    }

    .media {
      padding: 1rem;
      align-items: center;
      justify-content: center;
      min-height: 120px;

      img {
        max-width: 140px;
        max-height: 120px;
        width: 100%;
        height: auto;
        margin: auto;
      }
    }
  }

  &.publicity {
    padding: 3rem 0;
    border-top: none;

    h3 {
      text-align: center;
    }

    .media {
      padding: 1rem;
      align-items: center;
      justify-content: center;
      min-height: 120px;

      img {
        max-width: 100px;
        margin: auto;
      }
    }
  }

  &.reviews {
    background: $reviews-background-color;
    padding: 3rem 0;

    h3 {
      color: $reviews-heading-color;
    }

    p.lead {
      color: $reviews-lead-color;
      margin-bottom: 3rem;
    }

    .review span.quote > i {
      vertical-align: top;
      margin-right: .5rem;
    }

    .score {
      display: inline-block;
      font-size: 16px;
      position: relative;
      overflow: hidden;
      line-height: 0;
      vertical-align: middle;
      z-index: 0;

      .stars-active {
        color: $color-stars-active;
        position: relative;
        z-index: 10;
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
      }

      .stars-inactive {
        color: $color-ccc;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  &.map {
    background: $map-background-color;
    padding-top: 0;
    min-height: 80vh;

    h3 {
      color: $map-heading-color;
      margin-bottom: 2rem;
    }

    #map {
      height: 80vh;
    }
  }

  &.seo {
    background: $seo-category-background-color;
    padding: 3rem 0;
    overflow: hidden;

    .content {
      column-count: 2;
      column-gap: 20px;

      img {
        max-width: 100% !important;
        max-height: 100% !important;
        height: auto !important;
      }
    }

    h2 {
      color: $seo-category-heading-color;
      font-size: 26px;
      margin-bottom: 1.5rem;
    }

    h3 {
      font-size: 22px;
    }

    p {
      font-size: 15px;
      color: $seo-category-content-color;
      line-height: 1.75rem;
      margin-bottom: 2rem;
    }

    li {
      color: #555;
    }
  }

  &.category {
    background-color: #E6F2F7;
    padding: 3rem 0 6rem 0;

    .col-task {
      padding-right: 0;

      .task {
        background: $task-background;
        color: $task-title-color;
        font-size: 14px;
        font-weight: 500;
        padding: 1rem 0 1rem 1.5rem;
        margin-bottom: 15px;
        border-left: 4px solid $task-border-color;
        cursor: pointer;
        display: block;

        .from-price {
          color: $task-price-color;
          display: block;
        }

        &:hover {
          background: $task-background-hover;
          color: $task-title-hover-color;
          border-color: $task-border-hover-color;
          text-decoration: none;

          .from-price {
            color: $task-price-hover-color;
          }
        }
      }
    }

    .all-jobs-title {
      font-size: 1.25rem;
      font-weight: 500;
    }

    h3 {
      color: $category-heading-color;
      margin-bottom: 1rem;
    }

    h5 {
      color: $category-title-color;
      margin: 3rem 0 1rem;

      a {
        font-size: 14px;
      }
    }

    p {
      color: $category-lead-color;
      font-size: 14px;
      line-height: 1.75rem;
      margin-bottom: 2rem;
    }

    section.tasks {

      a {
        background: white;
        border-radius: 8px;
        margin-bottom: 1rem;
        width: 32%;
        border: 1px solid #fff;
        cursor: pointer;
        transition: all .25s ease;
        text-decoration: none;

        &:hover {
          border: 1px solid #34B9B9;
        }

        .other-task {
          background: white;
          border-radius: 8px;
          padding: 0.7rem 1rem;
          width: 100%;
          border: 1px solid #fff;
          cursor: pointer;
          transition: all .25s ease;

          .other-task-title {
            font-size: 1.2rem;
            font-weight: 700;
            color: black;
          }

          .review-container {
            padding-bottom: 0.7rem;

            .stars {
              display: inline-block;
              font-size: 16px;
              position: relative;
              overflow: hidden;
              line-height: 0;
              vertical-align: middle;
              z-index: 0;

              .stars-active {
                color: $color-stars-active;
                position: relative;
                z-index: 10;
                display: inline-block;
                overflow: hidden;
                white-space: nowrap;
              }

              .stars-inactive {
                color: $color-ccc;
                position: absolute;
                top: 0;
                left: 0;
              }
            }

            .review-text {
              color: #777;
              font-size: 0.8rem;
            }
          }

          .from-price {
            color: #34B9B9;
            font-weight: 700;
            font-size: 1.1rem;
          }

          .in-area {
            color: #777;
            font-size: 0.75rem;
          }
        }
      }

      #show-more-task-button {
        background: #fff;
        border-radius: 8px;
        padding: .7rem 0;
        color: #34b9b9;
        border: none;
        width: 32%;
        font-size: 1.2rem;
        font-weight: 700;
      }
    }
  }


  &.tasks {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background: #E6F2F7;
    padding: 1rem 0;

    .title {
      font-size: 1.4rem;
      padding-left: 5px;
      margin-bottom: 0.5rem;
      font-weight: 700;
    }

    .other-task {
      background: white;
      border-radius: 8px;
      padding: 0.7rem 1rem;
      width: 32%;
      border: 1px solid #fff;
      cursor: pointer;
      transition: all .25s ease;

      &:hover {
        border: 1px solid #34B9B9;
      }

      .other-task-title {
        font-size: 1.2rem;
        font-weight: 700;
      }

      .review-container {
        padding-bottom: 0.7rem;

        .stars {
          color: #FEC600;
          font-size: 0.8rem;
        }

        .review-text {
          color: #777;
          font-size: 0.8rem;
        }
      }

      .from-price {
        color: #34B9B9;
        font-weight: 700;
        font-size: 1.1rem;
      }

      .in-area {
        color: #777;
        font-size: 0.75rem;
      }
    }
  }

  &.comments {
    padding: 4rem 0;

    h4 {
      text-align: center;
      margin: 0 0 3rem 0;
    }

    .card {
      border-radius: 10px;
      background: $color-off-white;
      border-color: $color-ddd;

      .card-title {
        margin: 0;
      }
    }

    .user {
      display: flex;
      flex-direction: column;
      float: left;
    }

    .date {
      font-size: .8rem;
      color: $color-999;
      line-height: 1.25rem;
    }

    .row.message {
      margin-bottom: 2rem;
    }

    .reply .user {
      float: right;
    }
  }

  &.confirm-details {
    background: $bgcolor-primary;
    padding: 4rem 0 1rem;
    min-height: 460px;
    position: relative;

    .container {
      /*width: 600px;*/
      margin: 25px auto;
    }

    .page-title {
      padding-top: 4rem;

      .row {
        width: 100%;
      }
    }

    .job-id-title {
      text-align: center;
      font-size: 2rem;
      margin-bottom: 2rem;

      span {
        font-weight: 700;
      }
    }

    .progress-container {
      margin-left: -2.5rem;
    }

    .progress {
      width: 80vw;
      margin: 0px auto;
    }

    .progressbar {
      width: 80vw;
      margin: 0px auto;
    }

    .progressbar li {
      list-style-type: none;
      width: 25%;
      float: left;
      font-size: 12px;
      position: relative;
      text-align: center;
      color: $color-black;

      .bold-text {
        font-weight: 700;
      }
    }

    .progressbar li:before {
      width: 18px;
      height: 18px;
      font-family: "Font Awesome 5 Free";
      font-size: 0.5rem;
      font-weight: 900;
      content: "\f00c";
      color: $color-grey;
      line-height: 18px;
      border: 1px solid $color-grey;
      display: block;
      text-align: center;
      margin: 0 auto 10px auto;
      border-radius: 50%;
      background-color: white;
    }

    .progressbar li.active:before {
      width: 18px;
      height: 18px;
      font-family: "Font Awesome 5 Free";
      font-size: 0.5rem;
      font-weight: 900;
      content: "\f00c";
      color: $color-white;
      line-height: 18px;
      border: 1px solid $color-grey;
      display: block;
      text-align: center;
      margin: 0 auto 10px auto;
      border-radius: 50%;
      background-color: #fed900;
    }

    .progressbar li:after {
      width: 87%;
      height: 2px;
      content: '';
      position: absolute;
      background-color: $color-grey;
      top: 8px;
      left: -44%;
      /*z-index: -1;*/
    }

    .progressbar li:first-child:after {
      content: none;
    }

    .progressbar li.active {
      color: $color-black;
    }

    .progressbar li.active:before {
      border-color: $color-primary;
    }

    .progressbar li.active + li:after {
      background-color: $color-primary;
    }

    .progress-icons {
      background-color: transparent;
      height: 3rem;

      i {
        color: $color-secondary;
        font-size: 2rem;
        width: 25%;
        text-align: center
      }

      .step1 {
        margin-left: 2.5rem;
      }
    }

    .score-wrap {
      display: inline-block;
      position: relative;
      height: 19px;
      vertical-align: middle;
      line-height: 1rem;
      text-align: left;

      .stars-active {
        color: $color-stars-active;
        position: relative;
        z-index: 10;
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
      }

      .stars-inactive {
        color: $color-ccc;
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    .headings {
      text-align: center;
      margin-top: 2rem;
      margin-bottom: 3rem;
    }

    .score {
      color: $color-999;
    }

    .alert {
      margin: 1.25rem 0;
    }

    .job-details {
      background: #fff;
      padding: 2rem;
      box-shadow: 2px 2px 5px 2px #ccc;

      h4 {
        margin-bottom: 2rem;
        font-weight: bold;

        span {
          font-weight: normal;
        }
      }

      h5 {
        font-weight: bold;
      }

      .email-confirmed-icon {
        i {
          color: $color-secondary;
        }
      }

      .timeslots {
        padding-top: 1rem;
      }

      .timeslot {
        background: #F4F4F4;
        padding: .7rem 1rem;

        .day-part {
          font-size: .9rem;
          color: #34B9B9;
          display: inline-block;
          padding-right: 8px;
          margin-right: 8px;
          line-height: 1.7rem;
          height: 45px;

          .name {
            font-weight: bold;
            font-size: 14px;
          }

          .time {
            font-size: .7rem;
            position: relative;
            bottom: 10px;
            left: 2px;
            color: #212529;
          }

          .rate {
            display: block;
            font-size: .9rem;
            position: relative;
            bottom: 18px;
            left: 2px;
            color: #333;
            font-weight: bold;
          }
        }

        &.picked-option {
          .picked-option-title {
            font-weight: 700;
            padding-bottom: 1rem;
          }

          .time {
            bottom: 0;
          }

          .rate {
            bottom: 0;
          }
        }
      }

      .comments {
        padding-top: 2rem;

        .comment {
          background: $color-white;
          border: 1px solid $bgcolor-primary;
          padding: .7rem 1rem;
        }
      }

      .col-ts {
        padding-top: .5rem;
      }

      .jobId a {
        font-size: 14px;
        font-weight: bold;
        margin-left: .3rem;
      }

      .inactive .timeslot {
        opacity: 0.25;

        i {
          display: none;
        }
      }

      .active .timeslot {
        color: $timeslot-active-color;
        background-color: $timeslot-active-background;
        border-color: $timeslot-active-border;
      }

      .cancelled .timeslot {
        color: $timeslot-cancelled-color;
        background-color: $timeslot-cancelled-background;
        border-color: $timeslot-cancelled-border;
      }
    }

    &.error {
      .alert {
        margin: 2rem 0;
      }

      p {
        margin: 2rem 0;
      }
    }

    .review .score-wrap {
      display: inline-block;
      font-size: 16px;
      position: relative;
      overflow: hidden;
      line-height: 0;
      vertical-align: middle;
    }

    p.lead {
      font-size: 1rem;
      color: $color-555;
    }

    .cancel-rebook {
      background: #F1F8FA;
      padding: 2rem;

      p {
        color: #888888;
        font-size: 0.8rem;
      }

      a {
        font-weight: 700;
      }
    }

    .questions {
      padding: 1rem;
    }

    &.cancelled-appointment {
      padding: 2rem 0 1px;

      .job-details {
        max-width: 60vw;
        margin: 0 auto;
      }
    }

    &.cancel-page {
      padding: 1rem;
    }

    &.status {
      .partners {
        display: none;
        background: #F1F8FA;
        padding-top: 2rem;

        .container {
          .row {
            .title {
              h3 {
                text-align: center;
              }
            }
          }
        }
      }

      #mc_embed_signup {
        display: none;
        width: unset;
        padding: 2rem 2rem;
        margin: -2rem 0;
        background: #F1F8FA;
      }
    }

    .professional-info.mobile {
      display: none;
    }

    section.faq {
      margin-top: -2rem;
    }

    .btn-back-button {
      background: #FFA500;
      color: white;
    }

    .btn-back-button:hover {
      background-color: #f09b00;
      border-color: #f09b00;
    }
  }

  &.confirm-details.status {
    background: #F9F9F9;
    padding: 4rem 0 1rem;
    min-height: 460px;
    position: relative;

    .container {
      /*width: 600px;*/
      margin: 25px auto;
      padding: 0;

      .col-status {
        .alert-warning, .alert-danger {
          display: flex;
          flex-direction: row;
          align-items: center;
          border: 1px solid #FED900;
          border-radius: 20px;
          background-color: #FFFBE5;
          margin: 0;
          margin-bottom: 1rem;
          height: auto;

          .alert-img {
            height: 90px;
            width: 90px;
            border-radius: 50%;
          }

          .alert-text {
            padding: 0 2rem;

            .alert-title {
              color: #333;
              font-size: 20px;
              margin-bottom: 0;
              font-weight: 500;
            }

            .alert-description {
              font-size: 15px;
              margin-top: 6px;
              color: #666;
            }
          }

          .rebook-button {
            color: white;
            padding: 0.7rem 1rem;
            background-color: #34b9b9;
            border-radius: 5px;
            font-weight: 500;
            margin-right: 1rem;
            margin-top: 1rem;

            &:hover {
              background: #2a9c9c;
            }
          }
        }
      }

      .cancel-detail {
        padding-bottom: 5rem;

        #no-rebook-cancel-link {
          display: none;
        }
      }
    }

    #rebook-over-cancel {
      display: none;
    }

    .status-body {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0;
      margin-top: 3rem;

      &.mobile {
        display: none;

        .cancel-detail {
          padding-bottom: 5rem;

          #no-rebook-cancel-link-mob {
            display: none;
            padding-left: 1rem;
          }
        }

        #rebook-over-cancel-mob {
          display: none;
          width: 85vw;
        }
      }

      .job-info {
        background: white;
        width: 75%;
        padding: 1.5rem 1.5rem;
        border-radius: 10px;
        margin-right: 20px;

        .heading {
          display: flex;
          flex-direction: row;
          margin-bottom: 3rem;

          .map {
            height: 120px;
            border-radius: 50%;
          }

          .info-container {
            display: flex;
            flex-direction: column;
            padding-left: 1.5rem;

            .job-title {
              color: #323C47;
              font-weight: 500;
              font-size: 22px;
            }

            .job-address {
              color: #999;
              font-size: 1.2rem;
              margin-bottom: 1rem;
            }

            .status {
              color: #777;
              margin-bottom: 0.5rem;
            }
          }
        }

        #image-dropzone {
          border: 1px dashed #d8d7d9;

          .detail.dropzone {
            width: 100%;

            .value.dropzone {
              margin: 0 auto;
              cursor: pointer;

              .uploadImageButton {
                border: 2px solid #34b9b9;
                padding: 8px;
                border-radius: 10px;
                background: white;
                color: #34b9b9;
                font-weight: 700;
                font-size: 14px;
                cursor: pointer;
              }

              .uploadImageButton:hover {
                border: 2px solid #34b9b9;
                padding: 8px;
                border-radius: 10px;
                background: #34b9b9;
                color: white;
                font-size: 14px;
              }

              #customerImage::-webkit-file-upload-button {
                visibility: hidden;
              }

              #customerImage::before {
                content: 'Select some files';
                display: inline-block;
                color: #34B9B9;
                outline: none;
                white-space: nowrap;
                -webkit-user-select: none;
                cursor: pointer;
                text-shadow: 1px 1px #fff;
                font-weight: 700;
                font-size: 10pt;
              }

              #customerImage:hover::before {
                border-color: black;
              }

              #customerImage:active::before {
                background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
              }
            }
          }
        }

        #image-dropzone:hover {
          border: 1px dashed #34B9B9;
        }

        .thumbnails {
          img {
            width: 80px;
            margin-right: 10px;
            margin-top: 10px;
          }

          #upload-spinner {
            width: 30px;
            position: relative;
            right: 65px;
          }
        }

        .modal, .modalMob {
          display: none;
          position: fixed;
          z-index: 1;
          padding-top: 100px;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          overflow: auto;
          background-color: rgba(0, 0, 0, 0.75);
        }

        .modal-content {
          position: relative;
          background-color: black;
          margin: auto;
          padding: 0;
          width: 90%;
          max-width: 1200px;
        }

        .close {
          color: white;
          position: absolute;
          top: 10px;
          right: 25px;
          font-size: 35px;
          font-weight: bold;
        }

        .close:hover,
        .close:focus {
          color: #999;
          text-decoration: none;
          cursor: pointer;
        }

        .mySlides, .mySlidesMob {
          margin: 0 auto;

          img {
            width: unset;
            max-height: 73vh;
            max-width: 80vw;
            margin: 0;
          }
        }

        .prev,
        .next {
          cursor: pointer;
          position: absolute;
          top: 50%;
          width: auto;
          padding: 16px;
          margin-top: -50px;
          color: white;
          font-weight: bold;
          font-size: 20px;
          transition: 0.6s ease;
          border-radius: 0 3px 3px 0;
          user-select: none;
          -webkit-user-select: none;
        }

        .next {
          right: 0;
          border-radius: 3px 0 0 3px;
        }

        .prev:hover,
        .next:hover {
          background-color: rgba(0, 0, 0, 0.8);
        }

        img.demo {
          opacity: 0.6;
        }

        .active,
        .demo:hover {
          opacity: 1;
        }

        img.hover-shadow {
          transition: 0.3s;
        }

        .hover-shadow:hover {
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        }

        .add-coupon {
          margin-top: 2rem;
          color: #34b9b9;
          cursor: pointer;
        }

        .add-coupon:hover {
          text-decoration: underline;
        }

        .row {
          &.coupon {
            /*padding: 1.5rem 1.75rem 1.5rem 1.75rem;
                width: 100%;*/
            .input {
              padding: 0;
              margin-top: 2rem;

              &.desktop {
                display: none;
              }

              .coupon-container {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 1rem;

                .form-field {
                  margin-top: 0;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  width: 100%;

                  .form-field__control {
                    border-bottom-right-radius: 0 !important;
                    border-top-right-radius: 0 !important;
                  }

                  #coupon-input-button, #coupon-input-button-mob {
                    padding: 14px 30px !important;
                    padding-bottom: 15px !important;
                    border: 1px solid #ccc;
                    border-left: none;
                    margin-top: 0;
                    border-radius: 7px;
                    border-top-left-radius: 0px !important;
                    border-bottom-left-radius: 0px !important;
                    border: 1px solid #aaa;
                    background: #aaa;
                    border-color: #aaa;
                    color: white;
                    text-transform: uppercase;
                    cursor: pointer;
                    font-size: 1rem;
                    font-weight: 700;

                    &:hover {
                      background-color: #999;
                      border-color: #999;
                    }
                  }

                  #coupon-input-button.remove, #coupon-input-button-mob.remove {
                    display: none;
                    padding: 14px 30px !important;
                    padding-bottom: 15px !important;
                    border: 1px solid #ccc;
                    border-left: none;
                    margin-top: 0;
                    border-radius: 7px;
                    border-top-left-radius: 0px !important;
                    border-bottom-left-radius: 0px !important;
                    border-left: 1px solid #ccc;
                    background: black;
                    border-color: black;
                    color: white;
                    text-transform: uppercase;
                    cursor: pointer;
                    font-size: 1rem;
                    font-weight: 700;

                    &:hover {
                      background-color: black;
                      border-color: black;
                    }
                  }

                  .form-field__control {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                  }
                }

                input {
                  padding: 15px 10px !important;
                  width: 100%;
                  margin-top: 0;
                  border-right: none;
                  border-top-right-radius: 0px !important;
                  border-bottom-right-radius: 0px !important;
                  border-top-left-radius: 5px;
                  border-bottom-left-radius: 5px;
                  border: 1px solid #ccc;
                }
              }
            }

            .alert.alert-success.success-label {
              display: none;
            }

            .alert.alert-danger.danger-label {
              display: none;
            }
          }
        }

        .appointment-details {
          display: flex;
          flex-direction: row;
          padding: 1rem 0.5rem;

          &.cancel {
            padding-left: 0;
          }

          .detail {
            display: flex;
            flex-direction: column;
            width: 55%;

            .title {
              color: #989DA3;
              font-size: 0.8rem;
              font-weight: 500;
            }

            .value {
              color: #323C47;
              font-size: 1rem;
            }

            .cancel-text {
            }

            .cancel-text:hover {
              color: #34b9b9;
            }
          }

          .detail:nth-child(1) {
            width: 30%;
          }

          .cancel-subtext {
            font-size: 0.9rem;
            color: #989da3;
          }

          .book-again {
            background: #FFA500;
            border-color: #FFA500;
            color: white;
            width: 100%;
            margin-top: 6rem;

            &:hover {
              background: #F09B00;
            }
          }
        }

        .appointment-description {
          padding: 1rem 0.5rem;

          .main-title {
            color: #212529;
            font-size: 1rem;
          }

          .title {
            color: #989DA3;
            font-size: 0.8rem;
            font-weight: 500;
          }

          .value {
            color: #323C47;
            font-size: 1rem;
          }

          .padding {
            padding-bottom: 1rem;
          }
        }
      }

      .professional-info.desktop {
        width: 25%;
      }

      .time-info {
        width: 100%;
        padding: 1.5rem 1.5rem;

        .title {
          color: #323C47;
          font-weight: 500;
          font-size: 1.5rem;
          margin-bottom: 2rem;
        }

        .day-container {
          margin-bottom: 2rem;

          .date-text {
            font-size: 0.8rem;
            font-weight: 500;
            color: #999;
            margin-bottom: 0.5rem;
            text-transform: uppercase;
          }

          .option-time {
            color: #323C47;
            font-size: 0.9rem;
            margin-bottom: 0.5rem;
          }

          .option-time.cancelled {
            text-decoration: line-through;
          }
        }

        .calendar-container {
          text-align: center;

          .calendar-title {
            font-size: 0.87rem;
            font-weight: 500;
          }

          .calendar-button-container {
            display: flex;
            justify-content: space-evenly;

            .calendar-button {
              background: #34b9b9;
              color: #fff;
              padding: 3px 10px;
              font-size: 0.87rem;
              border-radius: 3px;

              &:hover {
                text-decoration: none;
                background: #2a9c9c;
              }
            }
          }
        }

        .no-show-title {
          font-size: 0.87rem;
          font-weight: 500;
        }

        .no-show-button {
          background: #34b9b9;
          color: #fff;
          padding: 3px 10px;
          font-size: 0.87rem;
          border-radius: 3px;
          cursor: pointer;

          &:hover {
            text-decoration: none;
            background: #2a9c9c;
          }
        }
      }

      .time-info.pro-found {
        width: 100%;
        text-align: center;

        .title {
          margin-bottom: 1rem;
        }

        .day-container {

          .option-time {
            i {
              color: #34B9B9;
              padding-right: 1rem;
            }
          }

          .option-time.cancelled {
            text-decoration: line-through;
          }
        }
      }

      .pro-info-container {
        .carousel-card {
          display: block;
        }

        .card-header {
          background-color: #fff;
          border-radius: 6px 6px 0 0 !important;
          border: none !important;
          padding-bottom: 0;

          img.card-img-top.img-fluid {
            max-width: 70px;
            width: 70px;
            height: auto;
            display: block;
            margin-left: auto;
            margin-right: auto;
            margin-top: -60px;
            border-radius: 50%;
            background-color: #fed900;
          }

          p.card-subtitle {
            margin-top: 10px;
            color: #999;
            margin-bottom: 0;
            text-align: center;
          }
        }

        .card-body {
          background-color: #fff;
          border-bottom: 2px solid #f1f8fa;

          h2.card-text {
            font-weight: bold;
            font-size: 20px;
            margin-bottom: 0;
            text-align: center;

            .zi {
              font-size: 19px;
              color: #34b9b9;
            }

            .z-witgoedmonteur:before,
            .z-computer:before {
              font-size: 21px;
            }
          }

          p.card-text {
            margin-top: 0;
            color: #999;
            font-size: 14px;
            text-align: center;
          }
        }

        .card-footer {
          background-color: #fff;
          border-radius: 0 0 6px 6px !important;
          padding: 1rem 1.25rem 1.25rem 1.25rem;
          border-top: none;
          text-align: center;

          .msg-pro {
            margin-top: 10px;
            font-size: 12px;
            background-color: #34b9b9;
            border-color: #34b9b9;
            font-weight: 700;
          }

          .msg-pro:hover {
            background-color: #2badad;
            border-color: #2badad;
          }

          #div-msg {
            display: none;
            position: fixed;
            left: 15px;
            bottom: 5px;
            z-index: 99;
            width: 400px;
            height: 700px;
            box-shadow: 0 5px 40px rgba(0, 0, 0, .16) !important;

            .close-chat {
              position: absolute;
              left: 22rem;
              top: 8px;
              font-size: 2rem;
              cursor: pointer;
              padding: 5px;
            }
          }

          #div-msg-mob {
            display: none;
            position: fixed;
            left: 0px;
            bottom: 0px;
            z-index: 99;
            height: 100%;
            width: 100%;
            max-width: 600px;

            .close-chat-mob {
              position: absolute;
              right: 20px;
              top: 0px;
              font-size: 2rem;
              cursor: pointer;
              padding: 5px;
            }
          }

          p.card-text.region {
            font-weight: 400;
            font-size: 15px;
          }

          p.card-text {
            margin-top: 0;
            margin-bottom: 0;
            color: #999;
            font-size: 15px;
          }

          .stars {
            display: flex;
            align-items: center;
            font-size: 16px;
            position: relative;
            overflow: hidden;
            line-height: 0;
            vertical-align: middle;
            z-index: 0;
            margin: 0 auto;

            .stars-wrap {
              display: flex;
              margin: 0 auto;

              .stars-active {
                color: #eebd01;
                position: relative;
                z-index: 10;
                display: inline-block;
                overflow: hidden;
                white-space: nowrap;
              }

              .stars-inactive {
                color: #ccc;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
              }
            }
          }

          #review-stars {
            color: #999;
          }

          #more-reviews, #more-reviews-desktop {
            font-size: 0.9rem;
            text-decoration: underline;
            color: #34B9B9;
            cursor: pointer;
          }
        }

        .card-footer-phone {
          background-color: #fff;
          border-radius: 0 0 6px 6px !important;
          padding: 1rem 1.25rem 1.25rem 1.25rem;
          border-top: none;
          text-align: center;
          border-top: 2px solid #f1f8fa;

          p.card-text.phone {
            color: #34b9b9;
            font-weight: 700;
          }
        }

        .score-wrap.review {
          display: inline-block;
          position: relative;
          margin-bottom: 0;
          padding-right: 10px;
          margin-top: 0.15rem;
        }
      }
    }

    .page-title {
      padding-top: 4rem;

      .row {
        width: 100%;
      }
    }

    .job-id-title {
      text-align: center;
      font-size: 2rem;
      margin-bottom: 2rem;

      span {
        font-weight: 700;
      }
    }

    .progress-container {
      margin-left: -2.5rem;
    }

    .progress {
      width: 80vw;
      margin: 0px auto;
    }

    .progressbar {
      width: 80vw;
      margin: 0px auto;
    }

    .progressbar li {
      list-style-type: none;
      width: 25%;
      float: left;
      font-size: 12px;
      position: relative;
      text-align: center;
      color: $color-black;

      .bold-text {
        font-weight: 700;
      }
    }

    .progressbar li:before {
      width: 18px;
      height: 18px;
      font-family: "Font Awesome 5 Free";
      font-size: 0.5rem;
      font-weight: 900;
      content: "\f00c";
      color: $color-grey;
      line-height: 18px;
      border: 1px solid $color-grey;
      display: block;
      text-align: center;
      margin: 0 auto 10px auto;
      border-radius: 50%;
      background-color: white;
    }

    .progressbar li.active:before {
      width: 18px;
      height: 18px;
      font-family: "Font Awesome 5 Free";
      font-size: 0.5rem;
      font-weight: 900;
      content: "\f00c";
      color: $color-white;
      line-height: 18px;
      border: 1px solid $color-grey;
      display: block;
      text-align: center;
      margin: 0 auto 10px auto;
      border-radius: 50%;
      background-color: #fed900;
    }

    .progressbar li:after {
      width: 87%;
      height: 2px;
      content: '';
      position: absolute;
      background-color: $color-grey;
      top: 8px;
      left: -44%;
      /*z-index: -1;*/
    }

    .progressbar li:first-child:after {
      content: none;
    }

    .progressbar li.active {
      color: $color-black;
    }

    .progressbar li.active:before {
      border-color: $color-primary;
    }

    .progressbar li.active + li:after {
      background-color: $color-primary;
    }

    .progress-icons {
      background-color: transparent;
      height: 3rem;

      i {
        color: $color-secondary;
        font-size: 2rem;
        width: 25%;
        text-align: center
      }

      .step1 {
        margin-left: 2.5rem;
      }
    }

    .score-wrap {
      display: inline-block;
      position: relative;
      height: 19px;
      vertical-align: middle;
      line-height: 1rem;

      .stars-active {
        color: $color-stars-active;
        position: relative;
        z-index: 10;
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
      }

      .stars-inactive {
        color: $color-ccc;
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    .headings {
      text-align: center;
      margin-top: 2rem;
      margin-bottom: 3rem;
    }

    .score {
      color: $color-999;
    }

    .alert {
      margin: 1.25rem 0;
    }

    .job-details {
      background: #fff;
      padding: 2rem;
      box-shadow: 2px 2px 5px 2px #ccc;

      h4 {
        margin-bottom: 2rem;
        font-weight: bold;

        span {
          font-weight: normal;
        }
      }

      h5 {
        font-weight: bold;
      }

      .email-confirmed-icon {
        i {
          color: $color-secondary;
        }
      }

      .timeslots {
        padding-top: 1rem;
      }

      .timeslot {
        background: #F4F4F4;
        padding: .7rem 1rem;

        .day-part {
          font-size: .9rem;
          color: #34B9B9;
          display: inline-block;
          padding-right: 8px;
          margin-right: 8px;
          line-height: 1.7rem;
          height: 45px;

          .name {
            font-weight: bold;
            font-size: 14px;
          }

          .time {
            font-size: .7rem;
            position: relative;
            bottom: 10px;
            left: 2px;
            color: #212529;
          }

          .rate {
            display: block;
            font-size: .9rem;
            position: relative;
            bottom: 18px;
            left: 2px;
            color: #333;
            font-weight: bold;
          }
        }

        &.picked-option {
          .picked-option-title {
            font-weight: 700;
            padding-bottom: 1rem;
          }

          .time {
            bottom: 0;
          }

          .rate {
            bottom: 0;
          }
        }
      }

      .comments {
        padding-top: 2rem;

        .comment {
          background: $color-white;
          border: 1px solid $bgcolor-primary;
          padding: .7rem 1rem;
        }
      }

      .col-ts {
        padding-top: .5rem;
      }

      .jobId a {
        font-size: 14px;
        font-weight: bold;
        margin-left: .3rem;
      }

      .inactive .timeslot {
        opacity: 0.25;

        i {
          display: none;
        }
      }

      .active .timeslot {
        color: $timeslot-active-color;
        background-color: $timeslot-active-background;
        border-color: $timeslot-active-border;
      }

      .cancelled .timeslot {
        color: $timeslot-cancelled-color;
        background-color: $timeslot-cancelled-background;
        border-color: $timeslot-cancelled-border;
      }
    }

    &.error {
      .alert {
        margin: 2rem 0;
      }

      p {
        margin: 2rem 0;
      }
    }

    .review .score-wrap {
      display: inline-block;
      font-size: 16px;
      position: relative;
      overflow: hidden;
      line-height: 0;
      vertical-align: middle;
    }

    p.lead {
      font-size: 1rem;
      color: $color-555;
    }

    .cancel-rebook {
      background: #F1F8FA;
      padding: 2rem;

      p {
        color: #888888;
        font-size: 0.8rem;
      }

      a {
        font-weight: 700;
      }
    }

    .questions {
      padding: 1rem;
    }

    &.cancelled-appointment {
      padding: 2rem 0 1px;

      .job-details {
        max-width: 60vw;
        margin: 0 auto;
      }
    }

    &.cancel-page {
      padding: 1rem;
    }

    .status-page-reviews {
      margin-bottom: 4rem;

      .review-list-container {

        .status-page-review {
          padding: .5rem 0;

          .stars-score {
            color: #eebd01;
          }

          .review-text {
          }
        }

        .read-more {
          background-color: #34b9b9;
          border-color: #34b9b9;
          font-weight: 500;
        }
      }
    }
  }

  .desktop-chat-container {
    #desktop-chat {
      height: 600px;
      width: 75%;
      padding: 0;
      box-shadow: 0 10px 20px 0px rgba(0, 0, 0, .16)
    }

    .desktop-chat-toggle-box {
      background-color: rgba(255, 255, 255, .7);
      border-radius: .25rem;
      box-shadow: 0 1px 3px rgba(0, 0, 0, .2);
      font-size: 17px;
      font-weight: bold;
      color: #34b9b9;
      padding: 1.5rem;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      width: 75%;
    }

    .desktop-chat-toggle-box:hover {
      background-color: rgba(239, 239, 239, .7);
    }
  }


  &.job-form {
    position: relative;
    top: 20vh;

    form {
      padding: 15px;
    }

    .form-check-label {
      width: 100%;
    }

    .price-details {
      font-size: .9rem;
      margin-bottom: 1rem;
    }

    > div.container > form > div.row > div.stepper-column {
      background: $color-off-white;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 2rem;

      > .vertical {
        position: sticky;
        top: 70px;

        > div.bs-stepper-header > div.step > button > .stepper-object {
          display: flex;
          align-items: center;

          > .stepper-titles {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            > {
              .small-title {
                color: $color-aaa;
              }

              .big-title {
                color: $color-black;
              }
            }
          }
        }
      }
    }
  }

  &.rates {
    background: $color-eee;
    padding: 4rem 0;
  }

  &.review {
    background: $bgcolor-primary;
    padding: 4rem 0 150px;
    min-height: 460px;
    position: relative;
    margin-bottom: 0;

    textarea {
      min-height: 100px;
    }

    .btn-primary {
      background: $color-secondary;
      border: none;
      padding: .5rem 2rem;
      font-size: 1.2rem;
      font-weight: bold;

      &:hover {
        background: $color-secondary-hover;
      }
    }

    p span {
      font-weight: bold;
    }
  }

  &.whats-next {
    padding: 4rem 0;

    .map {
      height: 100%;
      min-height: 300px;
    }
  }

  &.faq {
    padding: 0 0 4rem;
    background-color: $bgcolor-primary;

    a {
      font-size: 17px;
      font-weight: bold;
      color: $color_53;
      line-height: 2.75rem;
    }

    .card.collapsed {
      background: none;
      border: none;
      background-color: $color-white-opacity-70;
    }

    .faq-title {
      padding-top: 3rem;
    }
  }

  &.content {
    background: $bgcolor-primary repeat-x 100% bottom;
    min-height: 460px;
    padding: 4rem 0 6rem;

    p {
      margin-bottom: 2rem;
    }

    h1 {
      font-size: 2rem;
      font-weight: bold;
    }

    h3 {
      margin-top: 3rem;
      font-size: 1.5rem;
      font-weight: bold;
    }

    .locations {
      column-count: 3;
      column-gap: 20px;
      margin-top: 2rem;

      .location {
        display: block;
        break-inside: avoid-column;
        margin-bottom: 2rem;
      }
    }
  }

  &.search-results {
    background: $bgcolor-primary;
    min-height: 460px;
    padding: 4rem 0 6rem;

    .result {
      background: $color-white-opacity-50;
      padding: 1rem;
      margin-bottom: 1rem;
    }

    p {
      margin-bottom: 1rem;
    }

    a {
      &.url {
        display: block;
        color: $color-secondary;
        margin-bottom: .4rem;
        width: 100%;
        height: 25px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      &.title {
        h3 {
          color: $color-almost-black;
        }

        &:hover {
          color: $color-almost-black;
        }
      }
    }

    h1 {
      font-size: 2rem;
      font-weight: bold;
    }

    h2 {
      font-size: 1.5rem;
      font-weight: bold;
      margin-bottom: 1rem;

      &.found {
        font-size: 1rem;
        font-weight: normal;
        color: $color-black-opacity-46;
        margin-bottom: 2rem;
      }
    }

    h3 {
      font-size: 1.2rem;
      font-weight: bold;
      margin: 0;
    }

    .input-group {
      margin-bottom: .5rem;

      .btn {
        border-top-right-radius: 9px;
        border-bottom-right-radius: 9px;
        padding: 1rem 2rem;
        font-size: 1.2rem;
        font-weight: 500;
        background-color: $color-secondary;
        border: none;
      }
    }

    .results {
      .page {
        display: none;
      }

      .page-active {
        display: block;
      }
    }

    .page-item {
      &.first {
        display: none;
      }

      &.last {
        display: none;
      }

      .page-link {
        color: $color-secondary;
      }

      &.active .page-link {
        background: $color-secondary;
        border-color: $color-secondary;
        color: $color-white;
      }
    }

    .form-control.search {
      font-size: 1.25rem;
      font-family: $font-primary;
      height: 50px;
      border: none;
      font-size: calc(14px + 0.5vw);
      padding: 2rem 1.5rem;
    }
  }

  &.faq-heading {
    background: $color-primary;
    padding: 5vh 0;

    h1 {
      text-align: center;
      color: $color-white;
    }

    .btn-group {
      margin-top: 2rem;
      margin-bottom: 1rem;

      .btn-secondary {
        background: none;
        border: 1px solid $color-white;
        font-weight: bold;
        font-size: 1.2rem;
        box-shadow: none !important;
        padding: .7rem 1.2rem;

        &.active {
          background: $color-white;
          color: $color-primary;
          border: 1px solid $color-white;
        }
      }

      a {
        &:first-child {
          border-top-left-radius: 25px;
          border-bottom-left-radius: 25px;
        }

        &:last-child {
          border-top-right-radius: 25px;
          border-bottom-right-radius: 25px;
        }
      }
    }
  }
}

#nu-regelen-sticky {
  position: fixed;
  bottom: 3rem;
  left: 2rem;
  z-index: 99;
  font-size: 1rem;

  &:active {
    background-color: #2c9696;
    border-color: #2c9696;
  }
}

.klus {
  section {
    &.jumbo {
      padding-bottom: 6rem;
      background: $jumbo-background-color;

      .search-form {
        margin-bottom: -16px;
        min-height: 55vh;

        p.lead {
          margin-top: 2rem;
          margin-bottom: 3rem;
        }

        .from-price {
          color: scale-color($jumbo-leadtext-color, $lightness: -20%);
        }
      }

      .score {
        display: inline-block;
        font-size: 16px;
        position: relative;
        overflow: hidden;
        line-height: 0;
        vertical-align: middle;
        z-index: 0;

        .stars-active {
          color: $color-stars-active;
          position: relative;
          z-index: 10;
          display: inline-block;
          overflow: hidden;
          white-space: nowrap;
        }

        .stars-inactive {
          color: $color-ccc;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }

    &.how {
      background: $how-background-color;
    }

    &.customer-carousel {
      .owl-carousel {
        display: block;
      }
    }
  }

  .btn-primary {
    background: #FFA500;
    border: none;
    padding: 1rem 2rem;
    font-size: calc(10px + 1vw);
    font-weight: bold;
    border-radius: 8px;
    margin-right: 1rem;

    &:hover {
      background: #f09b00;
    }
  }
}

h1 {
  #validate-address {
    text-align: center;
    color: $color-888;
  }

  .score {
    display: inline-block;
    font-size: 16px;
    position: relative;
    overflow: hidden;
    line-height: 0;
    vertical-align: middle;
    font-weight: normal;

    .score-wrap {
      display: inline-block;
      position: relative;
      height: 19px;
    }

    .stars-active {
      color: $color-stars-active;
      position: relative;
      z-index: 10;
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
    }

    .stars-inactive {
      color: $color-ccc;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .meta {
    display: block;
    padding-top: .5rem;

    > span {
      text-align: left;
      color: $color-almost-black;
      font-size: 1.25rem;
      line-height: 1.5rem;

      .from-price {
        vertical-align: top;
        margin: 0;
        padding-right: 1rem;
        color: $color-black-opacity-46;

        &:first-letter {
          text-transform: uppercase;
        }

        .badge {
          font-size: 1.25rem;
          margin-left: .25rem;
          margin-right: .2rem;
          background: $color-secondary;
          color: $color-white;
        }
      }

      .based-on {
        font-size: 12px;
        font-weight: normal;
        color: $color-aaa;
      }
    }
  }
}

div {
  .meta {
    display: block;
    padding-top: .5rem;

    > span {
      text-align: left;
      color: $color-almost-black;
      font-size: 1.25rem;
      line-height: 1.5rem;

      .from-price {
        vertical-align: top;
        margin: 0;
        padding-right: 1rem;
        color: $color-black-opacity-46;

        &:first-letter {
          text-transform: uppercase;
        }

        .badge {
          font-size: 1.25rem;
          margin-left: .25rem;
          margin-right: .2rem;
          background: $color-secondary;
          color: $color-white;
        }
      }

      .based-on {
        font-size: 12px;
        font-weight: normal;
        color: $color-aaa;
      }
    }
  }
}

.review {
  color: $reviews-review-title;
  margin-bottom: 2.5rem;

  &.quote {
    background: $color-white-opacity-70;
    padding: 1.25rem 1.5rem;
    margin-top: 3.5rem;

    p.review-content {
      font-size: 15px;
      color: $reviews-review-content;
      margin: 0;
    }
  }

  .personal {
    display: inline-block;
    vertical-align: top;
    margin-bottom: 1rem;

    .name {
      font-weight: bold;
    }

    .city {
      font-weight: bold;
    }
  }

  .task-date {
    display: block;
    font-size: 15px;
    color: $reviews-review-taskdate;
  }

  p.review-content {
    font-size: 17px;
    min-height: 80px;
    border-radius: 10px;
    line-height: 1.5rem;
  }

  span.quote {
    font-size: 30px;
    color: $color-eee;
    line-height: 1rem;
    margin-right: .75rem;
    float: left;
    display: inline-block;
    vertical-align: top;
  }
}

/* Begin Jobform */
.jobform {

  .form-control {
    border: none;
    border-bottom: 1px solid #ccc;
    border-radius: 8px 8px;
    padding: 18px 12px 18px 12px;
    height: auto;
  }

  .text-muted {
    color: #999 !important;
  }

  .where {
    padding-top: 2rem;
  }

  .when {
    padding-top: 2rem;

    .time-window {
      margin-top: .5rem;
      font-size: .9rem;
    }

    .estimate {
      margin: 0;
      padding: 0;

      #calculator-estimate {
      }

      .calculator-estimation-result {
        margin-top: 1rem;
        margin-bottom: 2rem;
        color: #34B9B9;
        font-style: italic;
      }
    }
  }

  .what {
    padding-top: 2rem;

    .dynamic-fields {
      padding-bottom: 1rem;

      .row.dynamic {
        margin-bottom: 1rem;
      }
    }

    .estimate {

      #calculator-estimate {
      }

      .calculator-estimation-result {
        margin-top: 1rem;
        margin-bottom: 2rem;
        color: #34B9B9;
        font-style: italic;
      }
    }
  }

  .who {
    padding-top: 2rem;

    .name {
      margin-bottom: 1rem;
    }
  }

  label.question {
    font-weight: 500;
    color: #444;
    font-size: 1.25rem;
  }

  textarea.comment {
    min-height: 120px;
  }

  .address-validation {
    color: #999;

    .validation-message {
      padding: 2rem .75rem 1rem .75rem;
    }

    #invalid-address {
      display: none;
    }

    &.valid {
      color: #34b9b9;
    }

    &.invalid {
      color: crimson;

      #validate-address {
        display: none;
      }

      #invalid-address {
        display: inline-block;
      }
    }
  }

  .review.quote {
    padding: 1.5rem 1.75rem 1.5rem 1.75rem;
    margin: 0;
    border-top: 1px solid $color-eee;
  }

  .payment-next {
    padding-top: 2rem;

    .payment {
      padding-bottom: 1rem;

      .text {
        margin-bottom: .5rem;
        font-size: .9rem;
      }

      .payment-methods {
        width: 100px;
      }
    }

    .terms {
      font-size: .9rem;
    }
  }

  .job-info {

    .title {
      font-size: .85rem;
      font-weight: 500;
      color: #777;
      text-align: center;
      padding: 2rem 0;
      vertical-align: middle;

      .zi {
        color: #34b9b9;
        font-size: 26px;
        display: inline-block;
        vertical-align: middle;
        margin-right: .3rem;
      }
    }

    .job {
      padding: 0 2rem 2rem 2rem;

      h3 {
        font-size: 1.25rem;
        margin-bottom: 0;
      }

      .description {
        margin: 1rem 0;
        font-size: 1rem;
      }

      .meta {

        .based-on {
          font-size: .75rem;
          color: #999;
          margin-top: .75rem;
          line-height: 1rem;
        }

        .from-price {
          color: #666;
          margin-bottom: 0;

          .badge {
            background: none;
            font-size: 100%;
            font-weight: normal;
            margin: 0;
            padding: 0;
            color: inherit;
          }
        }
      }
    }
  }

  .card.summary {
    margin-top: 1rem;

    .questions {
      padding-top: 1.5rem;
      margin-bottom: 1.5rem;

      .review.quote {
        padding: 1.5rem 1.75rem 1.5rem 1.75rem;
        margin: 0;
        border-top: 1px solid $color-eee;
      }


      h5 {
        margin-bottom: .25rem;
      }
    }

    #desktop-option-details {
      border-top: 1px solid $color-eee;
      padding: 1rem 1.75rem 1rem 1.75rem;
    }

    .payment {
      border-top: 1px solid $color-eee;
      padding: 1rem 0;

      img {
        max-width: 100px;
        margin-top: .5rem;
      }
    }

    .review.quote h5 {
      margin-bottom: 1rem;
    }

    &.coupon {
      padding: 1.5rem 1.75rem 1.5rem 1.75rem;
      width: 100%;

      .input {
        padding: 0;

        .coupon-container {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-top: 1rem;
          padding-bottom: 1rem;

          .form-field {
            margin-top: 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            #coupon-input-button {
              padding: 14px !important;
              border: 1px solid #ccc;
              border-left: none;
              margin-top: 0;
              position: relative;
              right: 5px;
              border-radius: 7px;
              border-top-left-radius: 0px !important;
              border-bottom-left-radius: 0px !important;
              border: 1px solid #34B9B9;
              background: #34B9B9;
              border-color: #34B9B9;
              color: white;
              text-transform: uppercase;
              cursor: pointer;
              font-size: .7rem;
              font-weight: 700;

              &:hover {
                background-color: #269c9c;
                border-color: #269c9c;
              }
            }

            #coupon-input-button.remove {
              display: none;
              padding: 14px !important;
              border: 1px solid #ccc;
              border-left: none;
              margin-top: 0;
              border-radius: 7px;
              border-top-left-radius: 0px !important;
              border-bottom-left-radius: 0px !important;
              border-left: 1px solid #ccc;
              position: relative;
              right: 5px;
              background: black;
              border-color: black;
              color: white;
              text-transform: uppercase;
              cursor: pointer;
              font-size: .7rem;
              font-weight: 700;

              &:hover {
                background-color: black;
                border-color: black;
              }
            }

            .form-field__control {
              display: flex;
              justify-content: space-between;
              align-items: center;
            }
          }

          input {
            padding: 10px !important;
            border: 1px solid #ccc;
            margin-top: 0;
            border-right: none;
            border-top-right-radius: 0px !important;
            border-bottom-right-radius: 0px !important;
          }
        }
      }

      .alert.alert-success.success-label {
        display: none;
      }

      .alert.alert-danger.danger-label {
        display: none;
      }
    }
  }

  .loading-dots {
    font-size: 48px;
  }

  .loading-dots:after {
    content: ' .';
    animation: dots 1s steps(5, end) infinite;
  }

  .type-selector-card {
    background: #fff;
    cursor: pointer;
    box-shadow: 0 0px 3px 0 rgba(0, 0, 0, 0.1);
    padding: 0.6rem 1rem;
    margin: 10px 0px;
    border-radius: 8px;
    align-items: center;
    transition: 0.3s;
    position: relative;
    min-height: 6rem;
    z-index: 10;

    .type-selected-title {
      color: #34B9B9;
      font-weight: bold;
    }

    .type-selected-title i {
      margin-right: .25rem;
    }

    .type-selected-subtitle {
      color: #999;
      font-size: 0.8rem;
    }

    .card-price-container {
      padding-top: 0.9rem;
    }
  }

  .type-selected-details {
    width: auto;
    padding: 1rem 0.9rem .35rem 0.9rem;
    background: #34b9b9;
    color: #fff;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    margin-top: -15px;
  }

  .type-selected-price {
    display: inline-block;
    font-size: 1.5rem;
    font-weight: bold;
    letter-spacing: -1px;
    color: #444;
    line-height: 1rem;

    span.from {
      display: inline-block;
      font-size: 13px;
      line-height: 1.5rem;
      margin-top: -10px;
    }
  }

  .discount-alert {
    display: none;
  }

  .service-fee {
    display: inline-block;
    font-size: 12px;
    color: #777;
    font-style: italic;
  }

  .check-icon {
    float: right;
    font-size: 1.5rem;
    color: white;
  }

  .type-selected-servicefee {
    display: inline-block;
    font-weight: normal;
    color: #777;
    margin-top: .25rem;
  }

  .selected {

    .type-selector-card {
      background: #34b9b9;
      color: #fff;
      box-shadow: none;
    }

    .type-selected-title,
    .type-selected-subtitle,
    .type-selected-price,
    .type-selected-servicefee,
    .service-fee {
      color: #fff;
    }
  }

  .type-selector-card:hover {
    background: #34b9b9;
    color: #fff;
    box-shadow: none;
  }

  .type-selector-card:hover .type-selected-title,
  .type-selector-card:hover .type-selected-subtitle,
  .type-selector-card:hover .type-selected-price,
  .type-selector-card:hover .type-selected-servicefee,
  .type-selector-card:hover .service-fee {
    color: #fff;
  }

  .time-checkbox-container {
    display: flex;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 1.2rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .time-details .rate-type {
    display: none;
  }

  /* Hide the browser's default checkbox */
  .time-checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 5px;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #fff;
    border: 1px solid #ccc;
  }

  /* On mouse-over, add a grey background color */
  .time-checkbox-container:hover input ~ .checkmark {
    background-color: #fafafa;
  }

  /* When the checkbox is checked, add a blue background */
  .time-checkbox-container input:checked ~ .checkmark {
    background-color: #fff;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .time-checkbox-container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .time-checkbox-container .checkmark:after {
    left: 9px;
    top: 3px;
    width: 7px;
    height: 14px;
    border: solid #34B9B9;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .scroll-container {
    width: 100%;
    display: none;
    align-items: center;
  }

  .scroll-container:hover #date-chev-left {
    -webkit-animation-name: pulse;
    animation-name: pulse;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: forwards;
  }

  .scroll-container:hover #date-chev-right {
    -webkit-animation-name: pulse;
    animation-name: pulse;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: forwards;
  }

  @keyframes pulse {
    0% {
      transform: scale3d(1, 1, 1);
    }

    10%, 20% {
      transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
    }

    30%, 50%, 70%, 90% {
      transform: scale3d(1.5, 1.5, 1.5) rotate3d(0, 0, 1, 3deg);
    }

    40%, 60%, 80% {
      transform: scale3d(1.5, 1.5, 1.5) rotate3d(0, 0, 1, -3deg);
    }

    100% {
      transform: scale3d(1, 1, 1);
      opacity: 1;
    }
  }

  .scrolling-wrapper {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    width: 100%;
  }

  .scrolling-wrapper .date {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #000;
    border: 1px solid #000;
    padding: 5px 4px;
    margin: 0 10px;
    cursor: pointer;
    min-width: 23%;
    max-width: 20%;
    border-radius: 15%;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
  }

  .scrolling-wrapper .date.date-active {
    background: #34b9b9;
    border-color: #34b9b9;
  }

  /*#day-0 {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }*/
  .day-day {
    font-size: calc(15px + 0.5vw);
    color: white;
    text-transform: capitalize;
    margin-top: 0;
    margin-bottom: 0;
  }

  .day-date {
    font-size: calc(15px + 0.3vw);
    color: white;
    text-align: center;
    margin-bottom: 0;
  }

  .day-date > .number {
    font-size: calc(23px + 0.5vw);
    display: block;
    line-height: 1;
  }

  .day-date > .month {
    text-transform: capitalize;
  }

  .day-date-price {
    font-size: calc(12px + 0.5vw);
    font-weight: bold;
    letter-spacing: 0;
    margin-bottom: 0;
  }

  .service-cost {
    font-size: calc(8px + 0.5vw);
    color: #A8A8A8;
    font-style: italic;
    margin-bottom: 0.3rem;
  }

  .scrolling-wrapper::-webkit-scrollbar {
    display: none;
  }

  .scroll-card {
    display: inline-block;
    height: 100px;
    width: 25%;
    border: 1px solid black;
  }

  .badge {
    font-size: 60%;
  }

  input {
    padding: 1.5rem 1rem !important;
    border-radius: 7px !important;
  }

  textarea {
    border-radius: 7px !important;
  }

  section.jobform {
    background: #e6f2f6;
    padding-top: 4rem;
    padding-bottom: 1rem;
  }

  body.jobform form {
    overflow: hidden;
  }

  img.loader {
    width: 20px;
    height: 20px;
    margin-top: -3px;
    margin-left: -1px;
    margin-right: 1px;
  }

  .jobform .task-title {
    padding: 0 0 1rem 0;
    line-height: 1.75rem;
    font-size: 2rem;
  }

  .jobform #form-task-title a {
    text-decoration: none;
    cursor: default;
    color: #999;
  }

  .jobform h2 {
    font-size: 1.5rem;
    margin: 3rem 0 .5rem 0;
    color: rgba(0, 0, 0, 0.4);
  }

  .jobform .container {
    max-width: 1100px;
    padding: 0;
  }

  .jobform .col-usps {
    padding-left: 0;
  }

  .jobform #submit-button-mobile {
    display: none;
  }

  #price-info-title {
    display: block;
    font-weight: bold;
  }

  #desktop-option-details {
    display: none;
  }

  .card-header {
    background: #fff;
    text-align: left;
    border: none;
    padding: 1.5rem 1.75rem;
    border-radius: 6px !important;
    position: relative;
  }

  .accordion .card-header.collapsed h5 {
    color: #ddd;
  }

  .accordion .card.invalid .card-header h5 {
    color: #222;
  }

  #validate-address {
    display: inline-block;
    font-size: .9rem;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: -4px;
  }

  .validation-status {
    margin-right: .25rem;
  }

  .validation-status #spinner {
    display: none;
  }

  .jobform .card-header .collapse-status {
    position: absolute;
    top: 1.8rem;
    right: 1.5rem;
    font-size: 1rem;
  }

  .jobform .card-header .collapse-status .down {
    display: none;
  }

  .jobform .card-header.collapsed .collapse-status .up {
    display: none;
  }

  .jobform .card-header.collapsed .collapse-status .down {
    display: block;
  }

  .jobform .card-header.collapsed {
    border: none;
  }

  .jobform .card.invalid .card-header.collapsed {
    color: #222;
  }

  .jobform .accordion .card {
    margin-bottom: .5rem;
    border-radius: 6px;
    border: none;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
  }

  .jobform .card .card-body {
    padding: 0 1.75rem 1.5rem 1.75rem;
    position: relative;
  }

  .jobform .accordion .card .card-body {
    padding-bottom: 80px;
  }

  .jobform .accordion .card .card-body .row.dynamic {
    padding-bottom: 1.75rem;
  }


  .jobform .card .card-body .input {
    padding: .5rem 15px;
  }

  .jobform .col-usps .card-body {
    padding-bottom: 1rem;
  }

  .jobform .col-usps .card.summary {
    margin-bottom: 1rem;
  }

  .jobform .card textarea {
    min-height: 100px;
  }

  .jobform .card .card-body .btn-next,
  .jobform .card .card-body .btn-prev {
    position: absolute;
    bottom: 0;
    right: 0;
    background: none;
    border-top: 1px solid #eee;
    border-left: 1px solid #eee;
    color: #666;
    border-radius: 0;
    border-top-left-radius: 8px;
    border-bottom-right-radius: 8px;
    padding: .75rem 1rem;
  }

  .jobform .card .card-body .btn-next {
    background-color: #34B9B9;
  }

  .jobform .card .card-body .btn-next,
  .jobform .card .card-body .btn-next i {
    color: #fff;
  }

  .jobform .card .card-body .btn-prev {
    position: absolute;
    bottom: 0;
    left: 0;
    right: auto;
    border-top: 1px solid #eee;
    border-right: 1px solid #eee;
    border-left: none;
    border-radius: 0;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  .jobform .card .card-body .btn-prev i {
    color: #34B9B9;
  }

  .jobform .card .card-body .btn-next i {
    margin-left: .5rem;
  }

  .jobform .card .card-body .btn-prev i {
    margin-right: .5rem;
  }

  .jobform .card .card-body .btn-next:hover,
  .jobform .card .card-body .btn-prev:hover {
    background-color: #34B9B9;
  }

  .jobform .card .card-body .btn-next:hover,
  .jobform .card .card-body .btn-prev:hover,
  .jobform .card .card-body .btn-next:hover i,
  .jobform .card .card-body .btn-prev:hover i {
    color: #fff;
  }

  .jobform .col-usps .card-header {
    padding-bottom: .5rem;
  }

  .jobform .col-usps ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .jobform .card.summary {
    margin-bottom: 1rem;
  }

  .jobform .card.summary h5 {
    margin-bottom: 0;
  }

  .jobform .card.summary .address {
    display: block;
    margin-bottom: 1rem;
    font-weight: bold;
    font-size: 1.25rem;
  }

  .jobform .card.summary p.price-info {
    margin-bottom: .4rem;
  }

  .jobform li.total {
    margin-top: 1rem;
  }

  .jobform .card .questions {
    padding-top: 1rem;
  }

  .jobform .card.summary .why {
    border-top: 1px solid #eee;
    padding-bottom: 1rem;
  }

  .date-picker .date-picker-container > #dates {
    display: flex;
    width: 100%;
  }

  .date-picker .date-picker-container > #dates > .date {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #ddd;
    padding: 5px 4px;
    cursor: pointer;
    width: 18%;
  }

  /*.date-picker .date-picker-container > #dates > .date:focus {
box-shadow: 0 0 0 3px rgba(0, 123, 255, .5);
}*/
  .date-picker .date-picker-container > #dates > .date:nth-child(1) {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .date-picker .date-picker-container > #dates > .date:nth-child(7) {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .date-picker .date-picker-container > #dates > .date > p {
    margin: 0;
  }

  .date-picker .date-picker-container > #dates > .date > .badge {
    font-size: 9px;
    padding: .5em .75em;
  }

  .date-picker .date-picker-container > #dates > .date > p.day-day {
    font-size: calc(5px + 0.6vw);
    color: #8b8b8b;
    text-transform: capitalize;
    margin-top: .5rem;
  }

  .date-picker .date-picker-container > #dates > .date > p.day-date-month {
    font-size: calc(5px + 0.8vw);
    text-transform: uppercase;
    font-weight: 700;
    color: #555555;
  }

  .date-picker .date-picker-container > #dates > .date > p.day-date {
    font-size: calc(9px + 1vw);
    color: #333;
    font-weight: 700;
    text-align: center;
    line-height: 1rem;
  }

  .date-picker .date-picker-container > #dates > .date > p.day-date .number {
    display: block;
    font-size: 18px;
  }

  .date-picker .date-picker-container > #dates > .date > p.day-date .month {
    font-size: 14px;
  }

  .date-picker .date-picker-container > #dates > .date > p.day-date-price {
    line-height: 2.5rem;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
  }

  .date-color-white {
    color: white !important;
  }

  .time-picker {
    display: none;
    flex-direction: column;
    margin-bottom: 20px;
  }

  .time-picker.muted {
    color: #ccc;
  }

  .time-picker.muted p.text-muted {
    color: #ccc !important;
  }

  .time-picker .time-option-container {
    display: block;
    transition: 0.3s;
  }

  .time-option-container > #currentChosenDate {
    color: #008F9D;
    font-size: 1rem;
    padding: 20px 0px;
  }

  .time-picker .time-option-container > .time-option {
    border: 1px solid #ddd;
    font-size: calc(11px + 0.5vw);
    padding: 13px 5px;
    cursor: pointer;
    font-weight: 700;
    width: 20%;
    text-align: center;
  }

  .time-picker .time-option-container > .time-option:nth-child(1) {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .time-picker .time-option-container > .time-option:nth-child(5) {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .time-option-active {
    background-color: #34B9B9;
    color: #fff;
  }


  #spoed-option-alert {
    display: none;
    font-size: 14px;
  }

  #list-of-options {
    padding: 0 0 1rem 0;
  }

  #list-of-options > .picked-option {
    list-style-type: none;
    display: flex;
    align-items: initial;
    font-size: 13px;
    font-weight: bold;
    color: #222;
    margin: .5rem .5rem 0 0;
    border: 1px solid #eee;
    border-radius: 5px;
    padding: .5rem .75rem .5rem .75rem;
    cursor: pointer;
    background: #f6f6f6;
    position: relative;
  }

  #list-of-options > .picked-option > .picked-option-price-container {
    display: flex;
    align-items: center;
    padding-right: 10px;
  }

  #list-of-options > .picked-option > .picked-option-price-container > .picked-option-price {
    font-size: 16px;
  }

  #list-of-options > .picked-option > .picked-option-date-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 10px;
  }

  #list-of-options > .picked-option > .picked-option-date-container > .picked-option-date {
    display: flex;
    justify-content: space-between;
  }

  #list-of-options > .picked-option > .picked-option-date-container > .picked-option-time {
    font-weight: 400;
  }


  #list-of-options > .picked-option > .separator {
    border-left: 1px solid lightgrey;
  }

  #list-of-options .picked-option > .remove {
    position: absolute;
    right: .7rem;
    top: .7rem;
  }

  #job-summary {
    list-style: none;
    padding: 0;
    padding-bottom: 1rem;
  }

  .confirm-dates-button {
    background-color: #34B9B9 !important;
    border-color: #34B9B9 !important;
    width: 100%;
    padding: 0.6rem;
    font-weight: 500;
  }

  .form-check-label {
    font-weight: normal;
    font-size: 17px;
    color: #000;
    margin-top: .5rem;
    padding-bottom: 0.4rem;
  }

  .card .card-body .row > div {
    position: relative;
  }

  .valid-feedback {
    position: absolute;
    right: 5px;
    width: 30px;
    margin-top: -27px;
  }

  .jobform .questions .phone {
    font-size: 1.2rem;
    margin-top: .5rem;
    font-weight: bold;
  }

  .mobile-checkbox {
    padding-left: 20px;
    padding-top: 35px;
    padding-bottom: 0px;
  }

  .payment-footer-container {
    padding-top: 1rem;
    text-align: center;
  }

  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    /* IE10, IE11 styles */
    .date-picker {
      display: flex;
      flex-direction: inherit;
    }

    .time-picker {
      display: none;
    }

    .form-control {
      height: calc(3.25rem + 2px);
      padding: .375rem .75rem !important;
    }
  }

  .dot-container {
    display: flex;
    justify-content: center;
    position: relative;
    bottom: 2vw;
  }

  .dot-spin {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: transparent;
    color: transparent;
    box-shadow: 0 -18px 0 0 #34B9B9, 12.72984px -12.72984px 0 0 #34B9B9, 18px 0 0 0 #34B9B9, 12.72984px 12.72984px 0 0 rgba(152, 128, 255, 0), 0 18px 0 0 rgba(152, 128, 255, 0), -12.72984px 12.72984px 0 0 rgba(152, 128, 255, 0), -18px 0 0 0 rgba(152, 128, 255, 0), -12.72984px -12.72984px 0 0 rgba(152, 128, 255, 0);
    animation: dotSpin 1.5s infinite linear;
  }

  @keyframes dotSpin {
    0%, 100% {
      box-shadow: 0 -18px 0 0 #34B9B9, 12.72984px -12.72984px 0 0 #34B9B9, 18px 0 0 0 #34B9B9, 12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 -5px rgba(152, 128, 255, 0), -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 -5px rgba(152, 128, 255, 0), -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
    }

    12.5% {
      box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0), 12.72984px -12.72984px 0 0 #34B9B9, 18px 0 0 0 #34B9B9, 12.72984px 12.72984px 0 0 #34B9B9, 0 18px 0 -5px rgba(152, 128, 255, 0), -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 -5px rgba(152, 128, 255, 0), -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
    }

    25% {
      box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0), 12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0), 18px 0 0 0 #34B9B9, 12.72984px 12.72984px 0 0 #34B9B9, 0 18px 0 0 #34B9B9, -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 -5px rgba(152, 128, 255, 0), -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
    }

    37.5% {
      box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0), 12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0), 18px 0 0 -5px rgba(152, 128, 255, 0), 12.72984px 12.72984px 0 0 #34B9B9, 0 18px 0 0 #34B9B9, -12.72984px 12.72984px 0 0 #34B9B9, -18px 0 0 -5px rgba(152, 128, 255, 0), -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
    }

    50% {
      box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0), 12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0), 18px 0 0 -5px rgba(152, 128, 255, 0), 12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 0 #34B9B9, -12.72984px 12.72984px 0 0 #34B9B9, -18px 0 0 0 #34B9B9, -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
    }

    62.5% {
      box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0), 12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0), 18px 0 0 -5px rgba(152, 128, 255, 0), 12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 -5px rgba(152, 128, 255, 0), -12.72984px 12.72984px 0 0 #34B9B9, -18px 0 0 0 #34B9B9, -12.72984px -12.72984px 0 0 #34B9B9;
    }

    75% {
      box-shadow: 0 -18px 0 0 #34B9B9, 12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0), 18px 0 0 -5px rgba(152, 128, 255, 0), 12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 -5px rgba(152, 128, 255, 0), -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 0 #34B9B9, -12.72984px -12.72984px 0 0 #34B9B9;
    }

    87.5% {
      box-shadow: 0 -18px 0 0 #34B9B9, 12.72984px -12.72984px 0 0 #34B9B9, 18px 0 0 -5px rgba(152, 128, 255, 0), 12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 -5px rgba(152, 128, 255, 0), -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 -5px rgba(152, 128, 255, 0), -12.72984px -12.72984px 0 0 #34B9B9;
    }
  }

  .modal {
    .modal-content {
      .modal-body {
        border-radius: 0.3rem;
        padding: 0;

        button.close {
          position: absolute;
          right: 25px;
          top: 20px;
          z-index: 10;
        }

        .bg {
          background: #fafafa;
          padding: 2rem;
          border-radius: 0.3rem;
        }

        .options {
          padding: 2rem;
          border-radius: 0.3rem;
        }
      }
    }
  }

  .score {
    display: inline-block;
    font-size: 16px;
    position: relative;
    overflow: hidden;
    line-height: 0;
    vertical-align: middle;
    z-index: 0;

    .stars-active {
      color: $color-stars-active;
      position: relative;
      z-index: 10;
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
    }

    .stars-inactive {
      color: $color-ccc;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  /* Form fields with floating labels (React style) */
  .form-field {
    display: block;
    margin-top: 16px;

    &.calculator {
      display: flex;
      align-items: center;

      .fa-calculator {
        font-size: 2rem;
        color: #34B9B9;
        padding-right: 1rem;
      }
    }

    &.form-field--is-active {
      .form-field__control::after {
        border-bottom: 2px solid #34b9b9;
        -webkit-transform: scaleX(150);
        transform: scaleX(150);
      }

      .form-field__label {
        color: #34b9b9;
        font-size: 0.75rem;
        -webkit-transform: translateY(-14px);
        transform: translateY(-14px);
      }
    }

    &.form-field--is-valid {
      .form-field__label {
        color: #34b9b9;

        &:before {
          font-family: "Font Awesome 5 Free";
          content: "\f00c";
          display: inline-block;
          padding-right: 3px;
          vertical-align: middle;
          font-weight: 900;
          font-size: 8px;
        }
      }
    }

    &.form-field--is-filled {
      .form-field__label {
        color: #34b9b9;
        font-size: 0.75rem;
        -webkit-transform: translateY(-14px);
        transform: translateY(-14px);
      }
    }

    &.form-field--is-invalid {
      .form-field__label {
        color: crimson;
        font-size: 0.75rem;
        -webkit-transform: translateY(-14px);
        transform: translateY(-14px);

        &:before {
          font-family: "Font Awesome 5 Free";
          content: "\f00d";
          display: inline-block;
          padding-right: 3px;
          vertical-align: middle;
          font-weight: 900;
          font-size: 8px;
        }
      }

      .form-field__input,
      .form-field__textarea {
        border-bottom: 1px solid crimson;
      }

      .invalid-feedback {
        display: block;
      }
    }

    .form-field__label {
      display: block;
      font-size: 1rem;
      font-weight: normal;
      left: 0;
      margin: 0;
      padding: 18px 12px 0;
      position: absolute;
      top: 0;
      -webkit-transition: all 0.4s;
      transition: all 0.4s;
      width: 100%;
    }

    .form-field__control {
      background: #fff;
      border-radius: 8px 8px;
      overflow: hidden;
      position: relative;
      width: 100%;
    }

    .form-field__control::after {
      border-bottom: 2px solid #34b9b9;
      bottom: 0;
      content: "";
      display: block;
      left: 0;
      margin: 0 auto;
      position: absolute;
      right: 0;
      -webkit-transform: scaleX(0);
      transform: scaleX(0);
      -webkit-transition: all 0.4s;
      transition: all 0.4s;
      width: 1%;
    }

    .form-field__input,
    .form-field__textarea {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background: transparent;
      border: 0;
      border-bottom: 1px solid #ccc;
      color: #333;
      display: block;
      font-size: 1rem;
      margin-top: 24px;
      outline: 0;
      padding: 0 12px 10px 12px !important;
      width: 100%;
    }

    .form-field__textarea {
      height: 150px;
    }
  }

  .address-validation {
    &.invalid {

      .form-field--is-active {
        .form-field__label {
          color: crimson;
        }
      }

      .form-field--is-filled {
        .form-field__label {
          color: crimson;
        }
      }

      .form-field__input,
      .form-field__textarea {
        border-bottom: 1px solid crimson;
      }

      .form-field__control::after {
        border-bottom: 2px solid crimson;
      }
    }
  }

  footer {
    margin-top: 0;
    padding: 1rem;
  }

  #insuranceModal {
    .modal-content {
      background: #FFA100;
      width: 90%;
      margin: 0 auto;

      .modal-header {
        border: none;
        background: #FFA100;
        padding-bottom: 0;
        padding-top: 10px;

        button {
          outline: none;
          position: relative;
          bottom: 8px;
          left: 14px;
          background: #9B9B9B;
          border-radius: 50%;
          height: 20px;
          width: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 1;
          color: white;
        }
      }

      .modal-body {
        max-width: 90%;
        margin: 0 auto;
        font-weight: 200;
        text-align: center;
        color: white;
        background: #FFA100;
        border-radius: 0;
      }

      .modal-footer {
        border: none;
        background: #FFA100;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        font-size: 0.85rem;
        padding-top: 20px;

        p {
          color: black;
          cursor: pointer;
          margin: 0 auto;
        }
      }
    }
  }
}

/* End Jobform */
section.partner-carousel {
  padding-bottom: 10vw;

  .owl-carousel {
    overflow: hidden;

    .owl-stage-outer {
      left: -26px;

      .owl-item {
        height: 55px;

        #Karwei {
          img {
            height: 55px;
          }
        }
      }
    }
  }

  .partner-nav-container {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .partnerNavButton {
      width: 50px;
      height: 4px;
      background: #8a8a8a;
      opacity: 0.5;
      margin: 0px 3px;
      margin-top: 22px;
      cursor: pointer;
    }

    .partnerNavButton.active {
      background: #000000;
    }
  }
}

.score-wrap {
  display: inline-block;
  position: relative;
  height: 19px;
}

#starttask-button {
  height: 100%;
}

#form-inputs {
  &.form-column {
    background: $color-white;
    padding: 1.75rem 60px;
  }

  > {
    #invalid-address {
      display: none;
      color: $color-error;
      margin: 1rem 0;
      line-height: 1rem;
    }

    #validate-address {
      margin: 1rem 0;
      color: $color-999;
      line-height: 1rem;

      i.fas {
        margin-right: .5rem;
      }
    }

    .row > {
      .task-title {
        padding-top: 20px;
        font-family: $font-primary;
        font-weight: 700;
        font-size: calc(20px + 1vw);
        margin-bottom: 5px;
        display: block;
        width: 100%;
      }

      .badges {
        margin-bottom: 20px;
      }
    }

    .submit-job-button:disabled {
      background: $color-grey;
      border-color: $color-999;
    }
  }

  > div {
    &.row {
      > div {
        &.valid-feedback {
          position: relative;
          margin-top: -40px;
          right: 22px;
          z-index: 1;

          > .fa-check {
            color: $color-secondary;
          }
        }

        &.form-check {
          > label {
            @extend %extend_4;
          }

          > input {
            @extend %extend_4;
          }
        }
      }

      > label {
        @extend %extend_4;
      }
    }

    > input {
      @extend %extend_3;
    }

    > textarea {
      @extend %extend_3;
      height: 100px;
    }

    &.form-check {
      display: flex;
      align-items: center;
    }
  }
}

.price-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 20px;
  height: 290px;

  > .price-wrapper {
    width: 32%;

    > .price-block {
      > {
        .price-description {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;
          background: $color-primary;
          border-radius: 7px;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          padding: 12px 5px;

          .price-type {
            padding-top: 0;
            margin-bottom: 0;
            font-size: calc(10px + 1vw);
            color: $color_39;
            font-weight: 700;
          }

          .price-amount {
            font-weight: 700;
            color: $color-white;
            font-size: calc(12px + 1vw);
            margin: 0;
          }

          &.chosen-price {
            background: $color-secondary !important;

            .price-type {
              color: $color-white !important;
            }

            .price-text {
              color: $color-white !important;
            }
          }

          > .inner-price {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            > .price-text {
              font-size: calc(8px + 0.5vw);
              color: $color_39;
              padding: .5rem;
              margin: 0;
            }
          }

          .loading-price-amount:after {
            content: ' .';
            animation: dots 1s steps(5, end) infinite;
            margin-left: -10px;
          }
        }

        .price-button {
          width: 100%;
          min-height: 50px;
          padding: 1vw;
          border-radius: 7px;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          background: $color-secondary;
          border: none;

          > .price-button-text {
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 0;
            padding: 0 5px;
          }
        }
      }

      .badge-primary {
        background: $color-black-opacity-20;
      }

      .price-description {
        min-height: 100px !important;
        max-height: 250px;
      }

      &:hover > .price-description {
        background: $color-secondary;
        color: $color-white;

        .price-type {
          color: $color-white;
        }

        > .inner-price .price-text {
          color: $color-white;
        }
      }
    }
  }
}

.later-price-button {
  cursor: pointer;
}

.morgen-price-button {
  cursor: pointer;
}

.spoed-price-button {
  cursor: pointer;
}

@keyframes dots {
  0% {
    color: rgba(0, 0, 0, 0);
    text-shadow: .25em 0 0 rgba(0, 0, 0, 0), .5em 0 0 rgba(0, 0, 0, 0);
  }

  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: .25em 0 0 rgba(0, 0, 0, 0), .5em 0 0 rgba(0, 0, 0, 0);
  }

  40% {
    color: $color-white;
    text-shadow: .25em 0 0 rgba(0, 0, 0, 0), .5em 0 0 rgba(0, 0, 0, 0);
  }

  60% {
    text-shadow: .25em 0 0 $color-white, .5em 0 0 rgba(0, 0, 0, 0);
  }

  80% {
    text-shadow: .25em 0 0 $color-white, .5em 0 0 $color-white;
  }

  100% {
    text-shadow: .25em 0 0 $color-white, .5em 0 0 $color-white;
  }
}

.price-button {
  &:hover {
    background: $color-secondary;
    background-color: $color-secondary !important;
    border-color: $color-secondary !important;
    box-shadow: none !important;
  }

  &:active {
    background: $color-secondary;
    background-color: $color-secondary !important;
    border-color: $color-secondary !important;
    box-shadow: none !important;
  }

  &:focus {
    background: $color-secondary;
    background-color: $color-secondary !important;
    border-color: $color-secondary !important;
    box-shadow: none !important;
  }
}

.has-animation {
  transition-duration: 0.3s;
  transition-timing-function: linear;
}

.opacity {
  opacity: 0.5;
}

.is-not-checked {
  display: none;
}

.chosen-type-text {
  color: $color-white;
}

.chosen-price {
  background: $color-secondary !important;

  .price-type {
    color: $color-white !important;
  }

  .price-text {
    color: $color-white !important;
  }
}

.date-picker {
  display: flex;
  flex-direction: column;

  .date-picker-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    > #dates {
      display: flex;
      width: 100%;

      > .date {
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 1px solid $color-ddd;
        padding: 5px 10px;
        cursor: pointer;
        width: 18%;

        &:nth-child(1) {
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
        }

        &:nth-child(7) {
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }

        > p {
          margin: 0;

          &.day-day {
            font-size: calc(5px + 0.6vw);
            color: $color-888;
            text-transform: capitalize;
            margin-top: .5rem;
          }

          &.day-date-month {
            font-size: calc(5px + 0.8vw);
            text-transform: uppercase;
            font-weight: 700;
            color: $color-555;
          }

          &.day-date {
            font-size: calc(9px + 1vw);
            color: $color-333;
            font-weight: 700;
            text-align: center;
            line-height: 1rem;

            .number {
              display: block;
              font-size: 18px;
            }

            .month {
              font-size: 14px;
            }
          }

          &.day-date-price {
            line-height: 2.5rem;
            font-size: 20px;
            font-weight: bold;
            letter-spacing: 0;
          }
        }
      }
    }
  }
}

#date-chev-left {
  cursor: pointer;
  padding: 4px 10px;
  font-size: calc(16px + 0.5vw);
  color: $color-secondary;
}

#date-chev-right {
  cursor: pointer;
  padding: 4px 10px;
  font-size: calc(16px + 0.5vw);
  color: $color-secondary;
}

.date-chev-left-error {
  animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
  transform: translate3d(0, 0, 0);
  color: $color-error !important;
}

.starrating {
  > input {
    display: none;

    &:checked ~ label {
      color: $color-stars-active;
    }
  }

  > label {
    color: $color-888;
    font-family: $font-primary;
    font-weight: bold;

    &:before {
      content: "\f005";
      margin: 2px;
      font-size: 2em;
      font-family: $fontawesome;
      display: inline-block;
      cursor: pointer;
      font-weight: 900;
    }
  }
}

.shake-animation {
  animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
  transform: translate3d(0, 0, 0);
}

@keyframes shake {
  10% {
    transform: translate3d(-1px, 0, 0);
  }

  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20% {
    transform: translate3d(2px, 0, 0);
  }

  80% {
    transform: translate3d(2px, 0, 0);
  }

  30% {
    transform: translate3d(-2px, 0, 0);
  }

  50% {
    transform: translate3d(-2px, 0, 0);
  }

  70% {
    transform: translate3d(-2px, 0, 0);
  }

  40% {
    transform: translate3d(2px, 0, 0);
  }

  60% {
    transform: translate3d(2px, 0, 0);
  }
}

.date-color-white {
  color: $color-white !important;
}

.time-picker {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  &.muted {
    color: $color-ccc;

    p.text-muted {
      color: $color-ccc !important;
    }
  }

  .time-option-container {
    display: flex;

    > .time-option {
      border: 1px solid $color-ddd;
      font-size: calc(11px + 0.5vw);
      padding: 13px 5px;
      cursor: pointer;
      font-weight: 700;
      width: 20%;
      text-align: center;

      &:nth-child(1) {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }

      &:nth-child(5) {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
  }
}


.time-option-active {
  background-color: $color-secondary;
  color: $color-white;
}

#list-of-options {
  margin: 0;
  padding: 0;

  > .picked-option {
    width: 240px;
    list-style-type: none;
    display: block;
    font-size: 13px;
    font-weight: bold;
    color: $color-almost-black;
    margin: .5rem .5rem 0 0;
    border: 1px solid $color-eee;
    border-radius: 5px;
    padding: .5rem .75rem .5rem .75rem;
    cursor: pointer;
    background: $color-off-white;
    position: relative;
  }

  .picked-option > .remove {
    position: absolute;
    right: .7rem;
    top: .7rem;
  }
}

.submit-job-button {
  width: 100%;
  margin: .5rem 0 1rem 0;
  font-size: 1.5rem;
  background-color: #ffa500;
  border-color: #ffa500;

  &:hover {
    background-color: #f09b00;
    border-color: #f09b00;
  }

  &:active {
    background-color: #f09b00 !important;
    border-color: #f09b00 !important;
  }

  &:disabled {
    background-color: #f09b00 !important;
    border-color: #f09b00 !important;
  }
}

.faq {
  h3 {
    margin-bottom: 1.25rem;
    padding-top: 1rem;
  }

  .sections .section {
    margin-bottom: 2rem;

    h2 {
      font-size: 1.5rem;
      margin-bottom: 1rem;
    }
  }

  .jump-links ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      margin-bottom: .5rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .section .card {
    margin-bottom: .5rem;
    text-decoration: none;

    &:hover {
      background-color: $color-white;
    }

    .card-title {
      font-weight: bold;
      padding: 1rem 1.5rem;
      margin: 0;
      color: $color-secondary;
      cursor: pointer;
      font-size: 16px;
    }

    .card-body {
      margin-top: -14px;
      font-weight: normal;
      line-height: 1.5rem;
      padding: .5rem 1.5rem 1.5rem 1.5rem;
      font-size: 15px;
      color: $color-almost-black;

      > p {
        margin-bottom: 0;
      }
    }
  }
}

body.blog section.content p {
  margin-top: 1rem;
  margin-bottom: .75rem;
}

.nav-item.search {
  position: absolute;
  right: 0;
  padding: .5rem .75rem;
  background: $color-white-opacity-50;
  text-align: center;

  .search-input {
    background: none;
    border: none;
    width: 0;
    margin-right: -5px;
    line-height: 2.25rem;
    outline: none;
    border: none !important;
    box-shadow: none !important;
    font-family: $font-primary;
    font-size: 16px;
    font-weight: 300;
  }
}

li.nav-item.search > div {
  &.typeahead__container {
    display: flex;
    align-items: center;
  }

  > div.typeahead__result {
    background: $color-white;
    margin-top: 211px;
    width: 67vw;
    margin-left: -39px;

    > ul {
      &.typeahead__list {
        padding: 0;
        width: 100%;
      }

      > li.typeahead__item {
        list-style: none;
        padding: 5px 20px;

        &:hover {
          background: $color-secondary;

          > a > span.typeahead__display {
            color: $color-white !important;
          }

          > a > div > span {
            color: $color-white !important;
          }
        }

        > a {
          text-decoration: none;

          > span.typeahead__display {
            color: $color-black;
          }
        }
      }
    }
  }
}

section.jumbo .why {
  background: $color-white;
  border-radius: 6px;
  margin-top: 3rem;

  .card-header {
    padding: 1.25rem 1.25rem 0 1.25rem;
    border: none;
    background: none;
  }

  .card-body {
    padding: 0 1.25rem 1.25rem 1.25rem;
  }
}

.why ul {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    padding-top: 1rem;
    font-weight: lighter;
    font-size: .9rem;

    span {
      display: inline-block;
      max-width: 80%;
    }

    i {
      color: $color-secondary;
      font-size: 22px;
      margin-right: .5rem;
      margin-bottom: 1rem;
      display: inline-block;
      vertical-align: top;
    }
  }
}

nav ol.breadcrumb {
  background: $color-white-opacity-50;
  margin-bottom: 3rem;
}

.homepage section.jumbo {
  min-height: 480px;
}

.bs-searchbox .form-control {
  height: 50px;
}

.col-status {
  padding: 0;
}

.col-status .card {
  margin-bottom: .5rem;

  h5 {
    font-weight: bold;
  }

  p {
    color: $color-333;

    &.attention {
      font-size: 12px;
      color: $color-888;
      margin-bottom: 0;
    }
  }

  i {
    color: $color-888;
    margin-right: .25rem;

    &.fa-star {
      color: $color-stars-active;
      margin-right: 0;
    }
  }

  .cancel {
    font-size: 14px;
    margin-top: 1.5rem;
    margin-bottom: .25rem;
    color: $color-888;
  }
}

.status section.confirm-details .job-details .timeslots {
  width: 100%;
  padding-left: 15px;
  padding-bottom: 1rem;
}

.footer-link a {
  color: $color-almost-black;
  line-height: 2rem;
  font-family: $font-primary;
}

.activeDay {
  background: $color-secondary;
  text-align: center;
}

section.verbolia-links {
  .veroblia-links-container {
    background: #F1F8FA;

    .verbolia-link {
    }
  }
}

.youtube {
  background-color: #000;
  margin-bottom: 30px;
  position: relative;
  padding-top: 56.25%;
  overflow: hidden;
  cursor: pointer;

  .youtube-title {
    color: white;
    position: absolute;
    top: 1.5rem;
    left: 2.5rem;
    font-size: 1.2rem;
    z-index: 1;
  }

  &:hover {
    .play-button {
      background-color: #FF0000;
    }
  }
}

.youtube img {
  width: 100%;
  top: -16.82%;
  left: 0;
}

.youtube .play-button {
  width: 70px;
  height: 50px;
  background-color: #353323;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.6);
  z-index: 1;
  opacity: 0.8;
  border-radius: 25%;
}

.youtube .play-button:before {
  content: "";
  border-style: solid;
  border-width: 10px 0 10px 18px;
  border-color: transparent transparent transparent #fff;
}

.youtube img,
.youtube .play-button {
  cursor: pointer;
}

.youtube img,
.youtube iframe,
.youtube .play-button,
.youtube .play-button:before {
  position: absolute;
}

.youtube .play-button,
.youtube .play-button:before {
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.youtube iframe {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

/* Video responsive embed*/
.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.embed-container iframe, .embed-container object, .embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}


/***  STYLE FROM HOMEPAGE ***/
section.verbolia-links {
  background: #F1F8FA;
}

.verbolia-links-container {
  background: #F1F8FA;
  display: flex;
  flex-wrap: wrap;
}

.verbolia-links-title {
  padding-bottom: 20px;
}

.verbolia-links-container > a {
  background: white;
  text-decoration: none;
  border-radius: 2px;
  margin: 10px 15px;
  margin-left: 0px;
  padding: 5px 10px;
}

.verbolia-links-container > a:hover {
  text-decoration: none;
}

.verbolia-links-container > a > .verbolia-link {
  color: black;
}

.dl-mobile-app-container .title {
  font-family: 'Roboto';
  font-size: 32px;
  font-weight: 500;
}

.dl-mobile-app-container .dl-app-container {
  background: white;
  display: flex;
  padding: 1rem;
}

.dl-mobile-app-container .dl-app-container img {
  height: 120px;
}

.dl-mobile-app-container .dl-app-container .text-and-button {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.dl-mobile-app-container .dl-app-container .text-and-button .subtitle {
  margin-left: 1rem;
  font-family: 'Roboto';
  font-size: 1rem;
  font-weight: 500;
}

.dl-mobile-app-container .dl-app-container .text-and-button .app-store-links {
  display: flex;
}

.dl-mobile-app-container .dl-app-container .text-and-button .app-store-links img {
  height: 40px;
  margin-left: 1rem;
}

@media only screen and (max-width: 524px) {
  .pro-app-arrow, .cust-app-arrow {
    display: none;
  }

  .app-store-links {
    display: flex;
    flex-direction: column;
  }

  .app-store-links img {
    padding: 1px;
  }

  .zf-icon {
    margin-top: 1rem;
  }
}

/*** Button on guide ***/

#cta-footer {
  display: flex;
  display: none;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 99;
  background: #F7F7F7;
  width: 100vw;
  height: 7rem;
}

#cta-footer .cta-button {
  font-size: 1.4rem;
  width: 350px;
  height: 3rem
}

#qr-code-container {
  position: absolute;
  top: 10.2rem;
  left: 15%;
  border: 2px solid #c9c9c9;
  border-radius: 15px;
  padding: 1rem;
  width: 12rem
}

#qr-code-container .title {
  font-weight: 700;
  font-size: 1.5rem
}

#qr-code-container .subtitle {
  font-size: 1.2rem
}

#qr-code-container .qr-code {
  width: 100%
}

#qr-code-container .footer-container {
  display: flex;
  justify-content: space-around;
  width: 100%
}

#qr-code-container .footer-container i {
  font-size: 2rem
}

#qr-code-container .footer-container .footer-title {
  width: 70%;
  font-size: .8rem
}

@media only screen and (max-width: 768px) {
  #qr-code-container {
    left: 3%
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  #qr-code-container {
    left: 7%
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1224px) {
  #qr-code-container {
    left: 10%
  }
}

#qr-code-container-mob {
  border: 2px solid #c9c9c9;
  border-radius: 15px;
  padding: 1rem;
  display: flex;
  margin-top: 1rem;
  margin-bottom: 2rem
}

#qr-code-container-mob .qr-code {
  width: 50%;
  height: 50%
}

#qr-code-container-mob .text-container {
  padding-left: .5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between
}

#qr-code-container-mob .text-container .text {
  font-size: .75rem
}

#qr-code-container-mob .text-container .title {
  font-size: .75rem
}

#qr-code-container-mob .text-container .subtitle {
  font-size: .75rem
}

#qr-code-container-mob .text-container .qr-footer-container {
  display: flex;
  width: 100%
}

#qr-code-container-mob .text-container .qr-footer-container i {
  font-size: 1.5rem
}

#qr-code-container-mob .text-container .qr-footer-container .footer-title {
  width: 70%;
  font-size: .7rem;
  padding-left: .5rem
}

@media only screen and (max-width: 524px) {
  .pro-app-arrow, .cust-app-arrow {
    display: none
  }

  .app-store-links {
    display: flex;
    flex-direction: column
  }

  .app-store-links img {
    padding: 1px
  }

  .zf-icon {
    margin-top: 1rem
  }
}

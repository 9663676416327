/**
* Variables
*/

/* Colors */
$primary_yellow: #ffa500;
$primary_gray: #333333;
$secondary_gray: #555555;
$tertiary_gray: #767676;
$hover_gray: #55595c;
$primary_blue: #34B9B9;
$primary_light_blue: #F6F6F6;
$primary_white: #fff;
$primary_black: #000;
$primary_light_gray: #70707030;
$primary_light_gray_secondary: #a7a7a7;
$primary_light_black: #000000DE;

/* Inputs */
$input_width: 100%;
$input_padding: 13px 18px;
$input_disabled_background: #00000025;
$input_disabled_color: #00000040;
$input_border_color: #0000001F;
$input_border_color_hover: #00000061;
$input_error_color: #B00020;
$input_placeholder_color: rgb(167, 167, 167);
$input_focus_color: rgba($primary_blue, 1);
$input_yellow: rgba($primary_yellow, .9);

/* Borders & Border-Radius */
$primary_border_radius: 8px;
$secondary_border_radius: 1rem;

/* Rows & Cols Gaps */
$primary_grid_column_gap: 1.125rem;
$secondary_grid_column_gap: 1.5rem;
$primary_grid_row_gap: 5rem;
$primary_grid_row_gap_mobile: 3.5rem;
$secondary_grid_row_gap: 1.7rem;
$tertiary_grid_row_gap: 2.4rem;
$tertiary_grid_row_gap_mobile: 1.7rem;

/* Spacing */
$primary_margin_top: 1.8rem;
$primary_margin_top_big: 2.5rem;
$primary_margin_top_big_mobile: 1.5rem;
$primary_margin_top_small: 1.5rem;
$primary_margin_top_extra_small: .6rem;
$primary_margin_top_medium: 2.2rem;


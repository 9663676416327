.button-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.step-button {
  max-width: 400px;
  width: 100%;
  background: #ffa500;
  border-color: #ffa500;
  padding: 10px 1rem;
  font-size: 26px;
  font-weight: 500;
}

.step-button-small {
  font-size: 16px;
}

.btn {
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.disabled {
  background: #d8d8d8;
  border-color: #d8d8d8;
  color: #fff;
}

.disabled-confirm {
  background-color: #ffa500;
  border-color: #ffa500;
  color: #fff;
  opacity: .65;
  cursor: initial;
}

.active {
  color: #fff;
}

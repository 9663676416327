// Error Message

@import '../../../assets/sass/variables';

.error-message {
    font-weight: 600;
    color: $input_error_color;
    font-size: .9rem;
    margin-top: .4rem;
    display: inline-block;
}

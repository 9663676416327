@import "../../assets/sass/variables";

.step-2 {
    min-width: 100%;
    width: 100%;


    & > span {
        text-align: center;
    }

    & > div:first-child {
        margin-bottom: 48px;
    }

    & .spinner-container {
        height: 100px;
    }

    &__buttons {
        display: flex;
        column-gap: $primary_grid_column_gap;
    }

    &__text-calendar {
        & > span.small-bottom-text {
            margin-top: 0;
        }
    }

    .sub-title-text {
        font-size: 14px;
        font-weight: normal;
        color: #212529;
        font-family: Roboto, sans-serif;
    }
}
